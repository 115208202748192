import { useContext } from 'react';
import Client from '../apis/Client';

import useQuery from '../hooks/useQuery';
import useMutation from '../hooks/useMutation';

import AuthContext from '../context/AuthContext';

import Loader from './Loader';
import ErrorBox from './ErrorBox';

export default function CompanySelect(props) {
  const { noData, style } = props;

  const { userData, selectCompany } = useContext(AuthContext);

  const { data: userCompanies, error, isLoading } = useQuery({
    queryKey: ['get_user_companies'],
    queryFn: () => Client.get(`/api/v1/auth/company/all`)
  });

  const { mutate: loginToCompany } = useMutation({
    mutationKey: 'company_login',
    mutationFn: (companyId) => Client.post(`/api/v1/auth/company/login`, { companyId }),
    onSuccess: (body) => {
      const { userData } = body;
      selectCompany(userData);
    }
  });

  const onCompanyChange = (event) => {
    loginToCompany(event.target.value);
  };

  const selectEl = userCompanies?.length
    ? (
      <select
        value={userData.company_id}
        onChange={onCompanyChange}
        id="companyId"
      >
        {(userCompanies || []).map((company) => {
          return (
            <option key={company.company_id} value={company.company_id}>{company.name}</option>
          );
        })}
      </select>
    )
    : (
      <span style={{ fontWeight: '700' }}>{noData}</span>
    );

  return (
    <div className="selectLocation" style={{ ...style }}>
      <Loader loading={isLoading}>
        {selectEl}
      </Loader>
      <ErrorBox show={!!error} message={error?.message} />
    </div>
  );
}

import React from 'react'

const IconCamera = props => {

  const {
    title = '',
    color = '#3099FD',
    textColor = '#000000',
  } = props

  return (
    <>
      <svg width="60" height="54" viewBox="0 0 60 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.6174 0.258905C13.0655 -0.395059 11.274 0.227262 10.4408 1.70396L0.44186 19.4243C-0.0372543 20.2681 -0.130994 21.2701 0.181472 22.1878C0.493938 23.1055 1.17095 23.8438 2.05627 24.213L18.5232 31.164L14.3674 42.1865H6.67035V40.4988C6.67035 38.6318 5.18093 37.1235 3.33738 37.1235C1.49383 37.1235 0.00440787 38.6318 0.00440787 40.4988V45.5618V50.6247C0.00440787 52.4917 1.49383 54 3.33738 54C5.18093 54 6.67035 52.4917 6.67035 50.6247V48.9371H16.6693C18.0545 48.9371 19.294 48.0721 19.7835 46.7642L24.6788 33.7482L32.053 36.8598C33.0841 37.2923 34.2611 37.1762 35.1881 36.5539L55.1859 23.0527C56.2066 22.3671 56.7691 21.1541 56.6545 19.92C56.5399 18.6859 55.7588 17.6101 54.6235 17.1249L14.6278 0.248357L14.6174 0.258905ZM53.4778 42.0388C53.884 42.2286 54.3527 42.2392 54.7797 42.0704C55.2067 41.9017 55.5296 41.5747 55.7067 41.1528L59.8729 31.0269C60.2166 30.183 59.8416 29.2126 59.0084 28.8329L55.1859 27.1136L41.3437 36.4484L53.4778 42.0282V42.0388Z" fill={color} />
      </svg>

      {title && <h4 style={{ color: textColor }}>{title}</h4>}
    </>
  );
}

export default IconCamera;
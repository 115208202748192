const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const PHONE_REGEX = /^(\+\d+)? ?(\d{3}|\(\d{3}\))( |-)?\d{3}( |-)?\d{4}$/i;
const STATE_REGEX = /^(([Aa][EeLlKkSsZzRr])|([Cc][AaOoTt])|([Dd][EeCc])|([Ff][MmLl])|([Gg][AaUu])|([Hh][Ii])|([Ii][DdLlNnAa])|([Kk][SsYy])|([Ll][Aa])|([Mm][EeHhDdAaIiNnSsOoTt])|([Nn][EeVvHhJjMmYyCcDd])|([Mm][Pp])|([Oo][HhKkRr])|([Pp][WwAaRr])|([Rr][Ii])|([Ss][CcDd])|([Tt][NnXx])|([Uu][Tt])|([Vv][TtIiAa])|([Ww][AaVvIiYy]))$/i;
const POSTAL_REGEX = /^[0-9]{5}(?:-[0-9]{4})?$/;
const ROUTING_REGEX = /^[0-9]{9}$/;
const BANK_ACCOUNT_REGEX = /^[0-9]{4,17}$/;

const evaluate = (testFn, msg, noEmpty) => (errors, values, field) => {
	const str = values[field] || '';
	const showMsg = noEmpty || str;
	const isValid = testFn(str);
	if (!isValid && showMsg) {
		errors[field] = msg;
	}
}

export const email = evaluate(str => EMAIL_REGEX.test(str), 'Invalid email address');

export const phone = evaluate(str => PHONE_REGEX.test(str), 'Invalid phone number');

export const state = evaluate(str => STATE_REGEX.test(str), 'Invalid state acronym');

export const postalCode = evaluate(str => POSTAL_REGEX.test(str), 'Invalid postal code');

export const latitude = evaluate(
	str => str >= -90 || str <= 90,
	'Invalid latitude'
);

export const longitude = evaluate(
	str => str >= -180 || str <= 180,
	'Invalid longitude'
);

export const password = evaluate(str => str.length >= 6, 'Must be at least 6 characters long');

export const required = evaluate(
	val => {
		if (Array.isArray(val)) {
			return !!val.length;
		}
		return !!val
	},
	'Required',
	true
);

export const routingNumber = evaluate(str => ROUTING_REGEX.test(str), 'Invalid routing number');

export const accountNumber = evaluate(str => BANK_ACCOUNT_REGEX.test(str), 'invalid account number');

export const match = (errors, values, field1, field2) => {
	const str1 = values[field1] || '';
	const str2 = values[field2] || '';
	const showMsg = str1 && str2;
	const isValid = str1 === str2;
	if (!isValid && showMsg) {
		errors[field2] = `Must match ${field1}`;
	}
}

export const noSpaces = evaluate(str => str.indexOf(' ') < 0, 'Cannot contain spaces');

import React from 'react';
import {useNavigate} from 'react-router-dom';
import PageTitle from '../components/PageTitle/PageTitle';

function ProducerPageNotFound() {
  const navigate = useNavigate();

  function toOverview() {
    navigate('/developers/apps/overview');
  }

  return (
    <div id='mainContent'>
      <PageTitle title='404 Page Not Found' />
      <button onClick={toOverview} className={`btn btn-primary me-auto`}>Return to app overview</button>
    </div>
  );
}

export default ProducerPageNotFound;

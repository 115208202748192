import React from 'react';

const APIDocumentation = () => {
    return (
        <div>
            <h2>/api/device-app-builds/:deviceId</h2>
            <h3>GET</h3>
            <h4>Description</h4>
            <p>
                This endpoint retrieves device app builds associated with the
                specified device ID.
            </p>
            <h4>Endpoint</h4>
            <code>/api/device-app-builds/:deviceId</code>
            <h4>Request Method</h4>
            <code>GET</code>
            <h4>Parameters</h4>
            <p>The request should include the following parameter:</p>
            <ul>
                <li>
                    <strong>deviceId</strong> (string): The ID of the device for
                    which to retrieve app builds.
                </li>
            </ul>
            <h4>Example</h4>
            <p>Request:</p>
            <pre>/api/device-app-builds/123456</pre>
            <h4>Response</h4>
            <p>
                The API will respond with a JSON object containing app builds
                mapped by their IDs. Each app build object includes the
                following properties:
            </p>
            <pre>
                {`
{
  "appBuildId1": {
    "id": "appBuildId1",
    "version": "1.0.0",
    "fileUrl": "https://example.com/appBuildId1",
    "fileMd5": "f4b3c0d3",
    "name": "Example App 1"
  },
  "appBuildId2": {
    "id": "appBuildId2",
    "version": "2.1.0",
    "fileUrl": "https://example.com/appBuildId2",
    "fileMd5": "a1b2c3d4",
    "name": "Example App 2"
  }
}
    `}
            </pre>
            <h4>Example Response</h4>
            <p>Response:</p>
            <pre>
                {`
{
  "appBuildId1": {
    "id": "appBuildId1",
    "version": "1.0.0",
    "fileUrl": "https://example.com/appBuildId1",
    "fileMd5": "f4b3c0d3",
    "name": "Example App 1"
  },
  "appBuildId2": {
    "id": "appBuildId2",
    "version": "2.1.0",
    "fileUrl": "https://example.com/appBuildId2",
    "fileMd5": "a1b2c3d4",
    "name": "Example App 2"
  }
}
    `}
            </pre>
        </div>
    );
};

export default APIDocumentation;

import React from 'react';

import InputBase from '../InputBase';

import css from './styles.module.scss';

function CustomSelect(props) {
    const {
        label,
        name,
        formik,
        required,
        className,
        message,
        icon,
        disabled,
        options = [],
        placeholder,
        onBlur,
        onChange,
        value
    } = props;

    const optionEls = options.map((opt) => (
        <option
            key={opt?.value || opt}
            value={opt?.value || opt}
        >
            {opt.label || opt}
        </option>
    ));

    return (
        <InputBase {...props}>
            <select
                id={name}
                name={name}
                disabled={disabled}
                placeholder={placeholder}
                onBlur={formik?.handleBlur || onBlur}
                onChange={formik?.handleChange || onChange}
                value={formik?.values[name] || value}
            >
                {optionEls}
            </select>
        </InputBase>
    );
}

export default CustomSelect

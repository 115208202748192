import React from 'react';
import moment from 'moment';

import css from './_styles.module.scss';

const ChatItemNote = (props) => {
  const { name, head, cattleId, setCattleId, monitoringArea } = props;

  const containerStyles = [css.message];
  if (cattleId === head.id) {
    containerStyles.push(css.active);
  }

  const dotStyles = [css.dot];
  if (!head.chat_read) {
    dotStyles.push(css.unread);
  }

  const { health_note, chat_note } = head;

  return (
    <div
      className={containerStyles.join(' ')}
      onClick={() => setCattleId(head.id)}
    >
      <div className={dotStyles.join(' ')} />
      <div
        className={css.image}
        style={{ backgroundImage: `url('${head?.mug_url}')` }} />
      <div className={css.info}>
        <div className={css.idPenDate}>
          <div className={css.id}>
            <strong>ID:</strong>
            {name}
          </div>
          <div className={css.pen}>
            <strong>Pen:</strong>
            {monitoringArea.name}
          </div>
          <div className={css.date}>
            {moment.utc(chat_note?.created_at).local().format('L')}
          </div>
        </div>
        <p>{health_note?.description}</p>
      </div>
    </div>
  );
};

export default ChatItemNote;

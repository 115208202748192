import React from 'react';
import { Route } from 'react-router-dom';

import ProtectedLayout from '../layouts/ProtectedLayout';
import DeveloperLayout from '../layouts/DeveloperLayout';

// import DevAppsOverview from '../Developer/AppsOverview';
// import DevAppDetails from '../Developer/AppDetails';
// import DevAppsManageApps from '../Developer/ManageApps';

// import CreateApp from '../Developer/CreateApp';
// import MyAccount from '../Developer/MyAccount';
// import DevManagementPage from '../Developer/DevManagement';
// import NewVersionPage from '../Developer/NewVersion';
import DevPageNotFound from '../Developer/DevPageNotFound';

import { DEVELOPER_PATH } from '../constants';

const DeveloperRouter = (
    <Route path="/developers">
        <Route
            element={
                <ProtectedLayout portalDefaultPath={DEVELOPER_PATH} />
            }>
            <Route element={<DeveloperLayout />}>
                {/*<Route
                    path=""
                    element={<DevAppsOverview />}></Route>
                <Route
                    path="apps/overview"
                    element={<DevAppsOverview />}></Route>
                <Route
                    path="apps/manage-apps"
                    element={<DevAppsManageApps />}></Route>
                <Route
                    path="apps/:appId/*"
                    element={<DevAppDetails />}></Route>
                <Route
                    path="apps/:appId/builds/upload"
                    element={<NewVersionPage />}></Route>
                <Route
                    path="apps/create?"
                    element={<CreateApp></CreateApp>}></Route>
                <Route
                    path="account"
                    element={<MyAccount></MyAccount>}></Route>
                <Route
                    path="management"
                    element={
                        <DevManagementPage></DevManagementPage>
                    }></Route>*/}
                <Route
                    path="*"
                    element={
                        <DevPageNotFound></DevPageNotFound>
                    }></Route>
            </Route>
        </Route>
    </Route>
);

export default DeveloperRouter;

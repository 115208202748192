import React from 'react';
import moment from 'moment';

import css from './_styles.module.scss';

function CattleDetailsNote(props) {
  const { note } = props;

  let label = 'Watch List';
  let color = '#767B80';

  if (note.cattle_note_type === 'watch') {
    label = 'Watch List';
    color = '#767B80';
  } else if (note.cattle_note_type === 'pull') {
    label = 'Pull List';
    color = '#F16060';
  } else if (note.cattle_note_type === 'vet') {
    label = 'Vet Check';
    color = '#767B80';
  }

  const firstName = note?.created_user.first_name;
  const lastName = note?.created_user.last_name;

  return (
    <div key={note.id} className={css.cattleDetailsNote}>
      <div className={css.labelBlock}>
        <div className={css.dot} style={{ backgroundColor: color }} />
        {label}
      </div>
      <div className={css.timespan}>{moment.utc(note.created_at + 'Z').local().format('M/D/YY hh:mm A')}</div>
      <div className={css.desc}>{note.description}</div>
      <div className={css.author}>{firstName} {lastName}</div>
    </div>
  );
}

export default CattleDetailsNote;

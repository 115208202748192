import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Formik, Form } from 'formik';

import useQuery from '../hooks/useQuery';

// import css from '../Home/styles.module.scss'
import { LOGIN_PATH } from '../constants'

import CustomInput from '../components/forms/CustomInput';
import ErrorBox from '../components/ErrorBox';

import {required, password} from '../validation/form';

import AuthContext from '../context/AuthContext';

import Client from '../apis/Client';
import Topbar from '../Producer/Topbar';
import LogoBlock from '../components/LogoBlock/LogoBlock';

import css from './AcceptInvite.module.scss';

const validate = (values) => {
  const requiredFields = ['firstName', 'lastName', 'email', 'password', 'confirmPassword'];

    let errors = {};
    requiredFields.forEach((field) => {
        required(errors, values, field);
    });

    password(errors, values, 'password');
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords must match';
    }

    return errors;
}

function AcceptInvite({ type }) {
  const { login } = useContext(AuthContext);

  const { id } = useParams()
  const navigate = useNavigate()

  const {
    mutate: acceptInvite,
    error,
  } = useMutation({
    mutationKey: 'accept-invite',
    mutationFn: (values) => Client.post(`/api/v1/auth/invite/${id}/accept`, values),
    onSuccess: (res) => {
      login(res);
      navigate(LOGIN_PATH)
    },
  });

  const { data, isLoadingData, error: dataError } = useQuery({
    queryKey: ['get_user_by_invite'],
    queryFn: () => Client.get(`/api/v1/auth/invite/${id}`),
    onSuccess: (res) => {
      if (res.status === 'active') {
        acceptInvite({});
      }
    }
  });

  const initialValues = {
    password: '',
    confirmPassword: '',
    email: data?.email || '',
    phone: data?.phone || '',
    companyName: data?.company_display_name || data?.company_name || '',
    firstName: data?.first_name || '',
    lastName: data?.last_name || ''
  }

  let errorMsg = null;
  if (error) {
    switch (error.status) {
      case 400:
        errorMsg = 'Invite has expired! Please contact your team leader.';
        break;
      default:
        errorMsg = 'Sorry, there was an error processing your request. Please try again later.'
    }
  }

  const isProducer = data?.roles.find(r => r.type === 'producer');

  let formContent = (
    <Formik
      initialValues={initialValues}
      onSubmit={acceptInvite}
      validate={validate}
      enableReinitialize
    >
      {(formik) => (
        <Form>
          <CustomInput
            label="First Name"
            name="firstName"
            formik={formik}
            required
          />

          <CustomInput
            label="Last Name"
            name="lastName"
            formik={formik}
            required
          />

          <CustomInput
            label="Email"
            name="email"
            formik={formik}
            required
            disabled={true}
          />

          <CustomInput
            label="Password"
            name="password"
            formik={formik}
            required
            password
          />

          <CustomInput
            label="Confirm Password"
            name="confirmPassword"
            formik={formik}
            required
            password
          />

          <CustomInput
            label="Company Name"
            name="companyName"
            formik={formik}
            disabled={isProducer}
          />

          <ErrorBox show={error} message={error} />

          <div className={css.actions}>
            <button
              className='btn btn-primary'
              disabled={!Object.keys(formik.touched).length || Object.keys(formik.errors).length}
              onClick={formik.handleSubmit}
            >Accept</button>
          </div>

        </Form>
      )}
    </Formik>
  );

  if (isLoadingData) {
    formContent = <span>Loading...</span>;
  } else if (dataError) {
    formContent = <ErrorBox show={dataError} message={dataError} />;
  }

  const formBlock = (
    <div className={css.formBlock}>
      <LogoBlock />      
      <div className={css.title}>Welcome to Herd Sense</div>
      {formContent}
    </div>
  );

  return (
    <main id="top" className={css.main}>
      <div className={css.header} />

      {formBlock}
    </main>
  )
}

export default AcceptInvite

import React from 'react'
import FooterLogo from '../../../icons/FooterLogo';

import css from './styles.module.scss'

const HomeFooter = () => (
  <footer id={css.footer} className={`${css.bgHeader}`}>
    <div className="row align-items-center g-0 p-0">
      <div className="col text-center">
        <a href="#top" className={`align-items-center d-inline-flex justify-content-end ms-auto ${css.logo}`}>
          <FooterLogo />
        </a>
      </div>
    </div>
  </footer>
)

export default HomeFooter;
import React from 'react'

const IconGear = props => {

  const {
    color = '#3099FD',
  } = props

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.6935 14.8839L21.5402 13.6935C21.7576 12.571 21.7576 11.4194 21.5402 10.2968L23.6935 9.10646C23.9411 8.97098 24.0523 8.69034 23.9715 8.42905C23.4104 6.70647 22.4551 5.14841 21.2066 3.85164C21.0145 3.65326 20.7012 3.60487 20.4585 3.74035L18.3053 4.93067C17.4006 4.18551 16.3593 3.60971 15.2322 3.23229V0.856494C15.2322 0.585527 15.035 0.348431 14.757 0.290367C12.902 -0.106406 11.0015 -0.0870511 9.23748 0.290367C8.95948 0.348431 8.76235 0.585527 8.76235 0.856494V3.23713C7.64024 3.61939 6.59901 4.19519 5.68919 4.93551L3.54101 3.74519C3.29334 3.60971 2.98501 3.65326 2.79294 3.85648C1.54447 5.14841 0.589163 6.70647 0.0281093 8.43388C-0.0578179 8.69517 0.0584365 8.97582 0.306109 9.1113L2.45934 10.3016C2.242 11.4242 2.242 12.5758 2.45934 13.6984L0.306109 14.8887C0.0584365 15.0242 -0.0527633 15.3048 0.0281093 15.5661C0.589163 17.2887 1.54447 18.8467 2.79294 20.1435C2.98501 20.3419 3.29839 20.3903 3.54101 20.2548L5.69425 19.0645C6.59901 19.8096 7.64024 20.3855 8.76741 20.7629V23.1435C8.76741 23.4145 8.96453 23.6516 9.24253 23.7096C11.0975 24.1064 12.9981 24.0871 14.7621 23.7096C15.0401 23.6516 15.2372 23.4145 15.2372 23.1435V20.7629C16.3593 20.3806 17.4006 19.8048 18.3104 19.0645L20.4636 20.2548C20.7113 20.3903 21.0196 20.3467 21.2117 20.1435C22.4601 18.8516 23.4155 17.2935 23.9765 15.5661C24.0523 15.3 23.9411 15.0193 23.6935 14.8839ZM11.9973 15.8661C9.7682 15.8661 7.95362 14.129 7.95362 11.9952C7.95362 9.8613 9.7682 8.12421 11.9973 8.12421C14.2263 8.12421 16.0409 9.8613 16.0409 11.9952C16.0409 14.129 14.2263 15.8661 11.9973 15.8661Z" fill={color} />
    </svg>
  )
}

export default IconGear;

import React, { useState, useContext } from 'react';
import moment from 'moment';

import useQuery from '../../hooks/useQuery';

import Client from '../../apis/Client';
import plcAnimal from '../../images/plcAnimal.jpg';

import Location from './Location';
import Sidebar from './Sidebar';

import LocationContext from '../../context/LocationContext';

import css from './styles.module.scss';

function VetCheck() {
  const { locationId, updateLocationId } = useContext(LocationContext);

  const { data: locations, isLoading } = useQuery({
    queryKey: ['vet_locations'],
    queryFn: () => Client.get('/api/v1/vets/locations'),
    onSuccess: (data) => {
      let selected = data[0] || null;
      if (locationId) {
        selected = data.find(l => l.id === locationId);
      }

      updateLocationId(selected.id);
    }
  });

  const content = isLoading
    ? null
    : <Location locationId={locationId} />

  return (
    <div className={css.container}>
      <Sidebar
        locations={locations || []}
        selected={locationId}
        setLocation={updateLocationId}
        isLoading={isLoading}
      />
      {content}
    </div>
  );
}

export default VetCheck;

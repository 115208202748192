import React from 'react'

const LogoPig = ({ fill = '#ffffff' }) => (
  <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.03418 5.0121C6.91977 6.35693 6.25411 8.06807 6.20345 9.87192L6.18606 9.88025C5.72907 10.0991 5.22127 10.1899 4.71678 10.1428C3.94055 10.0704 3.22964 9.67749 2.75513 9.05872L2.46587 8.68152C1.94497 8.00226 1.66264 7.17008 1.66264 6.31398V6.10509C1.66264 5.19477 1.54115 4.28852 1.30139 3.41036L0.707428 1.23482C0.660994 1.06474 0.819228 0.909725 0.988241 0.959715L4.36307 1.95792L5.61415 2.38714C6.83066 2.80449 7.72686 3.80486 8.03418 5.0121Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.446 19.4243C11.4454 19.4208 11.4449 19.4173 11.4445 19.4139C11.0067 17.92 9.86295 16.1486 9.13046 15.3014C7.9287 13.9092 7.20021 12.0897 7.20021 10.094C7.20021 5.95101 10.4545 2.23457 14.9726 2.22051C19.2931 2.20717 22.7982 5.75495 22.7982 10.094C22.7982 12.1311 22.0715 13.9479 20.8684 15.3014C20.1359 16.1486 18.992 17.92 18.5544 19.4139C18.5541 19.4167 18.5537 19.4196 18.5532 19.4224C18.5527 19.4255 18.5522 19.4285 18.5516 19.4315L18.5515 19.432C18.5505 19.4377 18.5495 19.4433 18.5489 19.449H11.45C11.4493 19.4428 11.4482 19.4365 11.4471 19.4303C11.4467 19.4283 11.4464 19.4263 11.446 19.4243ZM10.1514 14.4181L10.1516 14.4183C10.8236 15.1957 11.7976 16.6449 12.4047 18.0988H17.5941C18.2012 16.6447 19.1755 15.1953 19.8474 14.4181L19.8535 14.4111L19.8597 14.4042C20.8409 13.3003 21.4484 11.8036 21.4484 10.094C21.4484 6.48861 18.5366 3.55979 14.9768 3.57079C11.2608 3.58234 8.55004 6.63551 8.55004 10.094C8.55004 11.7545 9.15457 13.2629 10.1514 14.4181Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.6886 23.2548C11.5347 23.0189 11.4541 22.743 11.4541 22.4601L11.4497 20.6993H18.5488L18.5431 22.4601C18.5431 22.6996 18.4362 23.0559 18.3058 23.2552L17.548 24.4087C17.315 24.7627 16.7861 25.0502 16.3673 25.0502H13.631C13.2109 25.0502 12.682 24.7625 12.449 24.4087L11.6886 23.2548ZM14.3556 22.4912C14.4986 23.0247 14.3603 23.6678 14.0947 23.739C13.8291 23.8102 13.3879 23.3224 13.245 22.7889C13.102 22.2553 13.2963 21.9856 13.603 21.9034C13.9097 21.8211 14.2127 21.9576 14.3556 22.4912ZM15.6443 22.4912C15.5014 23.0247 15.6397 23.6678 15.9053 23.739C16.1709 23.8102 16.612 23.3224 16.755 22.7889C16.8979 22.2553 16.7037 21.9856 16.397 21.9034C16.0903 21.8211 15.7872 21.9576 15.6443 22.4912Z" fill={fill} />
    <path d="M23.8124 9.88025L23.795 9.87195C23.7442 8.03177 23.0631 6.34439 21.9609 5.02522C22.2651 3.81211 23.1634 2.806 24.3843 2.38714L25.6354 1.95792L29.0102 0.959715C29.1792 0.909725 29.3374 1.06474 29.291 1.23482L28.697 3.41036C28.4573 4.28852 28.3358 5.19477 28.3358 6.10509V6.31398C28.3358 7.17008 28.0535 8.00226 27.5326 8.68152L27.2433 9.05872C26.7688 9.67749 26.0579 10.0704 25.2817 10.1428C24.7772 10.1899 24.2694 10.0991 23.8124 9.88025Z" fill={fill} />
  </svg>
)

export default LogoPig
import React from 'react'
import LogoHorns from '../../icons/LogoHorns'
import LogoCattle from '../../icons/LogoCattle'
import LogoPig from '../../icons/LogoPig'

import css from './styles.module.scss';

export default function LogoBlock() {
  return (
    <div className={css.logoIcons}>
      <span><LogoHorns fill='#0D1D27' /></span>
      <span><LogoCattle fill='#0D1D27' /></span>
      <span><LogoPig fill='#0D1D27' /></span>
    </div>
  )
}
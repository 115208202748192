import React from 'react'
import { Formik, Form } from 'formik'
import { useNavigate } from 'react-router-dom'
import Loader from '../components/Loader/Loader'

export default function CustomerInviteModal(props) {
    const navigate = useNavigate()

    const { type, onAccepted, error, isLoading, title, message } = props

    const initialValues = {
        password: '',
        confirmPassword: '',
    }
    const validate = (values) => {
        const errors = {}
        if (values.password !== values.confirmPassword) {
            errors.password = 'Passwords must match.'
        }
        return errors
    }
    const onSubmit = (values) => {
        onAccepted(values.password)
    }

    return (
        <div
            className="modal fade show"
            id="exampleModalLive"
            tabIndex="-1"
            aria-labelledby="newDevModalLabel"
            style={{ display: 'block' }}
            aria-modal="true"
            role="dialog">
            <div
                className={`position-fixed backdrop`}
                onClick={() => {
                    navigate('/')
                }}
            />
            <Formik
                validate={validate}
                initialValues={initialValues}
                onSubmit={onSubmit}>
                {(formik) => (
                    <Form
                        onSubmit={formik.handleSubmit}
                        style={{ padding: '1em' }}>
                        <div className={`modal-dialog modal-dialog-centered`}>
                            <div className={`modal-content`}>
                                <div
                                    className={`modal-header position-relative bgNavy textWhite`}>
                                    <h5
                                        className="modal-title"
                                        id="newDevModalLabel">
                                        {title}
                                    </h5>
                                    <button
                                        type="button"
                                        className={`border-0 position-absolute close`}
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => {
                                            navigate('/')
                                        }}>
                                        <svg
                                            width="12"
                                            height="12"
                                            viewBox="0 0 12 12"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.1341 2.58996L12 1.72791L10.2721 0L9.41004 0.862047L6 4.27209L2.58996 0.865862L1.72791 0L0 1.72791L0.862047 2.58996L4.27209 6L0.865861 9.41004L0 10.2721L1.72791 12L2.58996 11.138L6 7.72791L9.41004 11.138L10.2721 12L12 10.2721L11.138 9.41004L7.72791 6L11.138 2.58996H11.1341Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <div style={{ padding: '2em 2em' }}>
                                    <p style={{ marginBottom: '2em' }}>
                                        {message}
                                    </p>
                                    <div style={{ marginBottom: '2em' }}>
                                        <label
                                            htmlFor="password"
                                            className={`col-12 col-sm-6 mb-sm-0`}>
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            id="password"
                                            name="password"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.password}
                                            className={`col-12 col-sm-6`}
                                        />
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="confirmPassword"
                                            className={`col-12 col-sm-6 mb-sm-0`}>
                                            Confirm Password
                                        </label>
                                        <input
                                            type="password"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={
                                                formik.values.confirmPassword
                                            }
                                            className={`col-12 col-sm-6`}
                                        />
                                    </div>
                                    {formik.touched.password &&
                                    formik.errors.password ? (
                                        <div style={{ color: 'red' }}>
                                            {formik.errors.password}
                                        </div>
                                    ) : null}
                                    {isLoading && <Loader></Loader>}
                                    {error && (
                                        <div
                                            className="mt-5"
                                            style={{
                                                color: 'var(--bs-danger)',
                                            }}>
                                            <span>
                                                <strong>{error}</strong>
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className={`align-items-center d-flex justify-content-between modal-footer border-0 bgGray246`}>
                                    <button
                                        type="button"
                                        className={`btn btn-outline-secondary`}
                                        data-dismiss="modal"
                                        onClick={() => navigate('/')}>
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className={`btn btn-primary`}>
                                        Accept
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
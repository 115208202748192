import React from 'react'

const IconSecurity = props => {

  const {
    title = '',
    color = '#3099FD',
    textColor = '#000000',
  } = props

  return (
    <>
      <svg width="57" height="60" viewBox="0 0 57 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M28.5 0C29.0462 0 29.5925 0.117832 30.0912 0.341713L52.4518 9.75648C55.0643 10.8523 57.0118 13.4093 56.9999 16.4965C56.9406 28.1854 52.0956 49.5719 31.6231 59.293C29.64 60.2357 27.3363 60.2357 25.3531 59.293C4.90442 49.5719 0.0594289 28.1854 5.40132e-05 16.4965C-0.011821 13.4093 1.93568 10.8523 4.54817 9.75648L26.9206 0.341713C27.4075 0.117832 27.9538 0 28.5 0ZM28.5 7.87117V52.4116C44.8875 44.5405 49.2931 27.1131 49.4 16.6614L28.5 7.87117Z" fill={color} />
      </svg>

      {title && <h4 style={{ color: textColor }}>{title}</h4>}
    </>
  );
}

export default IconSecurity;
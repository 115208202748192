import React from 'react';
import { Form, Formik } from 'formik';

import Client from '../../apis/Client';

import useQuery from '../../hooks/useQuery';
import useMutation from '../../hooks/useMutation';

import FormModal from '../../components/FormModal';
import CustomSelect from '../../components/forms/CustomSelect';

import { required } from '../../validation/form';

import css from '../styles.module.scss';

const validate = (values, roles) => {
    let errors = {};
    
    required(errors, values, 'user');

    return errors;
};

const UserModal = ({
    showModal,
    setShowModal = () => {},
    companyId,
    locationId,
    refetch,
}) => {
    const { data: users } = useQuery({
        queryKey: ['location_unassigned_users', locationId],
        queryFn: () => {
            return Client.get(`/api/v1/admin/customers/${companyId}/locations/${locationId}/users?unassigned=true`);
        },
    });

    const {
        mutate: submitRequest,
        isLoading,
        isError,
        error,
    } = useMutation({
        mutationKey: ['assign-local-user'],
        mutationFn: (data) => {
            return Client.post(`/api/v1/admin/customers/${companyId}/locations/${locationId}/users/${data}`);
        },
        onSuccess: () => {
            setShowModal(false);
            refetch();
        },
    });

    const userOptions = (users || []).map((user) => {
        return {
            name: `${user.user.first_name} ${user.user.last_name}`,
            label: user.user.email,
            value: user.id
        };
    });

    const initialValues = {
        user: userOptions[0]
    };

    const onSubmit = (values, { setSubmitting, resetForm }) => {
        submitRequest(values.user.value);
    };

    return (
        <FormModal
          setShowModal={setShowModal}
          showModal={showModal}
          title="Add User"
          submitBtnLabel="Add"
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
          error={error}
        >
            {(formik) => (
                <CustomSelect
                    name="user"
                    label="User"
                    options={userOptions}
                    formik={formik}
                />
            )}
        </FormModal>
    );
};

export default UserModal;

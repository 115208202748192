import React from 'react'

const IconPencil = props => {

  const {
    color = '#3099FD',
  } = props

  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.57143 0C1.15313 0 0 1.15313 0 2.57143V15.4286C0 16.8469 1.15313 18 2.57143 18H15.4286C16.8469 18 18 16.8469 18 15.4286V2.57143C18 1.15313 16.8469 0 15.4286 0H2.57143ZM13.0902 4.32723L13.6688 4.9058C14.2955 5.53259 14.2955 6.54911 13.6688 7.17991L12.8089 8.03973L9.95625 5.18705L10.8161 4.32723C11.4429 3.70045 12.4594 3.70045 13.0902 4.32723ZM4.81741 10.3259L9.0442 6.09911L11.8969 8.95179L7.67009 13.1746C7.50536 13.3393 7.30045 13.4558 7.07143 13.5121L4.6567 14.1147C4.43571 14.171 4.2067 14.1067 4.04598 13.946C3.88527 13.7853 3.82098 13.5562 3.87723 13.3353L4.47991 10.9205C4.53616 10.6955 4.65268 10.4866 4.81741 10.3219V10.3259Z" fill={color} />
    </svg>
  )
}

export default IconPencil;

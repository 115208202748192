import Client from './Client';

export default class Account extends Client {
    static accessToken() {
        return super.getTokenDecoded('powershift_token');
    }

    static refreshToken(refreshToken, accountId) {
        return this.post('/api/v1/auth/refresh', { accountId }, refreshToken);
    }

    static forgotPassword(email) {
        return this.post('/api/v1/auth/forgot-password', { email });
    }

    static resetPassword(key, password) {
        return this.post('/api/v1/auth/reset-password', { key, password });
    }

    static acceptInvite(id, password) {
        return this.post('/api/v1/auth/accept-invite', { id, password });
    }

    static login(info) {
        const { email, password, accountId } = info;
        const data = { email, password, accountId };
        return Client.post('/api/v1/auth/login', data);
    }

    static loginToCompany(companyId) {
        return this.post(`/api/v1/auth/company/login`, { companyId });
    }

    static getUserCompanies() {
        return this.get(`/api/v1/auth/company/all`);
    }

    static createDevUser(data) {
        return this.post('/api/v1/auth/developers/signup', data);
    }

    static getCompanyInfo() {
        return this.get('/api/v1/auth/company');
    }

    static getAccountInfo() {
        return this.get('/api/v1/auth/users');
    }

    static updateAccount(data) {
        return this.put(`/api/v1/auth/users`, data);
    }

    static updateAccountPassword(data) {
        return this.put('/api/v1/auth/users/password', data);
    }

    static updatePayment(data) {
        return this.put(`/api/v1/auth/company`, data);
    }
}

import React, { useContext, useState, useRef, useEffect } from 'react';

import TopHeader from '../../components/TopHeader';

import AccountClient from '../../apis/Account';

import LocationContext from '../../context/LocationContext';

import AuthContext from '../../context/AuthContext';

import CompanySelect from '../../components/CompanySelect';
import LocationSelect from '../../components/LocationSelect';

import css from './styles.module.scss';
import IconGear from '../../icons/Gear';
import { Link } from 'react-router-dom';

function Topbar(props) {
  const {
    navOpen = false,
    setNavOpen = () => {},
    blank = false,
  } = props;

  const { locationId } = useContext(LocationContext);
  const { logout } = useContext(AuthContext);

  const dropdownRef = useRef();

  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const blurListener = (e) => {
      if (!dropdownRef.current?.contains(e.target)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener('click', blurListener);

    return () => {
      document.removeEventListener('click', blurListener);
    }
  }, []);

  const token = AccountClient.accessToken();
  const isProducerAdmin =
    token &&
    (token.roles || []).find((role) => {
      return (
        (role.type === 'producer' && role.name === 'admin') ||
        (role.type === 'system' && role.name === 'admin')
      );
    });

  const queryString = locationId ? `?locationId=${locationId}` : '';

  const links = [
    {
      title: 'Dashboard',
      path: `/producers`,
    },
    {
      title: 'HerdSense',
      path: `/producers/herd-sense`,
    },
    {
      title: 'Vet Chat',
      path: `/producers/vet-chat`,
    },
  ];

  if (isProducerAdmin) {
    links.push({
      title: 'Users',
      path: `/producers/users`
    });
  }

  let accountEl = null;
  if (isProducerAdmin) {
    accountEl = (
      <Link
        to={'/producers/account'}
        className={css.link}
        onClick={() => setShowDropdown(false)}
      >
        My Account
      </Link>
    );
  }

  const companySelectEl = (
    <div className={css.locSelect}>
      <h5>Company:</h5>
      <CompanySelect />
    </div>
  );

  const settingsDropdown = (
    <li
      ref={dropdownRef}
      className={css.dropdownContainer}
      onClick={() => setShowDropdown(!showDropdown)}
      id="settings-menu-item"
    >
      <IconGear color='#B1C0D0' />
      
      <div
        className={`${css.dropdown} ${showDropdown ? css.open : ''}`}
        onClick={e => e.stopPropagation()}
      >
        {token?.first_name &&
          <h5>
          Hi, <span>{token?.first_name}!</span>
          <span className={css.email}>{token?.email}</span>
          </h5>
        }
        {accountEl}

        {companySelectEl}

        <div className={css.locSelect}>
          <h5>Location:</h5>
          <LocationSelect hideLabel url="/api/v1/producers/locations" />
        </div>
        
        <button onClick={logout} className='btn btn-danger'>Logout</button>
      </div>
    </li>
  );

  const company = token?.company?.display_name || token?.company?.name;

  return (
    <TopHeader
      links={links}
      email={(token || {}).email}
      query={queryString}
      customEls={settingsDropdown}
      navOpen={navOpen}
      setNavOpen={setNavOpen}
      label={`Herd Sense - ${company}`}
      blank={blank}
    />
  );
}

export default Topbar;

export const STATES = [
    { value: '-1', text: 'Select' },
    { value: 'AK', text: 'Alaska' },
    { value: 'AL', text: 'Alabama' },
    { value: 'AR', text: 'Arkansas' },
    { value: 'AZ', text: 'Arizona' },
    { value: 'CA', text: 'California' },
    { value: 'CO', text: 'Colorado' },
    { value: 'CT', text: 'Connecticut' },
    { value: 'DC', text: 'District of Columbia' },
    { value: 'DE', text: 'Delaware' },
    { value: 'FL', text: 'Florida' },
    { value: 'GA', text: 'Georgia' },
    { value: 'HI', text: 'Hawaii' },
    { value: 'IA', text: 'Iowa' },
    { value: 'ID', text: 'Idaho' },
    { value: 'IL', text: 'Illinois' },
    { value: 'IN', text: 'Indiana' },
    { value: 'KS', text: 'Kansas' },
    { value: 'KY', text: 'Kentucky' },
    { value: 'LA', text: 'Louisiana' },
    { value: 'MA', text: 'Massachusetts' },
    { value: 'MD', text: 'Maryland' },
    { value: 'ME', text: 'Maine' },
    { value: 'MI', text: 'Michigan' },
    { value: 'MN', text: 'Minnesota' },
    { value: 'MO', text: 'Missouri' },
    { value: 'MS', text: 'Mississippi' },
    { value: 'MT', text: 'Montana' },
    { value: 'NC', text: 'North Carolina' },
    { value: 'ND', text: 'North Dakota' },
    { value: 'NE', text: 'Nebraska' },
    { value: 'NH', text: 'New Hampshire' },
    { value: 'NJ', text: 'New Jersey' },
    { value: 'NM', text: 'New Mexico' },
    { value: 'NV', text: 'Nevada' },
    { value: 'NY', text: 'New York' },
    { value: 'OH', text: 'Ohio' },
    { value: 'OK', text: 'Oklahoma' },
    { value: 'OR', text: 'Oregon' },
    { value: 'PA', text: 'Pennsylvania' },
    { value: 'PR', text: 'Puerto Rico' },
    { value: 'RI', text: 'Rhode Island' },
    { value: 'SC', text: 'South Carolina' },
    { value: 'SD', text: 'South Dakota' },
    { value: 'TN', text: 'Tennessee' },
    { value: 'TX', text: 'Texas' },
    { value: 'UT', text: 'Utah' },
    { value: 'VA', text: 'Virginia' },
    { value: 'VT', text: 'Vermont' },
    { value: 'WA', text: 'Washington' },
    { value: 'WI', text: 'Wisconsin' },
    { value: 'WV', text: 'West Virginia' },
    { value: 'WY', text: 'Wyoming' },
];

export const BUSINESS_TYPES = [{ value: 'scorp', text: 'S-corp' }];

export const HOME_PATH = '/';
export const LOGIN_PATH = '/login';
export const PRODUCER_PATH = '/producers';
export const DEVELOPER_PATH = '/developers';
export const ADMIN_PATH = '/admin';
export const REVIEW_PATH = '/review';
export const VET_PATH = '/vets';
//TODO - /not-found is a bogus path that will hit our * catch all path and render a not found component, maybe make it an official path?
export const NOT_FOUND_PATH = '/not-found';

import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import useQuery from '../../hooks/useQuery';

import Client from '../../apis/Client';

import Loader from '../../components/Loader';
import ErrorBox from '../../components/ErrorBox';

import { getAbsDay } from '../../util';

import css from './styles.module.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false
        },
    },
    interaction: {
        mode: 'index',
        intersect: false,
    },
    scales: {
        x: {
            stacked: true,
            ticks: {
                display: false
            }
        },
        y: {
            stacked: true,
            ticks: {
                beginAtZero: true,
                stepSize: 1
            }
        },
    }
};

function HeaderGraph(props) {
  const { locationId } = props;

  const date = new Date();
  date.setDate(date.getDate() - 30);
  const from = date.toISOString();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['get_dashboard_notes', locationId],
    queryFn: () => {
      return Client.get(`/api/v1/producers/locations/${locationId}/health-notes?from=${from}&details=true`)
    },
  });

  const today = getAbsDay(new Date());

  const labels = new Array(30).fill('').map((str, i) => {
    const time = (today - i) * 1000 * 60 * 60 * 24;
    const offset = new Date().getTimezoneOffset() * 60 * 1000;
    const timestamp = new Date(time + offset);
    return `${timestamp.getMonth()}/${timestamp.getDate()}`;
  }).reverse();

  const low = data?.filter(n => n.priority === 'low');
  const medium = data?.filter(n => n.priority === 'medium');
  const high = data?.filter(n => n.priority === 'high');

  const lowPerDayData = low?.reduce((arr, note) => {
    const noteDay = getAbsDay(note.created_at);
    const index = today - noteDay;
    arr[index] += 1;
    return arr;
  }, new Array(30).fill(0)).reverse();

  const mediumPerDayData = medium?.reduce((arr, note) => {
    const noteDay = getAbsDay(note.created_at);
    const index = today - noteDay;
    arr[index] += 1;
    return arr;
  }, new Array(30).fill(0)).reverse();

  const highPerDayData = high?.reduce((arr, note) => {
    const noteDay = getAbsDay(note.created_at);
    const index = today - noteDay;
    arr[index] += 1;
    return arr;
  }, new Array(30).fill(0)).reverse();

  const graphData = {
    labels,
    datasets: [
      {
        label: 'Low Priority',
        backgroundColor: 'rgb(48 153 253 / 50%)',
        barPercetage: 0.5,
        barThickness: 12,
        maxBarThickness: 12,
        minBarLength: 2,
        data: lowPerDayData
      },
      {
        label: 'Med. Priority',
        backgroundColor: 'rgb(235 191 79 / 50%',
        barPercetage: 0.5,
        barThickness: 12,
        maxBarThickness: 12,
        minBarLength: 2,
        data: mediumPerDayData
      },
      {
        label: 'High Priority',
        backgroundColor: 'rgb(241 96 96 / 50%',
        barPercetage: 0.5,
        barThickness: 12,
        maxBarThickness: 12,
        minBarLength: 2,
        data: highPerDayData
      }
    ]
  }

  return error
    ? <ErrorBox show={isError} error={error.message} />
     : (
      <Loader loading={isLoading}>
        <div className={css.headerGraph}>
          <h3>Last 30 days</h3>
          <Bar data={graphData} options={options} height={60} />
        </div>
      </Loader>
    );
}

export default HeaderGraph;

import React from 'react';

import css from './styles.module.scss';
import IconNoFeed from '../../icons/IconNoFeed';

function Marker(props) {
	const { label, online, onClick } = props;

	const noFeedIconEl = online ? null : <IconNoFeed />;

	return (
		<div onClick={onClick} className={css.marker}>
      <div className={css.iconNoFeed}>
        {noFeedIconEl}
      </div>
			<span className={css.label}>{label}</span>
		</div>
	);
}

export default Marker;

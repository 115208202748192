import React, {useState} from 'react';
import { Outlet } from 'react-router-dom';

import Topbar from '../Admin/Topbar';

const ProducerLayout = (props) => {
    const [navOpen, setNavOpen] = useState(false);

    return (
        <>
            <Topbar navOpen={navOpen} setNavOpen={setNavOpen} />
            <section
                id="mainContent"
                className={`admin`}
            >
                <Outlet />
            </section>
        </>
    );
};

export default ProducerLayout;

import React from 'react';

const APIDocumentation = () => {
    return (
        <div>
            <h2>/api/v1/device-heart-beats</h2>
            <h3>POST</h3>
            <h4>Description</h4>
            <p>
                This endpoint allows you to submit device heartbeats for
                tracking and updates.
            </p>
            <h4>Endpoint</h4>
            <code>/api/v1/device-heart-beats</code>
            <h4>Request Method</h4>
            <code>POST</code>
            <h4>Request Body</h4>
            <p>The request body should contain the following parameters:</p>
            <ul>
                <li>
                    <strong>device</strong> (object): An object containing
                    device details. It should have the following properties:
                    <ul>
                        <li>
                            <strong>id</strong> (string): The ID of the device,
                            this is always required.
                        </li>
                        <li>
                            <strong>latitude</strong> (number): The latitude of
                            the device's location, can be null.
                        </li>
                        <li>
                            <strong>longitude</strong> (number): The longitude
                            of the device's location, can be null.
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>id</strong> (string): The ID of the heartbeat.
                </li>
                <li>
                    <strong>apps</strong> (dictionary): A dictionary of apps
                    associated with the device.
                </li>
            </ul>
            <h4>Example</h4>
            <pre>
                {`
        {
          "device": {
            "id": "abc123",
            "latitude": 37.7749,
            "longitude": -122.4194
          },
          "id": "heart123",
          "apps": {
            "app1": {
              "id": "app1",
              "version": "1.0",
              "name": "App 1"
            },
            "app2": {
              "id": "app2",
              "version": "2.0",
              "name": "App 2"
            },
            "app3": {
              "id": "app3",
              "version": "3.0",
              "name": "App 3"
            }
          }
        }
        `}
            </pre>
            <h4>Response</h4>
            <p>
                The API will respond with a JSON object containing the added
                heartbeat details:
            </p>
            <pre>
                {`
        {
          "id": "heart123",
          "msg": <request body>,
          "device_id": <device ID>
        }
        `}
            </pre>
            <p>
                The <code>msg</code> field contains the original request body,
                and the <code>device_id</code> field represents the associated
                device ID.
            </p>
            <h4>Example Response</h4>
            <pre>
                {`
        {
          "id": "heart123",
          "msg": {
            "device": {
              "id": "abc123",
              "latitude": 37.7749,
              "longitude": -122.4194
            },
            "id": "heart123",
            "apps": {
              "app1": {
                "id": "app1",
                "version": "1.0",
                "name": "App 1"
              },
              "app2": {
                "id": "app2",
                "version": "2.0",
                "name": "App 2"
              },
              "app3": {
                "id": "app3",
                "version": "3.0",
                "name": "App 3"
              }
            }
          }
          "device_id": 1
        }
        `}
            </pre>
        </div>
    );
};

export default APIDocumentation;

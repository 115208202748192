import React from 'react';
import { Outlet } from 'react-router-dom';

import PermissionError from '../errors/PermissionError';

// import DeveloperSidebar from '../Developer/Sidebar';
import { ErrorBoundary } from 'react-error-boundary';

const DeveloperLayout = () => {
    return (
        <>
            {/*<DeveloperSidebar />*/}
            <Outlet></Outlet>
        </>
    );
};

export default DeveloperLayout;

import React, { useState, useContext } from 'react';
import { Formik, Form } from 'formik';
import { useMutation } from '@tanstack/react-query';
import LogoHorns from '../icons/LogoHorns';
import LogoCattle from '../icons/LogoCattle';
import LogoPig from '../icons/LogoPig';

import PaneInput from './PaneInput';

import AccountClient from '../apis/Account';
import css from './styles.module.scss';

import AuthContext from '../context/AuthContext';

import {required, email, phone, password, match} from '../validation/form';

const validate = (values) => {
    const errors = {};

    const requiredFields = ['fname', 'lname', 'email', 'company', 'phone', 'password', 'confirmPassword'];
    requiredFields.forEach(field => {
      required(errors, values, field);
    });

    email(errors, values, 'email');
    phone(errors, values, 'phone');

    password(errors, values, 'password');
    password(errors, values, 'confirmPassword');

    match(errors, values, 'password', 'confirmPassword');

    return errors;
};

const initialValues = {
    email: '',
    password: '',
    fname: '',
    lname: '',
    company: '',
    phone: '',
    confirmPassword: ''
};

const PaneCreate = (props) => {
  const {
    setFlow = () => {}
  } = props;

  const { login } = useContext(AuthContext);

  const {mutateAsync: submitRequest, isLoading, error} = useMutation({
    mutationKey: 'sign-up',
    mutationFn: (...args) => AccountClient.createDevUser(...args),
    onSuccess: (res) => {
      login(res);
    }
  });

  const [valmontTerms, setValmontTerms] = useState(false);

  let errorMsg = null;
  if (error) {
    switch (error.status) {
      case 500:
        errorMsg = 'Sorry, login is not working at this time. We are currently working to fix the issue.';
        break;
      case 401:
        errorMsg = 'Cannot create a new account with this email. Please login to view account.';
        break;
      case 400:
        errorMsg = 'Cannot create a new account with this company.';
        break;
      default:
        errorMsg = 'Sorry, login is not working at this time. We are currently working to fix the issue.';
    }
  }

  return (
    <div className={css.paneInner}>
      <div className={`col-12 px-0 text-center ${css.logo}`}>
          <div className={css.logoIcons}>
              <span>
                  <LogoHorns fill='#29313B' />
              </span>
              <span>
                  <LogoCattle fill='#29313B' />
              </span>
              <span>
                  <LogoPig fill='#29313B' />
              </span>
          </div>
      </div>

      <h1 className={`col-12 px-0`}>Create Developer Account</h1>

      <p className={`col-12 px-0`}>Welcome to Herd Sense! Please fill out the form below to create your developer account. Or, <a href='/login' onClick={(e) => { e.preventDefault(); setFlow(''); }}>click here</a> to login.</p>

      <Formik
          validate={validate}
          initialValues={initialValues}
          onSubmit={submitRequest}
      >
          {(formik) => (
            <Form
              className={`px-0 ${css.form}`}
              onSubmit={formik.handleSubmit}
            >
              <PaneInput
                className="col-12 col-sm-6 d-flex flex-column pe-sm-3 text-start"
                name="fname"
                label="First Name"
                placeholder="first name"
                required
                formik={formik}
              />

              <PaneInput
                className="col-12 col-sm-6 d-flex flex-column ps-sm-3 text-start"
                name="lname"
                label="Last Name"
                placeholder="last name"
                required
                formik={formik}
              />

              <PaneInput
                className="col-12 d-flex flex-column text-start"
                name="email"
                label="Email"
                placeholder="your email here"
                required
                formik={formik}
              />

              <PaneInput
                className="col-12 col-sm-6 d-flex flex-column pe-sm-3 text-start"
                name="company"
                label="Company"
                placeholder="company name"
                required
                formik={formik}
              />

              <PaneInput
                className="col-12 col-sm-6 d-flex flex-column ps-sm-3 text-start"
                name="phone"
                label="Phone"
                placeholder="phone name"
                required
                formik={formik}
              />

              <PaneInput
                className="col-12 col-sm-6 d-flex flex-column pe-sm-3 text-start"
                name="password"
                label="Password"
                placeholder="password"
                type="password"
                required
                formik={formik}
              />

              <PaneInput
                className="col-12 col-sm-6 d-flex flex-column ps-sm-3 text-start"
                name="confirmPassword"
                label="Confirm Password"
                placeholder="password"
                type="password"
                required
                formik={formik}
              />

              <span className="px-0 mb-2" style={{color: 'var(--bs-danger)'}}>{errorMsg}</span>

              <div className={`col-12 col-sm-8 d-flex flex-column text-start ${css.item}`}>
                <label className={`d-block mb-0 position-relative ${css.checkContainer}`}>
                  <input
                    type="checkbox"
                    value={valmontTerms}
                    onChange={() => setValmontTerms(!valmontTerms)}
                  />
                  <span data-testid="agree-term-check" className={`${css.checkmark}`} />
                  I agree to the <a href="#terms" onClick={(e) => { e.preventDefault(); console.log('click')}}>Valmont</a> Terms & Services
                </label>
              </div>

              {/* <div className={`col-12 d-flex flex-column text-start item ${css.item}`}>
                <label className={`d-block mb-0 position-relative ${css.checkContainer}`}>
                  <input
                    type="checkbox"
                    className={`position-absolute`}
                    value={ailliaTerms}
                    onChange={() => setAilliaTerms(!ailliaTerms)}
                  />
                  <span className={`position-absolute ${css.checkmark}`} />
                  I agree to the <a href="#terms" onClick={(e) => { e.preventDefault(); console.log('click')}}>ailia SDK</a> Terms & Services
                </label>
              </div> */}

              <div className={`col-xl-4 d-flex flex-column justify-content-end ms-auto item ${css.item}`}>
                <button
                  type="submit"
                  className={`btn btn-primary ms-auto ${isLoading ? 'btn-disabled' : 'btn-primary'}`}
                  disabled={isLoading || Object.keys(formik.errors).length || !valmontTerms}
                >
                  Create
                </button>
              </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default PaneCreate;

import React from 'react'

const IconHardware = props => {

  const {
    title = '',
    color = '#3099FD',
    textColor = '#000000',
  } = props

  return (
    <>
      <svg width="58" height="60" viewBox="0 0 58 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32.8667 3.87097C32.8667 1.72984 31.1388 0 29 0C26.8612 0 25.1333 1.72984 25.1333 3.87097V30.9677C25.1333 33.1089 26.8612 34.8387 29 34.8387C31.1388 34.8387 32.8667 33.1089 32.8667 30.9677V3.87097ZM15.4062 14.5887C17.0496 13.2218 17.2671 10.7782 15.9017 9.13306C14.5363 7.4879 12.0954 7.27016 10.4521 8.6371C4.07208 13.9597 0 21.9919 0 30.9677C0 46.996 12.9896 60 29 60C45.0104 60 58 46.996 58 30.9677C58 21.9919 53.9158 13.9597 47.5358 8.6371C45.8925 7.27016 43.4517 7.5 42.0862 9.13306C40.7208 10.7661 40.9504 13.2218 42.5817 14.5887C47.2821 18.496 50.2546 24.3871 50.2546 30.9677C50.2546 42.7258 40.7329 52.2581 28.9879 52.2581C17.2429 52.2581 7.72125 42.7258 7.72125 30.9677C7.72125 24.3871 10.7058 18.496 15.3942 14.5887H15.4062Z" fill={color} />
      </svg>

      {title && <h4 style={{ color: textColor }}>{title}</h4>}
    </>
  );
}

export default IconHardware;
import React from 'react';

import Client from '../../apis/Client';
// import { DEFAULT_SELECTS } from './utils';

import { required, latitude, longitude } from '../../validation/form';

import CustomInput from '../../components/forms/CustomInput';
import CustomSelect from '../../components/forms/CustomSelect';
import FormModal from '../../components/FormModal';

import useQuery from '../../hooks/useQuery';
import useMutation from '../../hooks/useMutation';

const validate = (values) => {
    const errors = {};
 
    required(errors, values, 'name');

    latitude(errors, values, 'latitude');
    longitude(errors, values, 'longitude');

    // noSpaces(errors, values, 'deviceId');

    return errors;
};

const newEntry = {
    name: 'New',
    label: 'New',
    value: null
};

const DeviceModal = ({
    showModal,
    setShowModal = () => {},
    device,
    companyId,
    locationId,
    onSuccess = () => {},
}) => {
    const params = new URLSearchParams();
    params.set('locationId', locationId);
    params.set('noDevice', true);

    const { data: monitoringAreaRsp, isLoading: isAreasLoading } = useQuery({
        queryKey: ['monitoring-areas'],
        queryFn: () =>
            Client.get(
                `/api/v1/admin/customers/${companyId}/locations/${locationId}/monitoring-areas`
            ),
        useErrorBoundary: (error) => error.status === 401,
    });

    const {
        mutateAsync: submitRequest,
        isLoading,
        error,
    } = useMutation({
        mutationKey: 'create-edit-device',
        mutationFn: (data) => {
            if (device) {
                let url = `/api/v1/admin/customers/${companyId}/locations/${locationId}/devices/${device.id}`;
                return Client.put(url, data);
            } else {
                let url = `/api/v1/admin/customers/${companyId}/locations/${locationId}/devices`;
                return Client.post(url, data);
            }
        },
        onSuccess: (values) => {
            setShowModal(false);
            onSuccess();
        }
    });

    const monitoringAreas = monitoringAreaRsp || [];

    const initialValues = {
        name: device?.name || '',
        deviceIP: device?.device_ip || '',
        latitude: device?.latitude || '',
        longitude: device?.longitude || '',
        monitoringAreaId: device?.monitoring_area.id || null,
        companyId: device?.company_id || ''
    };

    const headerLabel = `Update Device ${device?.name}`;

    const areaOptions = (monitoringAreas || []).map((area) => {
        return {
            name: area.name,
            label: area.name,
            value: area.id
        };
    });

    return (
        <FormModal
          setShowModal={setShowModal}
          showModal={showModal}
          title={headerLabel}
          submitBtnLabel="Update"
          initialValues={initialValues}
          onSubmit={submitRequest}
          validate={validate}
          error={error}
        >
            {(formik) => (
                <>
                    <CustomInput
                        label="Name"
                        name="name"
                        formik={formik}
                        required
                    />

                    <CustomInput
                        label="Device IP"
                        name="deviceIP"
                        formik={formik}
                        required
                    />

                    <CustomInput
                        label="Latitude"
                        name="latitude"
                        formik={formik}
                        number
                        required
                    />

                    <CustomInput
                        label="Longitude"
                        name="longitude"
                        formik={formik}
                        number
                        required
                    />

                    <CustomSelect
                        name="monitoringAreaId"
                        label="Monitoring Area"
                        options={[newEntry, ...areaOptions]}
                        formik={formik}
                    />
                </>
            )}
        </FormModal>
    );
};

export default DeviceModal;

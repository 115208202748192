import Hls from 'hls.js';

import Renderer from './Renderer';
import EventHandler from './EventHandler';

const FRAMES_PER_SECOND = 30;

const RESET_INTERVAL = 3000;

const head_down_thresh = -0.3
const head_up_thresh = 0.3
const laying_down_lower_thresh = -0.3
const laying_down_upper_thresh = 0.3

class Streamer {
  constructor() {
    this.resetTimeout = null; // use to check if video is stale
    this.lastFrame = null;
    this.hls = null;

    this.frameTimestamp = null;
    this.framePrevTimestamp = null;

    this.container = null;
    this.deviceIP = null;
    this.videoSrc = null;
    this.canvas = null;

    this.accumulator = 0;
    this.destroyed = false;

    this.renderer = null;
    this.eventHandler = null;

    this.onClick = () => {};
    this.onFatalError = () => {};
    this.onData = () => {};
    this.onFragLoaded = () => {};

    this.shouldDrawBoxes = false;
    this.shouldDrawPoses = false;
    this.shouldDrawGIds = false;
    this.shouldDrawTimestamp = true;

    this.canDrawIcons = false;
    this.shouldDrawMovement = true;
    this.shouldDrawHeadPos = true;

    this.hoverId = null;
    this.activeId = null;

    window.requestAnimationFrame(this.onFrameAnimation.bind(this));

    this.listeners = [];

    this.confidence = 1;
  }

  set showIds(bool) {
    this.shouldDrawGIds = bool;
  }

  set isDebugView(bool) {
    this.shouldDrawBoxes = bool;
    this.shouldDrawPoses = bool;
    this.canDrawIcons = bool;
  }

  zoomIn() {
    if (this.eventHandler) {
      this.eventHandler.zoomIn();
    }
  }

  zoomOut() {
    if (this.eventHandler) {
      this.eventHandler.zoomOut();
    }
  }

  center() {
    if (this.eventHandler) {
      this.eventHandler.center();
    }
  }

  toggleFullscreen() {
    if (this.eventHandler) {
      this.eventHandler.toggleFullscreen();
    }
  }

  frameCoordToCanvas(frame, zoom, offsetX, offsetY) {
    if (!frame?.payload) {
      return;
    }

    const { clientWidth, clientHeight } = this.videoSrc;
    const { frameWidth, frameHeight } = frame.payload;

    return (x, y) => {
      // convert to percent of screen
      const frameX = x / frameWidth;
      const frameY = y / frameHeight;

      // change center of screen to 0, 0
      const relX = (frameX - 0.5) * clientWidth * zoom;
      const relY = (frameY - 0.5) * clientHeight * zoom;

      // scale to screen size and add offsets
      const cameraX = relX + (clientWidth / 2) + offsetX;
      const cameraY = relY + (clientHeight / 2) + offsetY;

      return { x: cameraX, y: cameraY };
    }
  }

  parseFrameData(lastFrame, data) {
    if (!data?.payload?.detections) {
      return data;
    }

    const mapDetections = (map, c) => {
      const { gId, bones, bbox } = c;

      if (gId) {
        const [nose, leftEye, rightEye, leftEar, rightEar, leftShoulder, rightShoulder, leftElbow, rightElbow, leftWrist, rightWrist, leftHip, rightHip, leftKnee, rightKnee] = bones;
        map[gId] = {
          gId,
          nose,
          leftEye,
          rightEye,
          leftEar,
          rightEar,
          leftShoulder,
          rightShoulder,
          leftElbow,
          rightElbow,
          leftWrist,
          rightWrist,
          leftHip,
          rightHip,
          leftKnee,
          rightKnee,
          bbox
        };
      }

      return map;
    };

    const detectionsT0 = lastFrame?.payload?.detections.reduce(mapDetections, {}) || {};

    const parsedDetections = data.payload.detections.map((d) => {
      const { gId, bones} = d;
      const [leftEar, leftShoulder, leftElbow, rightElbow, leftWrist, leftHip, leftKnee, rightKnee] = bones;

      const calcs = {
        walking: false,
        head_position: 'FLAT',
        laying_down: false
      };

      const d0 = detectionsT0[gId];

      if (d0) {
        // Walking - is either x or y coordinate changing for both an elbow and a knee?
        calcs.walking = (
          leftKnee.x !== d0.leftKnee.x
          || leftKnee.y !== d0.leftKnee.y
          || rightKnee.y !== d0.rightKnee.y
          || rightKnee.x !== d0.rightKnee.x
        ) && (
          leftElbow.x !== d0.leftElbow.x
          || leftElbow.y !== d0.leftElbow.y
          || rightElbow.y !== d0.rightElbow.y
          || rightElbow.x !== d0.rightElbow.x
        );
      }

      // Head - is the angle of the neckline to backline outside an expected limit in either direction? Need to handle a dividing by 0 error.
      // I'm unsure which of the 8 cow orientations this is accurate/inaccurate for. We'll need to either normalizing cow orientation or adjust for inaccurate angles (+/-)
      const back_slope = (leftHip.y - leftShoulder.y) / (leftHip.x - leftShoulder.x);
      const neck_slope = (leftShoulder.y - leftEar.y) / (leftShoulder.x - leftEar.x);
      const neck_angle = Math.atan((back_slope - neck_slope) / (1 + back_slope * neck_slope));
      const wrist_slope = (leftWrist.y - leftElbow.y) / (leftWrist.x - leftElbow.x);
      const leg_angle = Math.atan((back_slope - wrist_slope) / (1 + back_slope * wrist_slope));

      if (neck_angle <= head_down_thresh) {
        calcs.head_position = 'HEAD_DOWN'
      } else if (neck_angle >= head_up_thresh) {
        calcs.head_position = 'HEAD_UP'
      }

      // Position - is the slope from wrist to elbow the same as the backline? Need to handle a dividing by 0 error
      // Isn't accurate for cows walking directly towards or away from the camera
      calcs.laying_down = laying_down_lower_thresh <= leg_angle <= laying_down_upper_thresh;

      return { ...d, calcs };
    });

    return {
      ...data,
      payload: {
        ...data.payload,
        detections: parsedDetections
      }
    }
  }

  async startHls() {
    if (this.destroyed) return false;

    const url = `https://video.herdsense.com/${this.deviceIP}/index.m3u8`;

    if (Hls.isSupported()) {
      await new Promise((resolve, reject) => {
        this.hls = new Hls({
          enableWorker: true,
          maxBufferLength: 1,
          liveBackBufferLength: 0,
          liveSyncDuration: 1,
          liveMaxLatencyDuration: 5,
          liveDurationInfinity: true,
          highBufferWatchdogPeriod: 1,
        });

        this.hls.on(Hls.Events.ERROR, (evt, data) => {
          if (!data.fatal) return;

          this.hls.destroy();

          this.onFatalError();
          reject('could not start stream');
        });

        this.hls.on(Hls.Events.MANIFEST_LOADED, () => {
          resolve();
        });

        this.hls.on(Hls.Events.FRAG_LOADED, () => {
          this.onFragLoaded();
          clearTimeout(this.resetTimeout);
          this.resetTimeout = setTimeout(() => {
            this.onFatalError();
          }, RESET_INTERVAL);
        });

        this.hls.loadSource(url);
        this.hls.attachMedia(this.videoSrc);
      });
    } else if (this.videoSrc.canPlayType('application/vnd.apple.mpegurl')) {
      // since it's not possible to detect timeout errors in iOS,
      // wait for the playlist to be available before starting the stream
      await fetch(url);

      this.videoSrc.src = url;
    }

    return true;
  }

  startSockets(socketClient, deviceId) {
    socketClient.subscribe(`device-frame-meta`, deviceId, (data) => {
      this.onData(data);

      const parsedFrameData = this.parseFrameData(this.lastFrame, data);
      console.log(parsedFrameData);
      this.lastFrame = parsedFrameData;
      this.framePrevTimestamp = this.frameTimestamp;
      this.frameTimestamp = new Date().getTime();
    });
  }

  stopSockets(socketClient, deviceId) {
    socketClient.unsubscribe('device-frame-meta', deviceId);
  }

  onFrameAnimation(timestamp) {
    if (this.destroyed) {
      return;
    }

    const delta = timestamp - this.prevTimestamp;
    this.prevTimestamp = timestamp;

    this.accumulator += delta;

    if (!this.renderer || this.accumulator < 1000 / FRAMES_PER_SECOND) {
      window.requestAnimationFrame(this.onFrameAnimation.bind(this));
      return;
    }

    this.accumulator = 0;

    this.renderer.clear();

    const frameCoordToCanvasFn = this.frameCoordToCanvas(
      this.lastFrame,
      this.eventHandler.zoom,
      this.eventHandler.offsetX,
      this.eventHandler.offsetY
    );

    const payload = this.lastFrame?.payload;

    this.eventHandler.registerClickBoxes(payload, frameCoordToCanvasFn);

    this.renderer.render(
      payload,
      this.frameTimestamp,
      this.framePrevTimestamp,
      frameCoordToCanvasFn
    );

    window.requestAnimationFrame(this.onFrameAnimation.bind(this));
  }

  async start(deviceIP, container, videoSrc, canvas) {
    this.container = container;
    this.deviceIP = deviceIP;
    this.videoSrc = videoSrc;
    this.canvas = canvas;

    let started = false;
    try {
      started = await this.startHls();
      await this.videoSrc.play();
    } catch (err) {
      return false;
    }

    if (!started) {
      return false;
    }

    this.renderer = new Renderer(this);
    this.eventHandler = new EventHandler(this);
    this.eventHandler.start();

    this.listeners.forEach((listener) => {
      const { event, fn } = listener;
      this.videoSrc.addEventListener(event, fn);
    });

    return true;
  }

  stop() {
    this.destroyed = true;

    if (this.renderer) {
      this.renderer.clear();
      this.lastFrame = null;
      this.renderer = null;
    }

    if (this.eventHandler) {
      this.eventHandler.stop();
      this.eventHandler = null;
    }

    if (this.hls) {
      this.hls.destroy();
      this.hls = null;
    }

    if (this.videoSrc) {
      this.listeners.forEach((listener) => {
        const { event, fn } = listener;
        this.videoSrc.removeEventListener(event, fn);
      });
    }
  }
}

export default Streamer;

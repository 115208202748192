import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import PageHeader from './Header';
import NoteTable from './NoteTable';
import MapView from './MapView';

import css from './styles.module.scss';

function Dashboard() {
  const [params] = useSearchParams();

  const [view, setView] = useState('map');

  const locationId = params.get('locationId');

  const viewEl = view === 'map'
    ? (
      <MapView
        locationId={locationId}
        view={view}
        setView={setView}
      />
    )
    : (
      <NoteTable
        locationId={locationId}
        view={view}
        setView={setView}
      />
    );

  return (
    <>
      <PageHeader locationId={locationId} view={view} />

      <div className={css.content}>
        {viewEl}
      </div>
    </>
  );
}

export default Dashboard;

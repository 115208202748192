import DeviceHeartBeatsAPI from './DeviceHeartBeatsAPI';
import DeviceEnvResolverAPI from './DeviceEnvResolverAPI';
import DeviceFramesAPI from './DeviceFramesAPI';
import DeviceAppUpdatesAPI from './DeviceAppUpdatesAPI';

export default function DeviceAPIDocs() {
    // CSS styles
    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const contentStyle = {
        textAlign: 'left',
    };
    return (
        <div style={containerStyle}>
            <div style={contentStyle}>
                <h1>API Documentation</h1>
                <DeviceHeartBeatsAPI></DeviceHeartBeatsAPI>
                <DeviceEnvResolverAPI></DeviceEnvResolverAPI>
                <DeviceFramesAPI></DeviceFramesAPI>
                <DeviceAppUpdatesAPI></DeviceAppUpdatesAPI>
            </div>
        </div>
    );
}

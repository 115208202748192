import React from 'react';

const PageTitle = ( props ) => {
  const {
    title = '',
    mBtm = '2rem',
    children = null,
  } = props;

  return (
    <div className={`align-items-start align-items-sm-center d-flex flex-column flex-sm-row`} style={{ marginBottom: mBtm }}>
      <h1 className={`mb-0`}>{title}</h1>

      {children}
    </div>
  )
}


export default PageTitle;
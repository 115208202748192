const header = 'data:image/svg+xml;base64,';

const base64 = btoa(`<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="21" height="21" rx="2.5" fill="#0D1D27"/>
<rect x="0.5" y="0.5" width="21" height="21" rx="2.5" stroke="#F6F6F6"/>
<path d="M9.11475 16.9795H7.05146C5.98184 16.9795 5.11475 16.1124 5.11475 15.0428V15.0428C5.11475 13.4383 6.41539 12.1377 8.01982 12.1377H13.1147C14.7716 12.1377 16.1147 13.4808 16.1147 15.1377V16.9795H14.5757" stroke="#F6F6F6" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.1461 5.60053C13.093 5.8395 13.066 6.08821 13.0682 6.34348C13.0704 6.59875 13.1017 6.84695 13.1589 7.08497L13.1595 7.14864C11.9831 7.15883 11.024 6.20304 11.0138 5.02706C11.0096 4.54371 11.1696 4.0696 11.4684 3.68869L11.591 3.53238C11.6705 3.43088 11.8114 3.40119 11.9251 3.46189C12.0388 3.5226 12.0927 3.65631 12.053 3.77904L11.8727 4.33708C11.8324 4.46182 11.8189 4.59381 11.833 4.72407C11.8847 5.19984 12.287 5.55769 12.7634 5.55356L13.1456 5.55024L13.1461 5.60053Z" fill="#F6F6F6"/>
<path d="M17.1098 5.60004C17.1629 5.83901 17.1899 6.08772 17.1877 6.34299C17.1855 6.59826 17.1542 6.84646 17.0969 7.08448L17.0964 7.14815C18.2728 7.15834 19.2319 6.20256 19.2421 5.02657C19.2463 4.54322 19.0863 4.06911 18.7875 3.68821L18.6649 3.53189C18.5853 3.43039 18.4445 3.4007 18.3308 3.4614C18.2171 3.52211 18.1632 3.65582 18.2029 3.77855L18.3832 4.33659C18.4235 4.46133 18.437 4.59332 18.4229 4.72358C18.3712 5.19935 17.9689 5.5572 17.4924 5.55307L17.1102 5.54975L17.1098 5.60004Z" fill="#F6F6F6"/>
<path d="M15.6527 9.62648C15.4038 10.0024 14.852 10.0024 14.6031 9.62648L12.5446 6.51815C12.2675 6.09972 12.5675 5.54115 13.0694 5.54115L17.1864 5.54115C17.6882 5.54115 17.9883 6.09972 17.7112 6.51815L15.6527 9.62648Z" fill="#F6F6F6"/>
<ellipse cx="15.1281" cy="5.65054" rx="1.77407" ry="0.443505" fill="#F6F6F6"/>
</svg>`);

const icon = `${header}${base64}`;

const img = new Image();
img.src = icon;

export default img;

import React, { forwardRef } from 'react';
import {Modal} from 'react-bootstrap';

import Canvas from './index';

import css from './styles.module.css';

const Viewer = (props, ref) => {
    const {
        showViewer,
        setShowViewer
    } = props;

    const modalBody = (
        <Canvas
            ref={ref}
            {...props}
            className={css.modalCanvas}
            videoClassName={css.modalVideo}
        />
    );

    return (
        <Modal
            size="xl"
            centered
            show={showViewer}
            onHide={() => setShowViewer(false)}>
            <Modal.Header
                style={{justifyContent: 'flex-start'}}
                closeButton
                className="p-3">
            </Modal.Header>

            {modalBody}
        </Modal>
    );
};

export default forwardRef(Viewer);

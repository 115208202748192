import React, {
  useState,
  useContext
} from 'react';

// images
import cow from '../images/cow.jpg';

// styles
import css from './styles.module.scss';
import ForgotPasswordModal from '../Login/ForgotPasswordModal';

import PaneLogin from './PaneLogin';
import PaneCreate from './PaneCreate';
import PaneCompany from './PaneCompany';

import AuthContext from '../context/AuthContext';

function Login() {
    const { userData } = useContext(AuthContext);

    // state
    const [showForgot, setShowForgot] = useState(false);
    const [flow, setFlow] = useState('');

    if (userData) {
        return (
            <div id={css.login} className={`${flow === 'create' ? css.create : ''}`}>
                <div id={css.view}>
                    <div id={css.viewInner}>
                        <div id={css.paneLogin} className={css.pane}>
                          <PaneCompany userData={userData} />
                        </div>

                        <div id={css.paneCow} className={css.pane}>
                          <img src={cow} alt='' id={css.loginCow} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const forgotPasswordModal = showForgot ? (
        <ForgotPasswordModal setShowForgot={setShowForgot} />
    ) : null;

    const loginPane = flow !== 'create'
        ? <PaneLogin setFlow={setFlow} setShowForgot={setShowForgot} />
        : null;

    const createPane = flow === 'create'
        ? <PaneCreate setFlow={setFlow} />
        : null;

    return (
        <div id={css.login} className={`${flow === 'create' ? css.create : ''}`}>
            <div id={css.view}>
                <div id={css.viewInner}>
                    <div id={css.paneLogin} className={css.pane}>
                      {loginPane}
                    </div>

                    <div id={css.paneCow} className={css.pane}>
                      <img src={cow} alt='' id={css.loginCow} />
                    </div>
                    
                    <div id={css.paneCreate} className={css.pane}>
                      {createPane}
                    </div>
                </div>
            </div>
            {forgotPasswordModal}
        </div>
    );
}

export default Login;

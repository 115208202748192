import React from 'react';
import { Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';

import Client from '../../apis/Client';

import ErrorBox from '../../components/ErrorBox';

const FormModal = ({
  setShowModal,
  showModal,
  title,
  submitBtnLabel,
  initialValues,
  onSubmit,
  validate,
  children,
  error,
  disabled
}) => {
  if (!showModal) {
    return null;
  }

  return (
    <Formik
      validate={validate}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <Modal
            backdrop="static"
            size="l"
            centered
            show={showModal}
            onHide={() => setShowModal(false)}
            scrollable
          >
            <Modal.Header closeButton>
              <h3 className='modal-title'>{title}</h3>
            </Modal.Header>

            <Modal.Body>
              {children(formik)}

              <ErrorBox show={!!error} message={error?.message || error} />
            </Modal.Body>

            <Modal.Footer>
              <button
                type="button"
                className={`btn btn-danger`}
                data-dismiss="modal"
                onClick={() => setShowModal(false)}>
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={formik.handleSubmit}
                disabled={disabled || Object.values(formik.errors).length}
              >
                {submitBtnLabel}
              </button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default FormModal;

import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';

import ChatItemNote from './ChatItemNote';

import useMutation from '../../../hooks/useMutation';

import Client from '../../../apis/Client';

import css from './_styles.module.scss';

const SCROLL_OFFSET = 100;
const FETCH_LIMIT = 7;

const ChatItems = forwardRef((props, ref) => {
  const { cattleId, setCattleId, locationId, portal } = props;

  const [cattleList, setCattleList] = useState([]);
  const [isListEnd, setIsListEnd] = useState(false);
  const [search, setSearch] = useState('');
  const [init, setInit] = useState(false);

  const timeoutRef = useRef(null);

  const baseUrl = portal === 'producer'
    ? `/api/v1/producers/locations/${locationId}/cattle?note_open=true&note_type=vet&sort_by=last_chat&asc=desc&limit=${FETCH_LIMIT}`
    : `/api/v1/vets/locations/${locationId}/cattle?all=true&asc=desc&limit=${FETCH_LIMIT}`;

  const { mutate: getCattleNotes, isLoading } = useMutation({
    mutationKey: 'post_cattle_convo_read',
    mutationFn: (offset) => {
      let url = baseUrl + `&offset=${offset}`;
      if (search) {
        url += `&search=${search}`;
      }

      return Client.get(url);
    },
    onSuccess: (data) => {
      if (!cattleId) {
        setCattleId(data[0]?.id);
      }

      if (data.length < FETCH_LIMIT) {
        setIsListEnd(true);
      }

      const merged = cattleList.reduce((map, head) => {
        map[head.id] = head;
        return map;
      }, {});

      data.forEach((head) => {
        merged[head.id] = head;
      });

      setCattleList(Object.values(merged));
    }
  });

  useImperativeHandle(ref, () => {
    return {
      markAsRead(cattleId, val) {
        const index = cattleList.findIndex(c => c.id === cattleId);
        if (index >= 0) {
          const entry = cattleList[index];
          entry.chat_read = val;
          setCattleList([
            ...cattleList.slice(0, index),
            entry,
            ...cattleList.slice(index + 1),
          ]);
        }
      }
    };
  }, [cattleList]);

  function handleSearch(e) {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setSearch(e.target.value);
    }, 400);
  }

  useEffect(() => {
    if (init) {
      getCattleNotes(0);
      setInit(false);
    }
  }, [getCattleNotes, init]);

  useEffect(() => {
    setCattleList([]);
    setInit(true);
  }, [locationId, search]);

  const searchEl = (
    <div className={css.search}>
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.1092 9.77506L13.3523 13.4229C13.6266 13.7316 13.6175 14.2236 13.3324 14.5203C13.1982 14.66 13.0214 14.7368 12.8351 14.7368C12.6383 14.7368 12.4545 14.6522 12.318 14.4988L9.05026 10.8233C8.1224 11.5285 7.03715 11.9 5.89832 11.9C2.86479 11.9 0.396729 9.23077 0.396729 5.94999C0.396729 2.66922 2.86479 0 5.89832 0C8.93185 0 11.3999 2.66922 11.3999 5.94999C11.3999 7.35419 10.943 8.70303 10.1092 9.77506ZM10.0903 5.89469C10.0903 3.45687 8.22674 1.47363 5.93596 1.47363C3.64518 1.47363 1.78157 3.45687 1.78157 5.89469C1.78157 8.33251 3.64518 10.3157 5.93596 10.3157C8.22674 10.3157 10.0903 8.33251 10.0903 5.89469Z" fill="#D9D9D9"/>
      </svg>
      <input type='text' name='search' onInput={handleSearch} />
    </div>
  );

  function onScroll(e) {
    const { scrollHeight, clientHeight, scrollTop } = e.target;

    if (scrollTop + clientHeight >= scrollHeight - SCROLL_OFFSET && !isLoading && !isListEnd) {
      getCattleNotes(cattleList.length);
    }
  }

  let messages = (
    <div className={css.messages} onScroll={onScroll}>
      {cattleList.length
        ? cattleList.map((head) => (
          <ChatItemNote
            key={head.health_note.id}
            note={head.chat_note}
            name={head.name}
            head={head}
            cattleId={cattleId}
            setCattleId={setCattleId}
            monitoringArea={head.monitoring_area}
          />
        ))
        : null
      }
    </div>
  );

  return (
    <div className={css.chatItems}>
      {searchEl}
      {messages}
    </div>
  );
});

export default ChatItems;

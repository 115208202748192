import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Client from '../../apis/Client';

import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import RemoveModal from '../../components/RemoveModal';
import CustomTable from '../../components/CustomTable';
import LocationModal from '../components/LocationModal';

import useQuery from '../../hooks/useQuery';

import css from '../styles.module.scss';

const Locations = (props) => {
    const { customer } = props;

    const { data, isLoading, isError, error, refetch } = useQuery({
        queryKey: ['locations'],
        queryFn: () =>
            Client.get(`/api/v1/admin/customers/${customer.id}/locations`),
        useErrorBoundary: (error) => error.status === 401,
    });

    const [showLocationModal, setShowLocationModal] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const activeLocation = useRef(null);
    const navigate = useNavigate();

    const columnsInfo = [
        {
            name: 'name',
            label: 'Name',
        },
        {
            name: 'state',
            label: 'State',
            valFn: (row) => row.address.state,
        },
        {
            name: 'city',
            label: 'City',
            valFn: (row) => row.address.city,
        },
        {
            name: 'address',
            label: 'Address',
            valFn: (row) => {
                const streetAddr = row.address.address2
                    ? `${row.address.address1} ${row.address.address2}`
                    : row.address.address1;

                return `${streetAddr}, ${row.address.city}, ${row.address.state}`;
            },
        },
        {
            name: 'locationContact',
            label: 'Location Contact',
            valFn: (row) => `${row.poc_first_name} ${row.poc_last_name}`,
        },
        {
            name: 'email',
            label: 'Contact Email',
            valFn: (row) => `${row.poc_email}`,
        },
        {
            name: 'phone',
            label: 'Contact Phone',
            valFn: (row) => `${row.poc_phone}`,
        },
        {
            name: 'expectedHeadCount',
            label: 'Total Heads',
        },
        {
            name: 'users',
            label: 'Users',
            valFn: (row) => `${row.accounts.length}`,
        },
    ];

    const locations = data || [];
    return (
        <div id="mainContent" className="admin">
            <div id={css.locations} className="tableContain">
                <PageSubTitle title="Locations" />
                {/* {isLoading && <Loader></Loader>}
                {isError && <h4 style={{ color: 'red' }}>{error.msg}</h4>} */}
                <div style={{ width: '100%', height: 'calc(200px)' }}>
                    <CustomTable
                        rows={locations}
                        columns={columnsInfo}
                        onRowClick={(e, loc) => navigate(`/admin/customers/${customer.id}/locations/${loc.id}`)}
                    />
                </div>
                <div id={css.actionsBlock} className="actions">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            activeLocation.current = null;
                            setShowLocationModal(true);
                        }}>
                        Add New Location
                    </button>
                </div>

                <LocationModal
                    location={activeLocation.current}
                    onSuccess={refetch}
                    companyId={customer?.id}
                    showModal={showLocationModal}
                    setShowModal={setShowLocationModal}
                />

                {showRemoveModal && (
                    <RemoveModal
                        title="Remove Location"
                        message={`Are you sure you want to remove location ${activeLocation.current.name}?`}
                        onRemoved={() => {
                            setShowRemoveModal(false);
                            refetch();
                        }}
                        endPoint={`/api/v1/admin/locations/${activeLocation.current.id}`}
                        customer={customer}
                        user={activeLocation.current}
                        showModal={showRemoveModal}
                        setShowModal={setShowRemoveModal}
                    />
                )}
            </div>
        </div>
    );
};

export default Locations;

import React from 'react'
import Modal from 'react-bootstrap/Modal'
import useMutation from '../hooks/useMutation';
import Client from '../apis/Client'

const RemoveUserModal = (props) => {
    const {
        showModal = false,
        setShowModal = () => {},
        endPoint,
        message,
        title,
        onRemoved,
    } = props

    const {
        mutate: submitRemove,
        isError,
        error,
    } = useMutation({
        mutationKey: ['remove_obj'],
        mutationFn: () => Client.delete(endPoint),
        onSuccess: () => {
            onRemoved()
        },
    })

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="modal-sm"
            centered
            show={showModal}
            onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <form
                onSubmit={(e) => {
                    e.preventDefault()
                }}>
                <Modal.Body>
                    <h4>{message}</h4>
                    {isError && (
                        <h4 style={{ color: 'red' }}>{error.message}</h4>
                    )}
                </Modal.Body>
                <Modal.Footer
                    className={`align-items-center d-flex justify-content-between border-0 bgGray246`}>
                    <button
                        type="button"
                        className={`btn btn-danger`}
                        data-dismiss="modal"
                        onClick={() => setShowModal(false)}>
                        No
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={submitRemove}>
                        Yes
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default RemoveUserModal

import React from 'react'

const IconCompliance = props => {

  const {
    color = '#3099FD',
  } = props

  return (
    <svg className="mb-5 mx-auto" width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="70" cy="70" r="67" fill="white" stroke={color} strokeWidth="6"/>
      <path d="M70 35C64.3396 35 59.5188 38.6504 57.7448 43.75H52.6667C47.8865 43.75 44 47.6738 44 52.5V96.25C44 101.076 47.8865 105 52.6667 105H87.3333C92.1135 105 96 101.076 96 96.25V52.5C96 47.6738 92.1135 43.75 87.3333 43.75H82.2552C80.4813 38.6504 75.6604 35 70 35ZM70 52.5C67.6031 52.5 65.6667 50.5449 65.6667 48.125C65.6667 45.7051 67.6031 43.75 70 43.75C72.3969 43.75 74.3333 45.7051 74.3333 48.125C74.3333 50.5449 72.3969 52.5 70 52.5ZM85.6813 72.707L68.3479 90.207C66.8719 91.6973 64.4615 91.6973 62.9854 90.207L54.3188 81.457C52.8427 79.9668 52.8427 77.5332 54.3188 76.043C55.7948 74.5527 58.2052 74.5527 59.6813 76.043L65.6667 82.0859L80.3188 67.293C81.7948 65.8027 84.2052 65.8027 85.6813 67.293C87.1573 68.7832 87.1573 71.2168 85.6813 72.707Z" fill={color} />
    </svg>
  )
}

export default IconCompliance;
import React from 'react';
import { Formik, Form } from 'formik';
import {Modal} from 'react-bootstrap';
import useMutation from '../../hooks/useMutation';

import CustomInput from '../../components/forms/CustomInput';
import CustomSelect from '../../components/forms/CustomSelect';
import FormModal from '../../components/FormModal';

import {required} from '../../validation/form';

import Client from '../../apis/Client';

import css from './styles.module.scss';

const validate = (values) => {
    const errors = {};

    required(errors, values, 'cattleId');
    required(errors, values, 'description');

    return errors;
};

const pullPriorities = [
    {value: 'low', label: 'Low'},
    {value: 'medium', label: 'Medium'},
    {value: 'high', label: 'High'},
];

const vetPriorities = [
    {value: 'check', label: 'Check'},
    {value: 'urgent', label: 'Urgent'}
];

function HealthNoteModal(props) {
  const {
    showModal,
    setShowModal,
    refetch,
    areaId,
    locationId,
    type,
    cattleId
  } = props;

  const initialValues = {
    type,
    cattleId: cattleId?.toString(),
    description: '',
    areaId: areaId?.toString(),
  };

  if (type === 'pull') {
    initialValues.priority = 'medium';
  } else if (type === 'vet') {
    initialValues.priority = 'check';
  }

  const { mutate: onSubmit, isLoading, error } = useMutation({
    mutationKey: 'post_health_note',
    mutationFn: (data) => {
      const url = `/api/v1/producers/locations/${locationId}/health-notes`;
      return Client.post(url, data);
    },
    onSuccess: (data) => {
      refetch();
      setShowModal(false);
    },
  });

  let priorityOptions = null;
  if (type === 'pull') {
    priorityOptions = pullPriorities;
  } else if (type === 'vet') {
    priorityOptions = vetPriorities;
  }

  const healthNoteTitle = type ?
    `Add ${type.charAt(0).toUpperCase().concat(type.slice(1).toLowerCase())} Note`
    : null;

  return (
    <FormModal
      setShowModal={setShowModal}
      showModal={showModal}
      title={healthNoteTitle}
      submitBtnLabel="Save"
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      error={error}
      disabled={isLoading}
    >
      {(formik) => (
        <>
          <CustomInput
            label="Cattle ID"
            name="cattleId"
            formik={formik}
            disabled={true}
            placeholder="Cattle ID here"
          />
          
          {priorityOptions && (
            <CustomSelect
              label="Priority"
              name="priority"
              formik={formik}
              options={priorityOptions}
            />
          )}

          <CustomInput
            label="Description"
            name="description"
            formik={formik}
            textarea
            placeholder="Add description here"
            required
          />
        </>
      )}
    </FormModal>
  );
}

export default HealthNoteModal;

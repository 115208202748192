import React from 'react'

const LogoHorns = ({ fill = '#ffffff'}) => (
  <svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.719 17.765C10.7197 17.7704 10.7206 17.7759 10.7216 17.7815C10.7227 17.7876 10.7238 17.7939 10.7246 17.8002H17.8243C17.825 17.7942 17.8261 17.7884 17.8271 17.7826C17.8281 17.7768 17.8292 17.771 17.8299 17.765C18.2675 16.2715 19.4116 14.5005 20.1442 13.6534C21.3474 12.3002 22.0742 10.4838 22.0742 8.4471C22.0742 4.10893 18.5687 0.561884 14.2476 0.575233C9.72895 0.589283 6.47422 4.30496 6.47422 8.4471C6.47422 10.4424 7.20281 12.2615 8.40472 13.6534C9.1373 14.5005 10.2812 16.2715 10.719 17.765ZM9.42597 12.7705C9.42592 12.7705 9.42586 12.7704 9.42581 12.7703C8.42883 11.6153 7.82422 10.1072 7.82422 8.4471C7.82422 4.98932 10.5353 1.93678 14.2518 1.92523C17.812 1.91423 20.7242 4.84245 20.7242 8.4471C20.7242 10.1564 20.1166 11.6527 19.1353 12.7564L19.1291 12.7633L19.1231 12.7703C18.451 13.5474 17.4766 14.9965 16.8694 16.4502H11.6794C11.0722 14.9967 10.0981 13.5477 9.42597 12.7705Z" fill={fill} />
    <path d="M10.7287 20.8107C10.7287 21.0935 10.8092 21.3694 10.9631 21.6051L11.7237 22.7589C11.9567 23.1125 12.4856 23.4002 12.9059 23.4002H15.6425C16.0613 23.4002 16.5903 23.1127 16.8233 22.7589L17.5812 21.6056C17.7116 21.4063 17.8185 21.05 17.8185 20.8107L17.8242 19.0502H10.7242L10.7287 20.8107Z" fill={fill} />
    <path d="M22.7992 10.3553C22.9464 9.71906 23.0242 9.05621 23.0242 8.3752C23.0242 7.69418 22.9464 7.03133 22.7992 6.39506V6.2609H23.8189C25.0901 6.2609 26.155 5.29699 26.2818 4.02659C26.3164 3.67878 26.2774 3.32697 26.167 2.99516L25.6731 1.51065C25.5645 1.18417 25.705 0.826232 26.0069 0.661657C26.3089 0.497082 26.6853 0.57304 26.8999 0.841957L27.2306 1.25613C28.0364 2.26534 28.4742 3.52642 28.4742 4.81593C28.4742 7.95329 25.9378 10.5252 22.7992 10.5252V10.3553Z" fill={fill} />
    <path d="M5.74922 6.39506C5.60202 7.03133 5.52422 7.69418 5.52422 8.3752C5.52422 9.05621 5.60202 9.71906 5.74922 10.3553V10.5252C2.61068 10.5252 0.0742188 7.95329 0.0742188 4.81593C0.0742188 3.52642 0.512036 2.26534 1.31784 1.25613L1.64859 0.841957C1.86317 0.57304 2.23959 0.497082 2.54151 0.661657C2.84344 0.826232 2.98399 1.18417 2.87536 1.51065L2.38146 2.99516C2.27106 3.32697 2.23205 3.67878 2.26663 4.02659C2.39343 5.29699 3.45838 6.2609 4.72949 6.2609H5.74922V6.39506Z" fill={fill} />
  </svg>
)

export default LogoHorns
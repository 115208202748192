import React, { useRef } from 'react'

// images
import imgAnalytics from '../images/constantAnalysis.jpg'
import imgEcosystems from '../images/ecosystems.jpg'
import imgGrow from '../images/grow.jpg'
import imgHero from '../images/hero-dev.jpg'
import imgInTheKnow from '../images/in-the-know.jpg'
import imgBg from '../images/dev-icon-bg.jpg'
import imgAppMk from '../images/appMarketplace.jpg'
import imgdevGrow from '../images/devDevelopGrow.jpg'

import css from './styles.module.scss'

import HomeHero from './Components/HomeHero/HomeHero'
import HomeCard from './Components/HomeCard/HomeCard'
import IconCow from '../icons/Cow'
import IconAI from '../icons/AI'
import IconCamera from '../icons/Camera'
import IconSecurity from '../icons/Security'
import IconPrivacy from '../icons/Privacy'
import IconBilling from '../icons/Billing'
import IconHardware from '../icons/Hardware'
import IconConnectivity from '../icons/Connectivity'
import IconInnovation from '../icons/Innovation'
import IconHealth from '../icons/Health'
import IconAnalytics from '../icons/Analytics'
import IconCompliance from '../icons/Compliance'

const HomeContent = ({ title = '', content = '', classes = 'col-12' }) => {
  return (
    <div className={`d-flex flex-column position-relative ${classes} ${css.homeContent}`} style={{ zIndex: 10 }}>
      {title && <h2 className={`mb-5`}>{title}</h2>}
      {content}
    </div>
  )
}

const Category = () => {
  const cats = [
    {
      icon: <IconHealth />,
      title: 'Health & Safety',
      para: `Applications focused on the well being and health of the animals`
    },
    {
      icon: <IconAnalytics />,
      title: 'Analytics',
      para: `Tracks information about the animals to derive deeper insights to drive higher ROI on each animal`
    },
    {
      icon: <IconCompliance />,
      title: 'Compliance',
      para: `Applications focused on ensuring environmental, employee or lot compliance`
    },
  ]

  const catEls = cats.map(cat => {
    return (
      <div key={cat.title} className={`align-items-center d-flex flex-column justify-content-start ${css.card}`}>
        {cat.icon}
        <h3 className={`mb-2`}>{cat.title}</h3>
        <p className={`text-center`}>{cat.para}</p>
      </div>
    )
  })
  
  return (
    <div className={`row align-items-start d-flex flex-column flex-md-row justify-content-md-between g-0 ${css.holder}`}>
      {catEls}
    </div>
  )
}

const HomeDevelopers = () => {
  const formRef = useRef(null)

  const scriptUrl = "https://script.google.com/macros/s/AKfycbzE6y99VC95zwjvE131svEfCtpsMmk1ocLQ-_yEfYIQVrv1zCnhTXr6w1hNHM1z9bNk/exec"

  const handleSubmitForm = (e) =>{
    e.preventDefault()

    fetch(scriptUrl, {method: 'POST', body: new FormData(formRef.current)})
    .then(res => {
      alert("SUCCESSFULLY SUBMITTED")
    })
    .catch(err => console.log(err))
  }

  return (
    <section className={`container d-flex flex-column ${css.container}`}>

      <HomeHero
        h1={<>Bring Your Imagination<br />We'll Bring the Edge</>}
        sub={`Herd Sense brings an unprecedented opportunity for software developers to deploy innovative AI solutions related to the beef production industry.`}
        img={imgHero} />

      <HomeCard id='apps'>
        <HomeContent
          title='Develop Beefy Apps'
          content={
            <>
              <p>Herd Sense provides infrastructure to deploy AI solutions for software companies.</p>
              <p>Initial roll out to feedlots, monitoring 2 Million+ head in the first year.</p>
            </>
          }
          classes='col-12 col-md-6' />

        <div className={`col-sm-6 col-md-3 col-md-3 mb-5 mb-sm-0 px-3 text-center ${css.industry}`}>
          <IconCow title='$72 Billion Industry' color='#3099FD' />
        </div>
        <div className={`col-sm-6 col-md-3 col-md-3 px-3 text-center ${css.ai}`}>
          <IconAI title='Virtually No AI Representation' />
        </div>
      </HomeCard>

      <HomeCard id='mgmt'>
        <div className={`col-12 align-items-start d-flex flex-column flex-md-row ${css.iconFocus}`} style={{ backgroundImage: `url(${imgBg})` }}>
          <div className={`col-12 col-md-9 mb-sm-5 mb-md-0 pe-md-3 ${css.manages}`}>
            <h4 className={`col-12 py-2 text-center bg-white text-black w-100`}>Herd Sense Manages</h4>
              <div className="align-items-end d-flex flex-row flex-wrap">
              <div className="col-6 col-sm-4 col-md-2 mb-5 mb-md-0 text-center">
                <IconCamera title='Cameras' color='#ffffff' textColor='#ffffff' />
              </div>      
              <div className="col-6 col-sm-4 col-md-2 mb-5 mb-md-0 text-center">
                <IconSecurity title='Security' color='#ffffff' textColor='#ffffff' />
              </div>      
              <div className="col-6 col-sm-4 col-md-2 mb-5 mb-md-0 text-center">
                <IconPrivacy title='Privacy' color='#ffffff' textColor='#ffffff' />
              </div>      
              <div className="col-6 col-sm-4 col-md-2 mb-5 mb-sm-0 text-center">
                <IconBilling title='Billing' color='#ffffff' textColor='#ffffff' />
              </div>      
              <div className="col-6 col-sm-4 col-md-2 mb-5 mb-sm-0 text-center">
                <IconHardware title='Hardware' color='#ffffff' textColor='#ffffff' />
              </div>      
              <div className="col-6 col-sm-4 col-md-2 mb-5 mb-sm-0 text-center">

                <IconConnectivity title='Connectivity' color='#ffffff' textColor='#ffffff' />
              </div>
            </div>
          </div>

          <div className={`col-12 col-md-3 ps-md-3 ${css.focus}`}>
            <h4 className={`col-12 py-2 text-center bg-white text-black`}>Developer Focus</h4>
            <div className="align-items-end d-flex flex-row flex-wrap">
              <div className="col-6 col-sm-4 col-md-12 mb-md-0 text-center">
                <IconInnovation title='Innovations' color='#ffffff' textColor='#ffffff' />
              </div>
            </div>
          </div>
        </div>

        <HomeContent
          content={
            <>
              <p>Herd Sense manages the camera, all connectivity concerns, security, privacy, customer billing and edge computing hardware so developers can focus on the innovative solutions that provide profitability for both themselves and the feedlot operator.</p>
            </>
          }
        />
      </HomeCard>

      <HomeCard id='cta' classes='justify-content-between position-relative'>
        <div className={`${css.greenBorder}`} />

        <HomeContent
          classes='col-12 align-items-center d-flex flex-column flex-sm-row'
          content={
            <>
              <div className={`col-sm-7`}>
                <h3 className={`mb-4 ${css.h3}`}>Herd Sense Technical Documentation</h3>
                <p className={`${css.p}`}>Get access to our technical documentation to start developing apps for the Herd Sense platform today.</p>
              </div>

              <div className={`col-sm-5 pe-sm-5 text-end`}>
                <a href="#pdf" className={`align-items-center btn btn-primary d-flex justify-content-center ms-auto position-relative ${css.btn}`}>
                  <span className="position-relative">Download PDF</span>
                </a>
              </div>
            </>
          } />
      </HomeCard>

      <HomeCard id='analysis' classes='flex-sm-column position-relative' minHeight='400' valign='start'>
          
        <div className={`col-md-6 ${css.content}`}>
          <HomeContent
            content={
              <>
                <h2 className={`mb-4 ${css.h2}`}>Constant Analysis</h2>
                <p>Herd Sense processes 24/7 video of cattle to classify and uniquely identify each individual cow. This group of classified cattle paired with tag numbers is passed to 3rd party applications to utilize their own custom built AI. These applications in turn can raise alerts or deliver insights to operators.</p>
                <p>This can also be paired with web applications to deliver deeper data analytics.</p>
              </>
            }
          >
          </HomeContent>          
        </div>
        
        <div className={`col-md-6 position-absolute bg ${css.noOverlay}`} style={{ backgroundImage: `url(${imgAnalytics})` }} />
      </HomeCard>

      <HomeCard id='appMarketplace' classes='flex-sm-column position-relative' minHeight='400' valign='start'>
        <div className={`col-md-6 position-absolute bg ${css.noOverlay}`} style={{ backgroundImage: `url(${imgAppMk})` }} />
          
        <div className={`col-md-6 offset-md-6 ${css.content}`}>
          <HomeContent
            content={
              <>
                <h2 className={`mb-4 ${css.h2}`}>Holistic App Marketplace</h2>
                <p>Herd Sense applications can do a wide range of tasks and solve many different and interesting problems, bringing virtually unlimited value to beef producers, and a superior product to consumers.</p>
                <p>Operators can install apps via the marketplace a la carte, read and write reviews, manage hardware, herd, and installed apps.</p>
              </>
            }
          >
          </HomeContent>
        </div>
      </HomeCard>

      <div id={css.categories} className={`d-flex flex-column`}>
        <Category />
      </div>

      <HomeCard id='developGrow' classes='flex-sm-column position-relative' minHeight='400' valign='start'>
        <div className={`col-md-6 position-absolute bg ${css.noOverlay}`} style={{ backgroundImage: `url(${imgdevGrow})` }} />
          
        <div className={`col-md-6 offset-md-6 ${css.content}`}>
          <HomeContent
            content={
              <>
                <h2 className={`mb-4 ${css.h2}`}>Develop and Grow Together</h2>
                <p>It is easy to imagine developers using Herd Sense to build applications that provide alerts when cattle are not eating or drinking enough, spooked by something outside the pen, or even analyzing step counts.</p>
                <p>Operators will pay for apps on a per-head observed basis, of which only a fraction will go towards Herd Sense.</p>
              </>
            }
          >
          </HomeContent>
        </div>
      </HomeCard>

      <HomeCard id={'inTheKnow'} classes='p-0 position-relative' minHeight='340' valign='start'>
        <div className={`col-12 position-absolute bg ${css.noOverlay}`} style={{ backgroundImage: `url(${imgInTheKnow})` }} />
        
        <div className={`col-12 position-absolute`} />
        
        <div className={`col-12`}>
          <HomeContent
            classes={`align-items-start d-flex flex-column flex-md-row text-white`}
            content={
              <>
                <div className="col-md-6 pe-md-3">
                  <h3 className={`mb-3`}>Be in the Know</h3>
                  <p className={css.sm}>Herd Sense is currently under development and in an invite-only stage of testing.</p>
                  <p className={css.sm}>Leave your email address and we'll be happy to schedule a meeting for a deeper discussion about the benefits of being included in the Herd Sense platform.</p>
                </div>
                
                <div className="col-12 col-md-6 col-lg-5 offset-lg-1 ps-md-3 position-relative text-end">
                  <form id={css.formInTheKnow} method='post' ref={formRef} className={`align-items-start d-flex flex-column flex-sm-row flex-wrap`} onSubmit={handleSubmitForm}>
                    <div className={`col-12 align-items-start d-flex flex-column flex-sm-row py-0 px-0`}>
                      <div className={`col-12 col-sm-6 d-flex flex-column pe-sm-3 ${css.item}`}>
                        <label htmlFor="first_name" className="text-start w-100">First Name</label>
                        <input type="text" name="first_name" id="first_name" className="w-100" />
                      </div>
                      <div className={`col-12 col-sm-6 d-flex flex-column ps-sm-3 ${css.item}`}>
                        <label htmlFor="last_name" className="text-start w-100">Last Name</label>
                        <input type="text" name="last_name" id="last_name" className="w-100" />
                      </div>
                    </div>
                    <div className={`col-12 d-flex flex-column py-0 px-0 ${css.item}`}>
                      <label htmlFor="email" className="text-start w-100">Email</label>
                      <input type="email" name="email" id="email" className="w-100" />
                    </div>

                    <button type="submit" className={`btn ms-auto ${css.btn}`}>Submit</button>
                  </form>
                </div>
              </>
            }
          >
          </HomeContent>
        </div>
      </HomeCard>

      {/* <HomeCard id='ecosystem' classes='flex-sm-column position-relative' minHeight='400' valign='start'>
        <div className={`col-md-6 ${css.content}`}>
          <HomeContent
            content={
              <>
                <h2 className={`mb-4 ${css.h2}`}>Extensive App Ecosystem</h2>
                <p>Herd Sense applications can do a wide range of tasks and solve many different and interesting problems, bringing virtually unlimited value to beef producers, and a superior product to consumers.</p>
                <p>Operators can install apps via the marketplace a la carte, read and write reviews, manage hardware, herd, and installed apps.</p>
              </>
            }
          >
          </HomeContent>
        </div>
          
        <div className={`col-md-6 position-absolute bg ${css.noOverlay}`} style={{ backgroundImage: `url(${imgEcosystems})` }} />
      </HomeCard> */}

    </section>
  )
}

export default HomeDevelopers
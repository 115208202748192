import React, { useRef, useContext } from 'react';
import useMutation from '../../hooks/useMutation';
import { Form, Formik } from 'formik';

import useQuery from '../../hooks/useQuery';

import Client from '../../apis/Client';

import ErrorBox from '../../components/ErrorBox';
import RoleSelect from '../../components/RoleSelect';
import CustomInput from '../../components/forms/CustomInput';
import CustomSelect from '../../components/forms/CustomSelect';
import FormModal from '../../components/FormModal';

import AuthContext from '../../context/AuthContext';

import { required, phone, email } from '../../validation/form';

const validate = (values) => {
  let errors = {};
  
  const requiredFields = ['roles', 'email'];
  requiredFields.forEach((field) => {
    required(errors, values, field);
  });

  phone(errors, values, 'phone');
  email(errors, values, 'email');

  return errors;
};

const UserModal = ({
  setShowModal = () => {},
  showModal,
  user,
  locationId,
  refetch,
}) => {
  const isEdit = !!user?.id;

  const { userData } = useContext(AuthContext);

  const { data: roles, isLoading } = useQuery({
    queryKey: ['roles'],
    queryFn: () => {
      return Client.get('/api/v1/producers/roles');
    },
  });

  const { mutate: onSubmit, error, reset: resetSubmit } = useMutation({
    mutationKey: 'create-edit-user',
    mutationFn: (data) => {
      if (user) {
        return Client.put(`/api/v1/producers/locations/${locationId}/users/${user.company_account_id}`, data);
      } else {
        return Client.post(`/api/v1/producers/locations/${locationId}/users`, data);
      }
    },
    onSuccess: () => {
      setShowModal(false);
      refetch();
    },
    useErrorBoundary: (error) => error.status === 401,
  });

  const { data: existingUsers, mutate: findExistingUser, existingUserLoading } = useMutation({
    mutationKey: 'find_existing_user',
    mutationFn: (email) => Client.get(`/api/v1/producers/locations/${locationId}/users?email=${email}`)
  });

  const existingUser = existingUsers?.length ? existingUsers[0] : null;

  const timeoutRef = useRef(null);

  function handleFindExistingUser(email) {
    resetSubmit();

    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      findExistingUser(email);
    }, 400);
  }

  const initialValues = {
    firstName: user?.first_name || existingUser?.first_name || '',
    lastName: user?.last_name || existingUser?.last_name || '',
    phone: user?.phone || existingUser?.phone || '',
    email: user?.email || existingUser?.email || '',
    roles: user?.roles[0]?.id || existingUser?.roles[0]?.id || (roles || [])[0]?.id || null,
    company_id: userData.company_id
  };

  const headerLabel = isEdit
    ? `Edit ${user?.first_name} ${user?.last_name}`
    : 'Add User';

  const existingUserError = !isEdit && !!existingUser ? 'User has already been added' : null;

  const isVet = isEdit && !!user?.roles.find(r => r.type === 'vet');

  const filteredRoles = isEdit && !isVet ? (roles || [])
    ?.filter(r => r.type === 'producer') : roles || [];

  return (
    <FormModal
      setShowModal={setShowModal}
      showModal={showModal}
      title={headerLabel}
      submitBtnLabel={user ? "Update" : "Create"}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      error={existingUserError || error}
      disabled={existingUserLoading || (!isEdit && !!existingUser) || isVet}
    >
      {(formik) => (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 15,
        }}>
          <RoleSelect
            label="Role"
            name="roles"
            formik={formik}
            roles={filteredRoles}
            required
            single
            disabled={existingUserLoading || !!existingUser || isVet}
          />

          <CustomInput
            label="Email"
            name="email"
            formik={formik}
            required
            disabled={!!user}
            onChange={(e) => handleFindExistingUser(e.target.value)}
          />

          <CustomInput
            label="First Name"
            name="firstName"
            formik={formik}
            disabled={existingUserLoading || !!existingUser || isVet}
          />

          <CustomInput
            label="Last Name"
            name="lastName"
            formik={formik}
            disabled={existingUserLoading || !!existingUser || isVet}
          />

          <CustomInput
            label="Phone"
            name="phone"
            formik={formik}
            disabled={existingUserLoading || !!existingUser || isVet}
          />
        </div>
      )}
    </FormModal>
  );
};

export default UserModal;

import React, {useState, useEffect, useRef} from 'react'

import css from './styles.module.scss'

function InputBase(props) {
    const {
        label,
        name,
        formik,
        required,
        className,
        message,
        icon,
        children,
        dropdown
    } = props;

    const containerRef = useRef();
    const innerContainerRef = useRef();

    const error = formik?.touched[name] ? formik?.errors[name] : null;

    const textClass = error ? css.errorText : '';
    const spanClass = error ? css.errorSpan : css.normSpan;

    const asterisk = required
        ? <span className={`position-absolute ${css.errorText}`}>*</span>
        : '';

    const iconEl = icon ? (
        <div
            className={`align-items-center d-flex justify-content-center position-absolute icon`}>
            <svg
                width="12"
                height="17"
                viewBox="0 0 12 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                {icon}
            </svg>
        </div>
    ) : null;

    const containerClasses = ['item', css.container];
    if (className) {
        containerClasses.push(className);
    }

    if (icon) {
        containerClasses.push('icon');
    }

    if (error) {
        containerClasses.push(css.error);
    }

    return (
        <div className={containerClasses.join(' ')} ref={containerRef}>
            <div ref={innerContainerRef}>
                <div className={css.inputContainer}>
                    <label
                        htmlFor={name}
                        className={`position-relative ${textClass} ${css.label}`}>
                        {label}
                        {asterisk}
                    </label>
                    {children}
                    {iconEl}
                </div>
                {dropdown}
            </div>
            {error || message && <span className={`${css.message} ${spanClass} ${textClass}`}>{error || message}</span>}
        </div>
    )
}

export default InputBase

import LogoHorns from '../../icons/LogoHorns';
import LogoCattle from '../../icons/LogoCattle';
import LogoPig from '../../icons/LogoPig';

import css from './styles.module.scss';
import { useLocation } from 'react-router-dom';
import MobTrigger from '../MobTrigger/MobTrigger';

export default function TopHeader(props) {
  const {
    links,
    label,
    query = '',
    customEls,
    navOpen,
    setNavOpen,
    blank,
  } = props;

  const location = useLocation()

  const linkEls = links?.map((link) => (
    <li key={link.path} className={location.pathname === link.path ? css.active : ''}>
      <a href={`${link.path}${query}`}>
        {link.title}
      </a>
    </li>
  ));

  if (blank === true) {
    return <div id={css.header} />
  }

  return (
    <div id={css.header}>
      <MobTrigger navOpen={navOpen} setNavOpen={setNavOpen} />

      <a href="#top" className={css.logoHolder}>
        <div className={css.logoIcons}>
          <span>
            <LogoHorns />
          </span>
          <span>
            <LogoCattle />
          </span>
          <span>
            <LogoPig />
          </span>
        </div>
        <span>{label}</span>
      </a>

      <nav id={css.nav} className={navOpen ? css.opened : ''}>
        <ul>
          {linkEls}
          {customEls}
        </ul>
      </nav>
    </div>
  );
}

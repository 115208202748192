import React, {useState} from 'react';
import useMutation from '../../hooks/useMutation';

import ReviewClient from '../../apis/Review';

const ReviewModal = (props) => {
  const {
    app,
    build,
    onCancel,
    onAccept,
    onReject
  } = props;

  const underReview = build.status === 'under_review';

  const [notes, setNotes] = useState(!underReview ? build.review_notes : '');

  const {mutate: downloadBuild} = useMutation({
    mutationKey: 'app_build_download',
    mutationFn: () => ReviewClient.downloadBuild(build.file_url)
  });

  const buttonEls = build.status === 'under_review'
    ? (
      <>
        <button type="button" className={`btn btn-outline-secondary me-3`} data-dismiss="modal" onClick={() => onCancel(false)}>Cancel</button>
        <div>
          <button
            type="button"
            className={`btn btn-primary ms-3`}
            data-dismiss="modal"
            onClick={() => onAccept({appId: app.id, buildId: build.id, notes})}
            disabled={!notes}
          >
            Accept
          </button>
          <button
            type="button"
            className={`btn btn-danger ms-3`}
            data-dismiss="modal"
            onClick={() => onReject({appId: app.id, buildId: build.id, notes})}
            disabled={!notes}
          >
            Reject
          </button>
        </div>
      </>
    )
    : (
      <>
        <div />
        <button type="button" className={`btn btn-outline-secondary me-3`} data-dismiss="modal" onClick={() => onCancel(false)}>Close</button>
      </>
    );

  return (
    <div className="modal fade show" id="exampleModalLive" tabIndex="-1" aria-labelledby="newDevModalLabel" style={{ display: 'block' }} aria-modal="true" role="dialog">
      
      <div className={`position-fixed backdrop`} onClick={() => onCancel(false)} />

      <form className={`d-inline m-0`} onSubmit={e => e.preventDefault()}>
        <div className={`modal-dialog-centered modal-dialog-scrollable modal`}>

          <div className={`modal-content`}>
            <div className={`modal-header position-relative bgNavy textWhite`}>
              <h5 className="modal-title" id="newDevModalLabel">{app.title} - v{build.version}</h5>
              <button type="button" className={`border-0 position-absolute close`} data-dismiss="modal" aria-label="Close" onClick={() => onCancel()}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.1341 2.58996L12 1.72791L10.2721 0L9.41004 0.862047L6 4.27209L2.58996 0.865862L1.72791 0L0 1.72791L0.862047 2.58996L4.27209 6L0.865861 9.41004L0 10.2721L1.72791 12L2.58996 11.138L6 7.72791L9.41004 11.138L10.2721 12L12 10.2721L11.138 9.41004L7.72791 6L11.138 2.58996H11.1341Z" fill="white"/>
                </svg>
              </button>
            </div>

            <div className={`modal-body d-flex flex-column`}>
              <div>
                <button
                  onClick={downloadBuild}
                  className={`btn btn-primary mb-4`}
                >
                  Download Build
                </button>
              </div>
              <h3>Notes for Reviewer</h3>
              <textarea
                defaultValue={build.notes_for_reviewer}
                readOnly={true}
                style={{height: '200px', resize: 'none'}}
                className={`mb-4`}
              />
              <h3>Reviewer Notes</h3>
              <textarea
                value={notes}
                onChange={e => setNotes(e.target.value)}
                readOnly={!underReview}
                style={{height: '200px', resize: 'none'}}
              />
            </div>

            <div className={`align-items-center d-flex flex-row justify-content-between modal-footer border-0 bgGray246`}>
              {buttonEls}
            </div>
          </div>

        </div>
      </form>
    </div>
  )
}

export default ReviewModal;

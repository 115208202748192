import React from 'react'

const IconPrivacy = props => {

  const {
    title = '',
    color = '#3099FD',
    textColor = '#000000'
  } = props

  return (
    <>
      <svg width="60" height="48" viewBox="0 0 60 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.63796 0.478641C2.66298 -0.290092 1.24738 -0.111971 0.478644 0.863008C-0.290093 1.83799 -0.111971 3.25358 0.863012 4.02231L56.362 47.5214C57.337 48.2901 58.7526 48.112 59.5214 47.137C60.2901 46.162 60.112 44.7464 59.137 43.9777L49.2747 36.2529C52.9871 32.4467 55.4995 28.1812 56.7652 25.1531C57.0745 24.4125 57.0745 23.5875 56.7652 22.8469C55.3683 19.5001 52.434 14.6252 48.0466 10.5565C43.6404 6.45039 37.5749 3.00046 30 3.00046C23.6064 3.00046 18.2815 5.46603 14.1284 8.70034L3.63796 0.478641ZM20.9158 14.0158C23.3064 11.8315 26.5032 10.5003 30 10.5003C37.453 10.5003 43.4998 16.547 43.4998 24C43.4998 26.3343 42.9091 28.528 41.8685 30.4405L38.2499 27.6093C38.7373 26.5031 38.9998 25.2843 38.9998 24C38.9998 19.0314 34.9687 15.0002 30 15.0002C29.7375 15.0002 29.475 15.0096 29.2125 15.0377C29.7094 15.9096 30 16.922 30 18.0001C30 18.9564 29.775 19.8563 29.3813 20.6532L20.9158 14.0158ZM41.831 41.9527L34.9687 36.5528C33.4312 37.1622 31.7531 37.4997 30 37.4997C22.547 37.4997 16.5002 31.453 16.5002 24C16.5002 23.3531 16.5471 22.725 16.6315 22.1063L7.79102 15.1408C5.65355 17.9251 4.12545 20.7001 3.23485 22.8469C2.92548 23.5875 2.92548 24.4125 3.23485 25.1531C4.6317 28.4999 7.56602 33.3748 11.9534 37.4435C16.3596 41.5496 22.4251 44.9995 30 44.9995C34.4812 44.9995 38.428 43.7902 41.831 41.9527Z" fill={color} />
      </svg>

      {title && <h4 style={{ color: textColor }}>{title}</h4>}
    </>
  );
}

export default IconPrivacy;
import React, { useState } from 'react';
import useMutation from '../../hooks/useMutation';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';

import useQuery from '../../hooks/useQuery';

import SortableTableHeader from '../../components/SortableTableHeader/SortableTableHeader';

import ReviewModal from './ReviewModal';

import ReviewClient from '../../apis/Review';

import css from '../styles.module.scss';

function AppDetails() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [selectedBuild, setSelectedBuild] = useState(null);
    const [sortAssets, setSortAssets] = useState('title');
    const [sortAsc, setSortAsc] = useState(true);
    const [showReviewModal, setShowReviewModal] = useState(false);

    const {
        data: app,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: ['get_review_app'],
        queryFn: () => ReviewClient.getReviewApp(id),
        useErrorBoundary: (error) => error.status === 401,
    });

    const { mutate: acceptBuild } = useMutation({
        mutationKey: 'accept_build',
        mutationFn: ({ appId, buildId, notes }) =>
            ReviewClient.acceptBuild(appId, buildId, notes),
        onSuccess: () => {
            setShowReviewModal(false);
            refetch();
        },
    });

    const { mutate: rejectBuild } = useMutation({
        mutationKey: 'reject_build',
        mutationFn: ({ appId, buildId, notes }) =>
            ReviewClient.rejectBuild(appId, buildId, notes),
        onSuccess: () => {
            setShowReviewModal(false);
            refetch();
        },
    });

    if (isLoading) {
        return <div></div>;
    }

    const headerArgs = [
        `text-start ${css.col1}`,
        sortAssets,
        setSortAssets,
        sortAsc,
        setSortAsc,
    ];

    function openBuildModal(build) {
        setSelectedBuild(build);
        setShowReviewModal(true);
    }

    function goBack() {
        navigate('/review/apps');
    }

    const buildEls = app.builds
        .map((build) => {
            build.fullName = `${build.contact_fname} ${build.contact_lname}`;
            return build;
        })
        .sort((item1, item2) => {
            const sOpL = sortAsc ? -1 : 1;
            const sOpG = sortAsc ? 1 : -1;

            return item1[sortAssets] < item2[sortAssets] ? sOpL : sOpG;
        })
        .map((build, i) => {
            const statusStyle =
                build.status === 'under_review'
                    ? { color: '#F16060' }
                    : { color: '#383838' };

            const statusText =
                build.status === 'under_review' ? (
                    <strong style={statusStyle}>Under Review</strong>
                ) : (
                    build.status.charAt(0).toUpperCase() + build.status.slice(1)
                );

            return (
                <tr
                    key={i}
                    className={`clickable-row show`}
                    onClick={() => openBuildModal(build)}>
                    <td className={`text-start ${css.col1}`}>
                        {build.version}
                    </td>
                    <td className={`text-start ${css.col1}`}>{statusText}</td>
                    <td className={`text-start ${css.col2}`}>
                        {build.fullName}
                    </td>
                    <td className={`text-start ${css.col3}`}>
                        {build.contact_email}
                    </td>
                    <td className={`text-start ${css.col3}`}>
                        {build.contact_phone}
                    </td>
                    <td className={`text-start ${css.col4}`}>
                        {moment.utc(build.upload_date).local().format('M/D/YYYY h:mm a')}
                    </td>
                </tr>
            );
        });

    const reviewModal = showReviewModal ? (
        <ReviewModal
            app={app}
            build={selectedBuild}
            onCancel={() => setShowReviewModal(false)}
            onAccept={acceptBuild}
            onReject={rejectBuild}
        />
    ) : null;

    return (
        <div className={css.page}>
            <div>
                <button
                    className="btn btn-outline-primary me-auto mb-4"
                    onClick={goBack}>
                    <svg
                        className={`me-4`}
                        width="6"
                        height="10"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.162909 4.6098L4.70779 0.159755C4.8129 0.0567475 4.95323 0 5.10285 0C5.25247 0 5.39279 0.0567475 5.49791 0.159755L5.83261 0.487394C6.0504 0.700889 6.0504 1.04788 5.83261 1.26105L2.01616 4.99793L5.83684 8.73895C5.94196 8.84196 6 8.97928 6 9.1257C6 9.27228 5.94196 9.4096 5.83684 9.51269L5.50215 9.84025C5.39694 9.94325 5.2567 10 5.10708 10C4.95746 10 4.81714 9.94325 4.71202 9.84025L0.162909 5.38614C0.0575418 5.2828 -0.000330925 5.14484 1.43051e-06 4.99817C-0.000330925 4.85094 0.0575418 4.71305 0.162909 4.6098Z"
                            fill="#3099FD"
                        />
                    </svg>
                    Back to apps
                </button>
            </div>
            <h1>{app.title}</h1>
            <div className={css.pageContent}>
                <div>
                    <div className="d-flex justify-content-between my-4">
                        <span>
                            <strong>Company:</strong> {app.company.name}
                        </span>
                        <span>
                            <strong>Category:</strong> {app.category}
                        </span>
                        <span>
                            <strong>Pricing:</strong> ${app.cost}
                        </span>
                    </div>
                    <div className="my-4">
                        <span>
                            <strong>Promotional Description:</strong>
                        </span>
                        <br />
                        <span>{app.description}</span>
                    </div>
                    <div className="my-4">
                        <span>
                            <strong>Long Description:</strong>
                        </span>
                        <br />
                        <span>{app.long_description}</span>
                    </div>
                    <div className="my-4">
                        <span>
                            <strong>Copyright:</strong> {app.copyright}
                        </span>
                    </div>
                    <div className="my-4">
                        <span>
                            <strong>Privacy Policy:</strong>{' '}
                            {app.privacy_policy}
                        </span>
                        <span>
                            <strong>Terms Of Service:</strong>{' '}
                            {app.terms_of_service}
                        </span>
                    </div>
                </div>
            </div>
            <h2>Builds</h2>
            <div className={css.pageContent}>
                <div className={`bgWhite tableContain`}>
                    <table className={`table table-striped mb-0`}>
                        <thead>
                            <tr>
                                <SortableTableHeader
                                    sortName="upload_date"
                                    displayName="Version"
                                    {...headerArgs}
                                />
                                <SortableTableHeader
                                    sortName="status"
                                    displayName="Status"
                                    {...headerArgs}
                                />
                                <SortableTableHeader
                                    sortName="fullName"
                                    displayName="Contact Name"
                                    {...headerArgs}
                                />
                                <SortableTableHeader
                                    sortName="contact_email"
                                    displayName="Contact Email"
                                    {...headerArgs}
                                />
                                <SortableTableHeader
                                    sortName="contact_phone"
                                    displayName="Contact Phone"
                                    {...headerArgs}
                                />
                                <SortableTableHeader
                                    sortName="upload_date"
                                    displayName="Upload Date"
                                    {...headerArgs}
                                />
                            </tr>
                        </thead>
                        <tbody>{buildEls}</tbody>
                    </table>
                </div>
            </div>

            {reviewModal}
        </div>
    );
}

export default AppDetails;

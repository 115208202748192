import React from 'react';
import {useNavigate} from 'react-router-dom';

function DevAppNotFound() {
  const navigate = useNavigate();

  function toOverview() {
    navigate('/developers/apps/overview');
  }

  return (
    <div id='mainContent'>
      <h1>404 Page Not Found</h1>
      <button onClick={toOverview}>Return to app overview</button>
    </div>
  );
}

export default DevAppNotFound;

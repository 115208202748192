import React, { useContext } from 'react';
import { Formik, Form } from 'formik';
import useMutation from '../hooks/useMutation';
import css from './styles.module.scss';
import PaneSelect from './PaneSelect';
import LogoHorns from '../icons/LogoHorns';
import LogoCattle from '../icons/LogoCattle';
import LogoPig from '../icons/LogoPig';

import {required} from '../validation/form';

import useQuery from '../hooks/useQuery';

import Loader from '../components/Loader';

import Account from '../apis/Account';
import Client from '../apis/Client';

import AuthContext from '../context/AuthContext';

const validate = (values) => {
    const errors = {};
 
    required(errors, values, 'company');

    return errors;
};

const PaneCompany = (props) => {
    const { userData } = props;

    const { selectCompany } = useContext(AuthContext);

    const { data: userCompanies, error, isLoading } = useQuery({
        queryKey: ['get_user_companies', userData.user_id],
        queryFn: () => Account.getUserCompanies(userData.user_id)
    });

    const { mutate: loginToCompany } = useMutation({
        mutationKey: 'company_login',
        mutationFn: (companyId) => Account.loginToCompany(companyId),
        onSuccess: (body) => {
            const { userData } = body;
            selectCompany(userData);
        }
    });

    const onSubmit = (data) => {
        loginToCompany(data.company);
    };

    const companyOptions = userCompanies?.map(c => ({
        value: c.company_id,
        label: c.name
    })) || [];

    const initialValues = {
        company: companyOptions[0]?.value || ''
    };

    let errorMsg = null;
    if (error) {
        if (error.status === 500) {
            errorMsg =
                'Sorry, there is a problem with our servers. We are currently working to fix the issue.';
        }
    }

    return (
        <div className={css.paneInner}>
            <div className={css.logo}>
                <div className={css.logoIcons}>
                    <span>
                        <LogoHorns fill='#29313B' />
                    </span>
                    <span>
                        <LogoCattle fill='#29313B' />
                    </span>
                    <span>
                        <LogoPig fill='#29313B' />
                    </span>
                </div>
            </div>

            <h1 className={`col-12 px-0`}>Company Select</h1>

            <p className={`col-12 px-0`}>
                Your account is associated with multiple companies, please select one.
            </p>

            <Formik
                validate={validate}
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {(formik) => (
                    <Form
                        id={css.loginForm}
                        className={`px-0 ${css.form}`}
                        onSubmit={formik.handleSubmit}
                    >
                        <Loader loading={isLoading}>
                            <PaneSelect
                                className="col-12 d-flex flex-column p-0 text-start"
                                name="company"
                                label="Company"
                                options={companyOptions}
                                required
                                formik={formik}
                            />
                        </Loader>

                        <span
                            className="px-0"
                            style={{ color: 'var(--bs-danger)' }}>
                            {errorMsg}
                        </span>

                        <div className={`align-items-center d-flex flex-column flex-sm-row justify-content-end p-0 mt-4 ${css.actions}`}>
                            <div className={`col-12 col-sm-6 d-flex flex-column justify-content-center justify-content-sm-end m-0 p-0 ${css.item}`}>
                                <button
                                    type="submit"
                                    className={`btn btn-primary ms-auto me-auto me-sm-0 ${
                                        isLoading ? 'btn-disabled' : 'btn-primary'
                                    }`}
                                    disabled={isLoading || Object.keys(formik.errors).length}
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default PaneCompany;

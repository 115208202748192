import {useState, useEffect} from 'react';

function useTextInput(defaultValue, validation = []) {
  const [value, setValue] = useState(defaultValue || '');
  const [pristine, setPristine] = useState(true);
  const [touched, setTouched] = useState(false);
  const [modified, setModified] = useState(false);
  const [error, setError] = useState(null);
  const [valid, setValid] = useState(false);
  const [pendingValidation, setPendingValidation] = useState(false);

  useEffect(() => {
    function validate() {
      setError(null);
      setValid(true);
      setPendingValidation(true);

      for (const fn of validation) {
        const message = fn(value);
        if (typeof message === 'string') {
          setValid(false);
          if (touched) {
            setError(message);
          }
          break;
        }
      }

      setPendingValidation(false);
    }

    validate();
  }, [value, validation, touched]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  function reset() {
    setPristine(true);
    setTouched(false);
    setModified(false);
  }

  return {
    value,
    setValue,
    valid: valid && !pendingValidation,
    pristine,
    setPristine,
    touched,
    setTouched,
    error,
    setError,
    pendingValidation,
    setPendingValidation,
    modified,
    setModified,
    reset
  };
}

export default useTextInput;

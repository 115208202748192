const header = 'data:image/svg+xml;base64,';

const base64 = btoa(`<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="21" height="21" rx="2.5" fill="#0D1D27"/>
<rect x="0.5" y="0.5" width="21" height="21" rx="2.5" stroke="#F6F6F6"/>
<path d="M7.85134 11.4657C7.76697 11.8453 7.72406 12.2404 7.72757 12.646C7.73109 13.0515 7.78084 13.4458 7.87178 13.824L7.87266 13.9251C6.00369 13.9413 4.47998 12.4229 4.46378 10.5546C4.45713 9.78672 4.71133 9.0335 5.18597 8.42837L5.38079 8.18002C5.50719 8.01878 5.73095 7.9716 5.91159 8.06805C6.09223 8.16449 6.17777 8.37691 6.11478 8.57189L5.82832 9.45845C5.7643 9.65661 5.74288 9.86631 5.76527 10.0732C5.84733 10.8291 6.48648 11.3976 7.24341 11.391L7.85065 11.3858L7.85134 11.4657Z" fill="#F6F6F6"/>
<path d="M14.1484 11.4657C14.2328 11.8453 14.2757 12.2404 14.2722 12.646C14.2687 13.0515 14.2189 13.4458 14.128 13.824L14.1271 13.9251C15.9961 13.9413 17.5198 12.4229 17.536 10.5546C17.5426 9.78672 17.2884 9.0335 16.8138 8.42837L16.619 8.18002C16.4926 8.01878 16.2688 7.9716 16.0882 8.06805C15.9075 8.16449 15.822 8.37691 15.885 8.57189L16.1714 9.45845C16.2355 9.65661 16.2569 9.86631 16.2345 10.0732C16.1524 10.8291 15.5133 11.3976 14.7563 11.391L14.1491 11.3858L14.1484 11.4657Z" fill="#F6F6F6"/>
<path d="M11.8337 17.8616C11.4382 18.4588 10.5616 18.4588 10.1662 17.8616L6.89591 12.9235C6.45568 12.2587 6.93235 11.3713 7.72966 11.3713L14.2702 11.3713C15.0675 11.3713 15.5442 12.2587 15.104 12.9235L11.8337 17.8616Z" fill="#F6F6F6"/>
<ellipse cx="10.9998" cy="11.5454" rx="2.81843" ry="0.70459" fill="#F6F6F6"/>
<path d="M10.4327 6.03535L9.70119 6.765L9.70246 6.76691C9.55918 6.91019 9.36485 6.99068 9.16222 6.99068C8.95959 6.99068 8.76525 6.91019 8.62197 6.76691C8.47869 6.62363 8.39819 6.42929 8.39819 6.22666C8.39819 6.02403 8.47869 5.8297 8.62197 5.68642L10.6558 3.65255C10.7267 3.58145 10.8109 3.52503 10.9036 3.48653C10.9963 3.44804 11.0957 3.42822 11.1961 3.42822C11.2965 3.42822 11.3959 3.44804 11.4886 3.48653C11.5813 3.52503 11.6655 3.58145 11.7363 3.65255L13.7702 5.68642C13.9135 5.8297 13.994 6.02403 13.994 6.22666C13.994 6.42929 13.9135 6.62363 13.7702 6.76691C13.6269 6.91019 13.4326 6.99068 13.2299 6.99068C13.0273 6.99068 12.833 6.91019 12.6897 6.76691L11.9581 6.03535V8.76836C11.9581 8.97064 11.8778 9.16464 11.7348 9.30767C11.5917 9.4507 11.3977 9.53106 11.1954 9.53106C10.9932 9.53106 10.7992 9.4507 10.6561 9.30767C10.5131 9.16464 10.4327 8.97064 10.4327 8.76836V6.03535Z" fill="#F6F6F6"/>
</svg>`);

const icon = `${header}${base64}`;

const img = new Image();
img.src = icon;

export default img;

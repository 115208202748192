import React, { useContext } from 'react';

import css from './styles.module.scss';
import IconEllipsis from '../../icons/Ellipsis';
import AccountClient from '../../apis/Account';
import AuthContext from '../../context/AuthContext';

function Sidebar(props) {
  const { setLocation, locations, isLoading, selected } = props;

  const { logout } = useContext(AuthContext);

  const token = AccountClient.accessToken();

  const sidebarEls = isLoading
    ? null
    : locations.map((l) => {
      const isSelected = selected === l.id;

      const locationStr = `${l.name || `${l.address.city}, ${l.address.state}`}`;

      return (
        <div key={l.id} onClick={() => setLocation(l.id)} className={`${css.pen} ${isSelected ? css.active : null}`}>
          <span>{locationStr}</span>
          <div className={css.length}>{l.count_check + l.count_urgent}</div>
        </div>
      )
    });

  const infoBlock = (
    <div className={css.infoBlock}>
      <div className={css.top}>
        {(token?.first_name || token?.last_name) && <div className={css.name}>{token?.first_name} {token?.last_name}</div>}
        <div>{token?.email}</div>
      </div>

      <button onClick={logout} className={`${css.btn}`}>Logout</button>
    </div>
  );

  return (
    <div className={css.sidebar}>
      <div className={css.title}>
        My Vet Checks
        <IconEllipsis />
      </div>

      {sidebarEls}

      {/* {infoBlock} */}
    </div>
  );
}

export default Sidebar;

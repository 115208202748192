import React from 'react'

const IconCarat = props => {

  const {
    color = '#3099FD',
    dir = 'left'
  } = props

  let point = dir === 'left'
    ? 0
    : dir === 'up'
        ? 90
        :  dir === 'right'
            ? 180
            : 270

  return (
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: `rotate(${point}deg)` }}>
        <path d="M0.190059 6.46824L5.49242 11.8083C5.61505 11.9319 5.77876 12 5.95332 12C6.12788 12 6.29159 11.9319 6.41423 11.8083L6.80471 11.4151C7.0588 11.1589 7.0588 10.7425 6.80471 10.4867L2.35219 6.00249L6.80965 1.51326C6.93229 1.38965 7 1.22487 7 1.04916C7 0.873262 6.93229 0.708483 6.80965 0.584777L6.41917 0.191707C6.29644 0.068098 6.13282 8.77863e-07 5.95826 8.62603e-07C5.7837 8.47342e-07 5.61999 0.0680979 5.49736 0.191707L0.190059 5.53664C0.0671316 5.66064 -0.000386652 5.8262 1.00118e-06 6.00219C-0.000386683 6.17888 0.0671315 6.34434 0.190059 6.46824Z" fill={color} />
    </svg>
  )
}

export default IconCarat;

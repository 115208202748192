const IconNoFeed = ({ style }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g filter="url(#filter0_d_5507_3787)">
      <rect x="4" y="4" width="24" height="24" rx="12" fill="#EBB01D" shapeRendering="crispEdges"/>
      <path d="M23.5906 12.3318C23.4672 12.2691 23.3289 12.2417 23.1909 12.2527C23.0529 12.2637 22.9206 12.3128 22.8088 12.3943L21.25 13.5268V12.4999C21.25 12.1684 21.1183 11.8505 20.8839 11.6161C20.6495 11.3816 20.3315 11.2499 20 11.2499H15.7425C15.5436 11.2499 15.3528 11.329 15.2122 11.4696C15.0715 11.6103 14.9925 11.801 14.9925 11.9999C14.9925 12.1989 15.0715 12.3896 15.2122 12.5303C15.3528 12.6709 15.5436 12.7499 15.7425 12.7499H19.75V16.9999C19.75 17.1118 19.7751 17.2222 19.8233 17.3231C19.8716 17.424 19.9418 17.5128 20.0288 17.5831C20.0381 17.5912 20.0481 17.5987 20.0588 17.6068L22.8088 19.6068C22.9207 19.6882 23.0529 19.7371 23.1909 19.748C23.3288 19.7589 23.4671 19.7315 23.5904 19.6686C23.7138 19.6058 23.8173 19.5101 23.8896 19.3921C23.9619 19.2741 24.0001 19.1383 24 18.9999V12.9999C24 12.8617 23.9617 12.7261 23.8895 12.6082C23.8173 12.4903 23.7138 12.3946 23.5906 12.3318ZM22.5 17.5268L21.25 16.6181V15.3818L22.5 14.4731V17.5268ZM11.555 9.99557C11.4891 9.92132 11.4092 9.86089 11.3198 9.81777C11.2304 9.77465 11.1333 9.7497 11.0342 9.74437C10.9351 9.73904 10.836 9.75343 10.7425 9.78671C10.649 9.81999 10.563 9.8715 10.4895 9.93825C10.4161 10.005 10.3566 10.0857 10.3146 10.1756C10.2725 10.2655 10.2487 10.3628 10.2446 10.462C10.2405 10.5611 10.256 10.6601 10.2904 10.7532C10.3248 10.8463 10.3774 10.9317 10.445 11.0043L10.6681 11.2499H10C9.66848 11.2499 9.35054 11.3816 9.11612 11.6161C8.8817 11.8505 8.75 12.1684 8.75 12.4999V19.4999C8.75 19.8315 8.8817 20.1494 9.11612 20.3838C9.35054 20.6182 9.66848 20.7499 10 20.7499H19.3044L20.445 22.0043C20.5795 22.1488 20.7655 22.2344 20.9626 22.2426C21.1598 22.2509 21.3523 22.1811 21.4984 22.0483C21.6444 21.9156 21.7323 21.7306 21.7429 21.5336C21.7535 21.3365 21.686 21.1432 21.555 20.9956L11.555 9.99557ZM10.25 19.2499V12.7499H12.0319L17.9412 19.2499H10.25Z" fill="#383838"/>
      </g>
      <defs>
      <filter id="filter0_d_5507_3787" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5507_3787"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5507_3787" result="shape"/>
      </filter>
      </defs>
    </svg>
  );
}

export default IconNoFeed;

import React from 'react';
import Modal from 'react-bootstrap/Modal';

function InfoModal(props) {
  const {
    message,
    showAlert = false,
    setShowAlert,
  } = props;

  if (!showAlert) {
    return null;
  }

  return (
    <Modal
      aria-labelledby='contained-modal-title-vcenter'
      dialogClassName='modal-sm'
      centered
      onHide={() => setShowAlert(false)}
      show={showAlert}
    >
      <Modal.Header closeButton>
        <Modal.Title>Information</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>

      <Modal.Footer className={`align-items-center d-flex justify-content-end border-0 bgGray246`}>
        <button data-testid='close-info-button' type='button' className={`btn btn-primary`} onClick={()=>{setShowAlert(false)}}>Close</button>
      </Modal.Footer>
    </Modal>
  )
}

export default InfoModal;
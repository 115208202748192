import React from 'react'

const IconBilling = props => {

  const {
    title = '',
    color = '#3099FD',
    textColor = '#000000',
  } = props

  return (
    <>
      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30 60C46.5703 60 60 46.5703 60 30C60 13.4297 46.5703 0 30 0C13.4297 0 0 13.4297 0 30C0 46.5703 13.4297 60 30 60ZM32.4375 15.6563V17.3203C33.5742 17.4609 34.7109 17.7773 35.8359 18.0938C36.0586 18.1523 36.2695 18.2109 36.4922 18.2812C37.8398 18.6562 38.6367 20.0508 38.2617 21.3984C37.8867 22.7461 36.4922 23.5312 35.1445 23.168C34.9688 23.1211 34.7812 23.0625 34.5938 23.0156C33.7734 22.7812 32.9531 22.5586 32.1211 22.3945C30.5742 22.1016 28.7812 22.2422 27.3398 22.8633C26.0508 23.4258 24.9844 24.7852 26.4492 25.7227C27.5977 26.4609 29.0039 26.8359 30.3398 27.1992C30.6211 27.2695 30.8906 27.3516 31.1602 27.4219C32.9883 27.9375 35.3203 28.6055 37.0664 29.8008C39.3398 31.3594 40.4062 33.8906 39.9023 36.6094C39.4219 39.2344 37.5938 40.957 35.4023 41.8477C34.4883 42.2227 33.4922 42.457 32.4492 42.5742V44.3555C32.4492 45.75 31.3125 46.8867 29.918 46.8867C28.5234 46.8867 27.3867 45.75 27.3867 44.3555V42.3164C25.6875 41.9297 24.0234 41.3906 22.3711 40.8516C21.0469 40.418 20.3203 38.9766 20.7656 37.6523C21.2109 36.3281 22.6406 35.6016 23.9648 36.0469C24.2578 36.1406 24.5508 36.2461 24.8438 36.3398C26.168 36.7852 27.5273 37.2422 28.8867 37.4648C30.8789 37.7578 32.4727 37.582 33.5156 37.1602C34.9219 36.5977 35.5898 34.9219 34.207 33.9844C33.0234 33.1758 31.5586 32.7773 30.1641 32.4023C29.8945 32.332 29.6367 32.2617 29.3672 32.1797C27.5977 31.6758 25.3828 31.0547 23.7188 29.9883C21.4336 28.5234 20.2734 26.0859 20.7656 23.3789C21.2344 20.8242 23.2266 19.125 25.3359 18.2109C25.9805 17.9297 26.6719 17.707 27.3867 17.543V15.6563C27.3867 14.2617 28.5234 13.125 29.918 13.125C31.3125 13.125 32.4492 14.2617 32.4492 15.6563H32.4375Z" fill={color} />
      </svg>

      {title && <h4 style={{ color: textColor }}>{title}</h4>}
    </>
  );
}

export default IconBilling;
import Client from './Client';

export default class Review extends Client {
  static getReviewBuilds(appId) {
    return this.get(`/api/v1/review/apps/${appId}`);
  }

  static acceptBuild(appId, buildId, notes) {
    return this.post(`/api/v1/review/apps/${appId}/builds/${buildId}/accept`, {reviewerNotes: notes});
  }

  static rejectBuild(appId, buildId, notes) {
    return this.post(`/api/v1/review/apps/${appId}/builds/${buildId}/reject`, {reviewerNotes: notes});
  }

  static getReviewApps() {
    return this.get(`/api/v1/review/apps`);
  }

  static getReviewApp(appId) {
    return this.get(`/api/v1/review/apps/${appId}`);
  }

  static downloadBuild(link) {
    return this.get(link);
  }
}

import React from 'react';
import {
    Route,
    Outlet,
    Navigate,
    useLocation
} from 'react-router-dom';

import ProtectedLayout from '../layouts/ProtectedLayout';

import ReviewAppsPage from '../Review/Apps';
import ReviewAppDetailPage from '../Review/AppDetails';
import {
    DEVELOPER_PATH,
} from '../constants';

const DefaultReview = () => {
    const location = useLocation();
    const isDefaultLoc = location.pathname.endsWith('/review');
    if (isDefaultLoc) {
        return <Navigate to="/review/apps" replace></Navigate>;
    } else {
        return <Outlet></Outlet>;
    }
};

const ReviewRouter = (
    <Route path="/review" element={<DefaultReview />}>
        <Route
            element={
                <ProtectedLayout portalDefaultPath={DEVELOPER_PATH} />
            }>
            <Route path="apps" element={<ReviewAppsPage />} />
            <Route
                path="apps/:id"
                element={<ReviewAppDetailPage />}
            />
        </Route>
    </Route>
);

export default ReviewRouter;

import React from 'react';

import HeaderGraph from './HeaderGraph';
import HeaderStats from './HeaderStats';

import css from './styles.module.scss';

function Header(props) {
  const { view } = props;

  return (
    <div
      className={css.container}
      style={{ gap: 25 }}
    >
      <div className={css.title}>
        <h1>Summary</h1>
      </div>
      <div className={`${css.headerInfo} ${view === 'map' ? css.map : ''}`}>
        <HeaderStats {...props} />
        <HeaderGraph {...props} />
      </div>
    </div>
  );
}

export default Header;

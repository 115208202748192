import React from 'react';
import { Formik, Form } from 'formik';

import Client from '../../apis/Client';

import {required, latitude, longitude, noSpaces} from '../../validation/form';

import Loader from '../../components/Loader/Loader';
import CustomTable from '../../components/CustomTable';

import { copyToClipboard } from '../../util';

import useQuery from '../../hooks/useQuery';
import useMutation from '../../hooks/useMutation';

import css from '../styles.module.scss';

const specialHeartBeatCols = [
    {
        name: 'timestamp',
        label: 'Timestamp',
        valFn: (datum) => new Date(datum.msg.timestamp).toLocaleString()
    },
    {
        name: 'device_id',
        label: 'Device ID',
    },
    {
        name: 'bandWidth',
        label: 'Total Bytes/Second',
        valFn: (datum) => {
            let bandWidth = datum.msg.bandWidth || 0;
            return bandWidth;
        },
    },
    {
        name: 'bytesPerSecond',
        label: 'Bytes Upload/Second',
        valFn: (datum) => {
            if (datum.msg.apps) {
                for (const appId in datum.msg.apps) {
                    const appInfo = datum.msg.apps[appId];
                    if (appInfo.payload && appInfo.payload.bandwidthData) {
                        return appInfo.payload.bandwidthData
                            .uploadBytesPerSecond;
                    }
                }
            }
            return '';
        },
    },
    {
        name: 'msg',
        label: 'Message',
        componentFn: (row) => (
                <div className={css.overflowContainer}>
                    <span>{JSON.stringify(row.msg)}</span>
                </div>
        ),
        width: 400
    },
    {
        name: 'copy',
        label: '',
        componentFn: (row) => (
            <button className="btn btn-primary" onClick={() => copyToClipboard(JSON.stringify(row.msg))}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none">
                    <path d="M17.5 14H19C20.1046 14 21 13.1046 21 12V5C21 3.89543 20.1046 3 19 3H12C10.8954 3 10 3.89543 10 5V6.5M5 10H12C13.1046 10 14 10.8954 14 12V19C14 20.1046 13.1046 21 12 21H5C3.89543 21 3 20.1046 3 19V12C3 10.8954 3.89543 10 5 10Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
        ),
        width: 50
    }
];

const DeviceModal = ({
    setShowModal = () => {},
    device = {}
}) => {
    const {data: heartBeats, isLoading, isError, error} = useQuery({
        queryKey: ['heart-beats'],
        queryFn: () => Client.get(`/api/v1/admin/devices/${device.id}/heartbeats`)
    });

    if (isLoading) {
        return <Loader></Loader>;
    }

    return (
        <div
            className="modal fade show"
            id="customerModal"
            tabIndex="-1"
            aria-labelledby="customerModalLabel"
            style={{ display: 'block' }}
            aria-modal="true"
            role="dialog">
            <div
                className={`position-fixed backdrop`}
                onClick={() => {
                    setShowModal(false);
                }}
            />
            <CustomTable
                columns={specialHeartBeatCols}
                rows={heartBeats || []}
                rowKey="id"
            />}
        </div>
    );
};

export default DeviceModal;

import React from 'react'
import IconGrid from '../../icons/icon-grid'
import IconList from '../../icons/icon-list'

export default function ButtonGroupFilter({
  showGridTable = false,
  activeGridTable,
  filters,
  onClick,
  activeFilter,
}) {
  if (showGridTable) {
    return (
      <div className='btn-group gridList'>
        <button
          onClick={() => onClick('grid')}
          className={activeGridTable === 'grid' ? 'active' : null}
        >
          <IconGrid color={activeGridTable === 'grid' ? '#d9d9d9' : '#3E9CDB' } />
        </button>
        <button
          onClick={() => onClick('list')}
          className={activeGridTable === 'list' ? 'active' : null}
        >
          <IconList color={activeGridTable === 'list' ? '#d9d9d9' : '#3E9CDB' } />
        </button>
      </div>
    )
  }

  return (
    <div className='btn-group pills'>
      {filters.map((datum) => (
        <button
          key={datum}
          onClick={() => onClick(datum)}
          className={activeFilter === datum ? 'active' : null}
        >{datum}</button>
      ))}
    </div>
  )
}

import React from 'react'

import css from './styles.module.scss'

export { default as useTextInput } from './useTextInput'
export * from './validation'

function TextInput(props) {
    const {
        label,
        name,
        data,
        onChange,
        onKeyDown,
        onBlur,
        required,
        className = '',
        message,
        textarea,
        inputStyle,
        icon,
        disabled,
        password,
        placeholder,
        itemStyle,
        labelStyle,
    } = props

    function internalOnChange(e) {
        data.setModified(true)
        data.setValue(e.target.value)
        if (onChange) onChange(e)
    }

    function internalOnKeyDown(e) {
        data.setPristine(false)
        if (onKeyDown) onKeyDown(e)
    }

    function internalOnBlur(e) {
        if (!data.pristine) {
            data.setTouched(true)
        }
        if (onBlur) onBlur(e)
    }

    const textClass = data.error ? css.errorText : ''
    const inputClass = data.error ? css.errorInput : ''
    const spanClass = data.error ? css.errorSpan : css.normSpan

    const asterisk = required ? (
        <span className={`position-absolute ${css.errorText}`}>*</span>
    ) : (
        ''
    )

    const inputEl = textarea ? (
        <textarea
            id={name}
            name={name}
            className={`w-100 ${inputClass}`}
            style={inputStyle}
            value={data.value || ''}
            onChange={internalOnChange}
            onKeyDown={internalOnKeyDown}
            onBlur={internalOnBlur}
            disabled={disabled}
            placeholder={placeholder}
        />
    ) : (
        <input
            type={password ? 'password' : 'text'}
            id={name}
            className={`w-100 ${inputClass}`}
            name={name}
            value={data.value || ''}
            onChange={internalOnChange}
            onKeyDown={internalOnKeyDown}
            onBlur={internalOnBlur}
            disabled={disabled}
            placeholder={placeholder}
        />
    )

    const iconEl = icon ? (
        <div
            className={`align-items-center d-flex justify-content-center position-absolute icon`}>
            <svg
                width="12"
                height="17"
                viewBox="0 0 12 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                {icon}
            </svg>
        </div>
    ) : null

    const iconClass = icon ? 'icon' : ''

    return (
        <div className={`${className} ${iconClass}`}>
            <div
              className="d-flex flex-column position-relative"
              style={itemStyle}
            >
                <label
                    htmlFor={name}
                    className={`position-relative ${textClass}`}
                    style={labelStyle}
                >
                    {label}
                    {asterisk}
                </label>
                {inputEl}
                {iconEl}
            </div>
            <span className={`${spanClass} ${textClass}`}>{data.error || message}</span>
        </div>
    )
}

export default TextInput

import React, { useState } from 'react';
import { Formik, Form } from 'formik';

import useMutation from '../../hooks/useMutation';
import useQuery from '../../hooks/useQuery';

import ProducerClient from '../../apis/Producers';

import CustomInput from '../../components/forms/CustomInput';
import Loader from '../../components/Loader';
import ErrorBox from '../../components/ErrorBox';

import { required, email, phone, postalCode, state } from '../../validation/form';

import PaymentModal from './PaymentModal';
import { Button } from 'react-bootstrap';
import css from './AccountPage.module.scss';

const validate = (values) => {
    const errors = {};

    const requiredFields = ['address1', 'city', 'state', 'postal_code', 'name', 'poc_first_name', 'poc_last_name', 'poc_email', 'poc_phone'];

    requiredFields.forEach(field => {
      required(errors, values, field);
    });

    postalCode(errors, values, 'postal_code');
    state(errors, values, 'state');
    email(errors, values, 'email');
    phone(errors, values, 'phone');

    return errors;
};

function AccountPage(props) {
    const [showPaymentModal, setShowPaymentModal] = useState(false);

    const { data, isLoading, isError, error, refetch } = useQuery({
        queryKey: ['get_company_account'],
        queryFn: () => ProducerClient.getCompanyAccount(),
        refetchOnmount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        retry: false
    });

    const {
      mutateAsync: updateCompanyAccount,
      isLoading: isSaveLoading,
      isError: isSaveError,
      error: saveError
    } = useMutation({
        mutationKey: 'update_company_account',
        mutationFn: (data) => ProducerClient.updateCompanyAccount(data),
        onSuccess: () => {
            refetch();
        }
    });

    const onSubmit = async (values, { resetForm }) => {
        await updateCompanyAccount(values);
        resetForm();
    }

    const initialValues = {
        address1: data?.address?.address1 || '',
        address2: data?.address?.address2 || '',
        city: data?.address?.city || '',
        state: data?.address?.state || '',
        postal_code: data?.address?.postal_code || '',
        name: data?.display_name || data?.name || '',
        poc_first_name: data?.poc_first_name || '',
        poc_last_name: data?.poc_last_name || '',
        poc_email: data?.poc_email || '',
        poc_phone: data?.poc_phone || ''
    };

    const bankAccountUpdateText = data?.payment.enabled ? 'Update Payment' : 'Add Payment';

    const bankAccountEl = data?.payment.enabled
        ? (
            <div className={css.paymentInfo}>
              <div class={css.item}>
                <div className={css.label}>
                  Account Name:
                </div>
                <div className={css.entry}>
                  {data?.payment.accountName}</div>
                </div>
              <div class={css.item}>
                <div className={css.label}>
                  Routing #:
                </div>
                <div className={css.entry}>
                  {data?.payment.routingNumber}</div>
                </div>
              <div class={css.item}>
                <div className={css.label}>
                  Last 4 of Account #:
                </div>
                <div className={css.entry}>
                  {data?.payment.last4}</div>
                </div>
            </div>
        )
        : (
            <div className={`${css.paymentInfo} ${css.no}`}>
                <span>No bank account</span>
                <Button
                  type='button'
                  variant='primary'
                  onClick={() => setShowPaymentModal(true)}
                >
                  {bankAccountUpdateText}
                </Button>
            </div>
        );

    return (
      <>
        <h1>My Account</h1>
          <div className={css.acct}>

            <Loader loading={isLoading}>
                <Formik
                    validate={validate}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    enableReinitialize
                >
                    {(formik) => (
                        <Form>
                            <h2 className={css.title}>Company</h2>
                            <h2 className={css.title}>Point of Contact</h2>

                            <div className={css.block}>
                              <div className={css.items}>
                                <CustomInput
                                  label="Company Name"
                                  name="name"
                                  formik={formik}
                                  required
                                />
                                <CustomInput
                                  label="Address 1"
                                  name="address1"
                                  className={css.half}
                                  formik={formik}
                                  required
                                />
                                <CustomInput
                                  label="Address 2"
                                  name="address2"
                                  className={css.half}
                                  formik={formik}
                                />
                                <CustomInput
                                  label="City"
                                  name="city"
                                  className={css.half}
                                  formik={formik}
                                  required
                                />
                                <CustomInput
                                  label="State"
                                  name="state"
                                  className={css.half}
                                  formik={formik}
                                  required
                                />
                                <CustomInput
                                  label="Zip"
                                  name="postal_code"
                                  className={css.half}
                                  formik={formik}
                                  required
                                />
                              </div>
                            </div>

                            <div className={css.block}>
                              <div className={css.items}>
                                <CustomInput
                                  label="First Name"
                                  name="poc_first_name"
                                  className={css.half}
                                  formik={formik}
                                  required
                                />
                                <CustomInput
                                  label="Last Name"
                                  name="poc_last_name"
                                  className={css.half}
                                  formik={formik}
                                  required
                                />
                                <CustomInput
                                  label="Phone"
                                  name="poc_phone"
                                  formik={formik}
                                  required
                                />
                                <CustomInput
                                  label="Email"
                                  name="poc_email"
                                  formik={formik}
                                  required
                                />
                              </div>
                            </div>

                            <ErrorBox show={isSaveError} message={saveError?.message} />

                            <div className={css.buttonBar}>
                              {/*<Button
                                type='button'
                                variant='outline-danger'
                                onClick={formik.resetForm}
                              >
                                Cancel
                              </Button>*/}
                              <Button
                                type="submit"
                                variant='primary'
                                disabled={!Object.values(formik.touched).length || isSaveLoading}
                              >
                                Save
                              </Button>
                            </div>
                        </Form>
                    )}
                </Formik>

                {/*<div className={css.paymentBlock}>
                  <h2>Payment</h2>
                  {bankAccountEl}
                  {data?.payment.enabled &&
                    <Button
                      type='button'
                      variant='primary'
                      onClick={() => setShowPaymentModal(true)}
                    >
                      {bankAccountUpdateText}
                    </Button>
                  }
                </div>*/}
            </Loader>
            <ErrorBox show={isError} message={error?.message} />

            {/*{showPaymentModal && (
                <PaymentModal
                    setShowModal={setShowPaymentModal}
                    showModal={showPaymentModal}
                />
            )}*/}
        </div>
      </>
    );
}

export default AccountPage;

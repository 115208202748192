import React from 'react';

import CustomMultiSelect from '../forms/CustomMultiSelect';
import CustomSelect from '../forms/CustomSelect';

function RoleSelect(props) {
	const {
		formik,
		roles,
		name,
		label,
		required,
		single,
		disabled
	} = props;

	if (single) {
		const selectableRoles = roles
			?.map(r => ({ label: r.display_name, value: r.id}))
			.sort((a, b) => a.label < b.label ? -1 : 1);

		return (
			<CustomSelect
				label={label}
				name={name}
				formik={formik}
				options={selectableRoles}
				placeholder="NONE"
				required={required}
				disabled={disabled}
			/>
		);
	}

	const selectedTypes = roles?.reduce((map, r) => {
		if (formik.values[name].includes(r.id)) {
			map[r.type] = true;
		}
		return map;
	}, {});

	const selectableRoles = roles
		?.filter(r => !selectedTypes[r.type] || formik.values[name].includes(r.id))
		.map(r => ({ label: r.display_name, value: r.id}))
		.sort((a, b) => a.label < b.label ? -1 : 1);

	return (
		<CustomMultiSelect
			label={label}
			name={name}
			formik={formik}
			options={selectableRoles}
			placeholder="NONE"
			required={required}
			disabled={disabled}
		/>
	);
}

export default RoleSelect;

import headDownIcon from './icons/headDown';
import headUpIcon from './icons/headUp';
import moveIcon from './icons/move';
import sitIcon from './icons/sit';
import standIcon from './icons/stand';

const SKELETONS = [
  [0, 1],
  [0, 2],
  [1, 2],

  [1, 3],
  [2, 4],

  [5, 7],
  [6, 8],

  [7, 9],
  [8, 10],

  [11, 13],
  [12, 14],

  [13, 15],
  [14, 16],
];

const POINT_RADIUS = 4;

class Renderer {
  constructor(streamer) {
    this.streamer = streamer;
    this.ctx = this.streamer.canvas.getContext('2d');
  }

  clear() {
    this.ctx.clearRect(0, 0, this.streamer.canvas.width, this.streamer.canvas.height);
  }

  render(payload, timestamp, prevTimestamp, frameCoordToCanvasFn) {
    if (!payload) {
      return;
    }

    // draw lines

    const {clientWidth, clientHeight} = this.streamer.videoSrc;

    // find padding so that canvas fits images 'object-fit: contain;'

    // resize canvas
    this.streamer.canvas.width = clientWidth;
    this.streamer.canvas.height = clientHeight;

    if (this.streamer.shouldDrawPoses) {
      this.drawPoses(payload, frameCoordToCanvasFn);
    }

    if (this.streamer.shouldDrawBoxes) {
      this.drawBoxes(payload, frameCoordToCanvasFn);
    }

    this.drawBoxHighlights(payload, frameCoordToCanvasFn);

    this.drawTrackingIds(payload, frameCoordToCanvasFn);

    if (this.streamer.shouldDrawTimestamp) {
      this.drawFrameDeltaInSecs(
        clientWidth,
        clientHeight,
        timestamp,
        prevTimestamp
      );
    }
  }

  stop() {
    this.ctx.clearRect(0, 0, this.streamer.canvas.width, this.streamer.canvas.height);
  }

  drawBoxes(payload, frameCoordToCanvasFn) {
    this.ctx.beginPath();

    for (const pose of payload.detections) {
      const {bbox} = pose;


      if (bbox) {
        const {x: startX, y: startY} = frameCoordToCanvasFn(
          bbox[0],
          bbox[1]
        );

        const {x: endX, y: endY} = frameCoordToCanvasFn(
          bbox[2],
          bbox[3]
        );

        this.ctx.rect(
          startX,
          startY,
          endX - startX,
          endY - startY
        );
      }
    }

    this.ctx.strokeStyle = '#64fe00';
    this.ctx.lineWidth = 1;
    this.ctx.stroke();
  }

  drawBoxHighlights(payload, frameCoordToCanvasFn) {
    this.ctx.beginPath();

    for (const pose of payload.detections) {
      const {bbox, gId} = pose;

      if (!bbox || !gId) {
        continue;
      }

      if (this.streamer.hoverId !== gId && this.streamer.activeId !== gId) {
        continue;
      }

      const {x: startX, y: startY} = frameCoordToCanvasFn(
        bbox[0],
        bbox[1]
      );

      const {x: endX, y: endY} = frameCoordToCanvasFn(
        bbox[2],
        bbox[3]
      );

      this.ctx.rect(
        startX,
        startY,
        endX - startX,
        endY - startY
      );
    }

    this.ctx.fillStyle = '#0003';
    this.ctx.lineWidth = 1;
    this.ctx.fill();
  }

  drawPoses(payload, frameCoordToCanvasFn) {
    this.ctx.beginPath();

    const { confidence } = this.streamer;

    for (const pose of payload.detections) {
      const {bones} = pose;

      if (!bones) {
        continue;
      }

      SKELETONS.forEach((skeleton) => {
        const startCor = bones[skeleton[0]];
        const endCor = bones[skeleton[1]];

        const {x: startAdjX, y: startAdjY} = frameCoordToCanvasFn(
          startCor.x,
          startCor.y
        );

        const {x: endAdjX, y: endAdjY} = frameCoordToCanvasFn(
          endCor.x,
          endCor.y
        );

        if (startCor.z >= confidence && endCor.z >= confidence) {
          this.ctx.moveTo(startAdjX, startAdjY);
          this.ctx.lineTo(endAdjX, endAdjY);
          return;
        }

        if (startCor.z >= confidence) {
          this.ctx.moveTo(startAdjX + POINT_RADIUS, startAdjY);
          this.ctx.arc(startAdjX, startAdjY, POINT_RADIUS, 0, Math.PI * 2);
          return;
        }

        if (endCor.z >= confidence) {
          this.ctx.moveTo(endAdjX + POINT_RADIUS, endAdjY);
          this.ctx.arc(endAdjX, endAdjY, POINT_RADIUS, 0, Math.PI * 2);
          return;
        }
      });
    }

    this.ctx.strokeStyle = '#64fe00';
    this.ctx.lineWidth = 1;
    this.ctx.stroke();
  }

  drawTrackingIds(payload, frameCoordToCanvasFn) {
    const boxPaddingH = 4;
    const boxPaddingV = 2;
    const fontHeight = 16;

    this.ctx.font = "14px sans-serif";

    const {
      canDrawIcons,
      shouldDrawGIds,
      shouldDrawMovement,
      shouldDrawHeadPos
    } = this.streamer || {};

    for (const pose of payload.detections) {
      const { bbox, gId, calcs } = pose;

      let [start_x, start_y] = bbox;
      const {x: adjX, y: adjY} = frameCoordToCanvasFn(
        start_x,
        start_y
      );

      const str = gId;

      const strLength = shouldDrawGIds ? this.ctx.measureText(str).width : 0;

      const icons = [];
      if (canDrawIcons && shouldDrawMovement) {
        if (calcs.walking) {
          icons.push(moveIcon);
        } else if (calcs.laying_down) {
          icons.push(sitIcon);
        } else {
          icons.push(standIcon);
        }
      }

      if (canDrawIcons && shouldDrawHeadPos) {
        if (calcs.head_position === 'HEAD_UP') {
          icons.push(headUpIcon)
        } else if (calcs.head_position === 'HEAD_DOWN') {
          icons.push(headDownIcon)
        }
      }

      if (!strLength && !icons.length) {
        break;
      }

      const textWidth = strLength ? strLength + (2 * boxPaddingH) : 0;
      const textPadding = strLength ? (2 * boxPaddingH) : 0;

      const boxHeight = fontHeight + (2 * boxPaddingV);
      const boxWidth = strLength + textPadding + (icons.length * (boxHeight + boxPaddingH));

      this.ctx.beginPath();

      this.ctx.rect(
        adjX,
        adjY,
        boxWidth,
        boxHeight
      );

      this.ctx.fillStyle = '#0007';

      this.ctx.fill();

      if (strLength) {
        const color = 'white';
        this.ctx.fillStyle = color;
        this.ctx.textAlign = 'left';
        this.ctx.fillText(str, adjX + boxPaddingH, adjY + boxPaddingV + 14);
      }

      icons.forEach((icon, i) => {
        const dx = adjX + textWidth + boxPaddingH + (i * (boxHeight + boxPaddingH));
        const dy = adjY;

        this.ctx.drawImage(icon, dx, dy, boxHeight, boxHeight);
      });
    }
  }

  drawFrameDeltaInSecs(clientWidth, clientHeight, timestamp, prevTimestamp) {
    if (!timestamp || !prevTimestamp) {
      return;
    }

    const boxPaddingH = 4;
    const boxPaddingV = 2;

    const fontHeight = 16;

    this.ctx.beginPath();

    this.ctx.font = "12px sans-serif";

    const delta = timestamp - prevTimestamp;

    const deltaStr = `${(delta / 1000).toFixed(2)}s`;
    const strLength = this.ctx.measureText(deltaStr).width;

    const boxWidth = strLength + (2 * boxPaddingH);

    this.ctx.rect(
      clientWidth - boxWidth,
      0,
      boxWidth,
      fontHeight + (2 * boxPaddingV)
    );

    this.ctx.fillStyle = '#000a';
    this.ctx.fill();

    this.ctx.fillStyle = 'white';
    this.ctx.textAlign = 'right';
    this.ctx.fillText(deltaStr, clientWidth - boxPaddingH, fontHeight);
  }
}

export default Renderer;

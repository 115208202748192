import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import Topbar from '../Vet/Topbar';

import SocketContext from '../context/SocketContext';

import SocketClient from '../SocketClient';

const VetLayout = (props) => {
  const [socketClient] = useState(new SocketClient());

  const [navOpen, setNavOpen] = useState(false);

  useEffect(() => {
    socketClient.open();
    return () => {
      socketClient.close();
    }
  }, [socketClient]);

  return (
    <SocketContext.Provider value={socketClient}>
      <Topbar navOpen={navOpen} setNavOpen={setNavOpen} />
      <section
        id="mainContent"
        className={`vet noPadding`}
      >
        <Outlet />
      </section>
    </SocketContext.Provider>
  );
};

export default VetLayout;

import React, { useContext, useState, useRef, useEffect } from 'react';

import TopHeader from '../../components/TopHeader';

import AccountClient from '../../apis/Account';

import LocationContext from '../../context/LocationContext';

import AuthContext from '../../context/AuthContext';

import LocationSelect from '../../components/LocationSelect';

import css from './styles.module.scss';
import IconGear from '../../icons/Gear';
import { Link } from 'react-router-dom';

function Topbar(props) {
    const {
        navOpen = false,
        setNavOpen = () => {}
    } = props;

    const { locationId } = useContext(LocationContext);
    const { logout } = useContext(AuthContext);

    const dropdownRef = useRef();

    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        const blurListener = (e) => {
            if (!dropdownRef.current?.contains(e.target)) {
                setShowDropdown(false);
            }
        }

        document.addEventListener('click', blurListener);

        return () => {
            document.removeEventListener('click', blurListener);
        }
    }, []);

    const token = AccountClient.accessToken();

    const queryString = locationId ? `?locationId=${locationId}` : '';

    const links = [
        {
            title: 'My Vet Checks',
            path: `/vets`,
        },
        {
            title: 'Vet Chat',
            path: `/vets/chat`,
        }
    ];

    const settingsDropdown = (
        <li
            ref={dropdownRef}
            className={`${css.dropdownContainer} ${showDropdown ? css.active : null}`}
            onClick={() => setShowDropdown(!showDropdown)}
            id="settings-menu-item"
        >
            <IconGear color='#FFF' />
            
            <div
              className={`${css.dropdown} ${showDropdown ? css.open : ''}`}
              onClick={e => e.stopPropagation()}
            >
                {token?.first_name &&
                  <h5>
                    Hi, <span>{token?.first_name}!</span>
                    <span className={css.email}>{token?.email}</span>
                  </h5>
                }
                {/*<Link
                  to={'/vets/account'}
                  className={css.link}
                  onClick={() => setShowDropdown(false)}
                >
                  My Account
                </Link>*/}
                <div className={css.locSelect}>
                  <h5>Location:</h5>
                  <LocationSelect hideLabel url="/api/v1/vets/locations" />
                </div>
                <button onClick={logout} className='btn btn-danger'>Logout</button>
            </div>
        </li>
    );

    return (
        <TopHeader
            links={links}
            email={(token || {}).email}
            query={queryString}
            customEls={settingsDropdown}
            navOpen={navOpen}
            setNavOpen={setNavOpen}
            label={`Herd Sense - Vet Check`}
        />
    );
}

export default Topbar;

import React from 'react';

const FooterLogo = () => (
  <svg width="31" height="26" viewBox="0 0 31 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.6172 19.5292C11.6161 19.5228 11.6151 19.5165 11.6143 19.5105C11.1358 17.8153 9.8856 15.8052 9.08494 14.8438C7.77131 13.2639 6.975 11.1993 6.975 8.93462C6.975 4.23327 10.5322 0.0159856 15.4709 4.26509e-05C20.1936 -0.015113 24.025 4.01078 24.025 8.93462C24.025 11.2462 23.2306 13.3078 21.9156 14.8438C21.1149 15.8052 19.8645 17.8153 19.3861 19.5105C19.3857 19.5146 19.3851 19.5186 19.3844 19.5225L19.3831 19.5305V19.5309C19.382 19.5373 19.3808 19.5438 19.3801 19.5504H11.6204C11.6201 19.5481 11.6198 19.5458 11.6195 19.5435L11.6188 19.5384C11.6183 19.5353 11.6177 19.5322 11.6172 19.5292ZM10.5135 13.558L10.5137 13.5583C11.2295 14.4181 12.2476 15.9744 12.9352 17.5853H18.0651C18.7531 15.9735 19.772 14.4165 20.4878 13.5571L20.4963 13.5469L20.5049 13.5368C21.509 12.364 22.1359 10.7687 22.1359 8.93462C22.1359 5.08174 19.1379 1.9534 15.4768 1.96513C11.6576 1.97749 8.86406 5.2327 8.86406 8.93462C8.86406 10.7109 9.48654 12.3228 10.5135 13.558Z" fill="white"/>
    <path d="M11.8858 23.9623C11.7178 23.6946 11.6298 23.3815 11.6298 23.0604L11.625 21.062H19.375L19.3688 23.0604C19.3688 23.3322 19.2521 23.7366 19.1098 23.9628L18.2824 25.272C18.0281 25.6737 17.4507 26 16.9935 26H14.0064C13.5477 26 12.9703 25.6735 12.716 25.272L11.8858 23.9623Z" fill="white"/>
    <path d="M2.52069 2.74511C2.40007 3.12152 2.35745 3.52058 2.39524 3.91512C2.53377 5.35619 3.69724 6.44961 5.08594 6.44961H6.2V11.2868C2.77111 11.2868 0 8.36938 0 4.81048C0 3.34774 0.47832 1.91723 1.35866 0.772439L1.72 0.302614C1.95446 -0.00241769 2.36568 -0.0885785 2.69554 0.0980869C3.0254 0.284777 3.17895 0.690806 3.06029 1.06116L2.52069 2.74511Z" fill="white"/>
    <path d="M28.6048 3.91512C28.6425 3.52058 28.5999 3.12152 28.4793 2.74511L27.9397 1.06116C27.8211 0.690806 27.9746 0.284777 28.3045 0.0980869C28.6343 -0.0885785 29.0455 -0.00241769 29.28 0.302614L29.6413 0.772439C30.5217 1.91723 31 3.34774 31 4.81048C31 8.36938 28.2289 11.2868 24.8 11.2868V6.44961H25.9141C27.3028 6.44961 28.4662 5.35619 28.6048 3.91512Z" fill="white"/>
  </svg>
)

export default FooterLogo;
import React from 'react';

import css from './styles.module.scss';

function ErrorBox(props) {
	const { show, message } = props;

	if (!show) {
		return null;
	}

	return (
		<div className={css.container}>
			<span className={css.message}>{JSON.stringify(message)}</span>
		</div>
	);
}

export default ErrorBox;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import APIClient from '../apis/Client';

import AuthContext from '../context/AuthContext';

function clearAccessToken() {
  const cookies = new Cookies();
  cookies.remove('powershift_token', { path: '/' });
}

const AuthLayout = ({ children }) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);

  const parsedUser = JSON.parse(localStorage.getItem('user'));
  const accountId = parsedUser?.id;
  const companyId = parsedUser?.company_id;

  function saveUserData(res) {
    const { userData: user, refreshToken } = res;
    setUserData(user);
    setIsLoading(false);

    window.localStorage.setItem("user", JSON.stringify(userData));
    if (refreshToken) {
      window.localStorage.setItem("powershift_refresh", refreshToken);
    }
    setUserData((state) => ({ ...userData }));
  }

  async function runProtected(cb, ...args) {
    // refresh token if expired, then run callback
    if (userData.exp < Date.now() / 1000) {
      try {
        const refreshToken = window.localStorage.getItem('powershift_refresh');
        const res = await APIClient.post('/api/v1/auth/refresh', { accountId, companyId }, refreshToken)
        saveUserData(res);
      } catch (err) {
        // invalid refresh
        logout();
        console.error(err);
      }
    }

    return cb(...args);
  }

  useEffect(() => {
    let data = window.localStorage.getItem("user");
    if (!data) {
      setUserData(null);
      setIsLoading(false);
      return;
    }

    data = JSON.parse(data);
    setUserData(data);
    setIsLoading(false);
  }, []);

  const login = async (values) => {
    if (!values) {
      return;
    }

    try {
      const { refreshToken: token, userData: user } = values;
      localStorage.setItem('powershift_refresh', token);
      localStorage.setItem('user', JSON.stringify(user));
      setUserData(user);
      setRefreshToken(token);
    } catch (error) {
      console.error('Login failed to parse JSON:', error);
    }
  };

  const logout = async () => {
    try {
      console.log('Logging out...');
      clearAccessToken();
      localStorage.removeItem('powershift_refresh');
      localStorage.removeItem('user');
      setUserData(null);
      setRefreshToken(null);
      navigate('/login', {});
    } catch (error) {
      console.error('Logout failed to remove user:', error);
    }
  };

  function selectCompany(user) {
      localStorage.setItem('user', JSON.stringify(user));
      setUserData(user);
  }

  if (isLoading) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        refreshToken,
        userData,
        login,
        logout,
        selectCompany,
        runProtected
      }}
    >
      <div className="App">
        <div className="non-footer">
          <div id="PageContainer">
            {children}
          </div>
        </div>
      </div>
    </AuthContext.Provider>
  );
};

export default AuthLayout;

import React, { useContext, useState, useRef, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import TopHeader from '../../components/TopHeader';

import AuthContext from '../../context/AuthContext';

import css from './styles.module.scss';
import IconGear from '../../icons/Gear';

function Topbar(props) {
    const {
        navOpen = false,
        setNavOpen = () => {}
    } = props;

    const [showDropdown, setShowDropdown] = useState(false);

    const { logout, userData } = useContext(AuthContext);

    const location = useLocation();
    const { customerId, locationId } = useParams();

    const dropdownRef = useRef();

    useEffect(() => {
        const blurListener = (e) => {
            if (!dropdownRef.current?.contains(e.target)) {
                setShowDropdown(false);
            }
        }

        document.addEventListener('click', blurListener);

        return () => {
            document.removeEventListener('click', blurListener);
        }
    }, []);

    const allLinks = [
        {
            title: 'Customers',
            path: `/admin/customers`,
            layer: 'customers'
        },
        {
            title: 'Locations',
            path: `/admin/customers/${customerId}`,
            layer: 'customer'
        },
        {
            title: 'Global Users',
            path: `/admin/customers/${customerId}/users`,
            layer: 'customer'
        },
        {
            title: 'Devices',
            path: `/admin/customers/${customerId}/locations/${locationId}`,
            layer: 'location'
        },
        {
            title: 'Local Users',
            path: `/admin/customers/${customerId}/locations/${locationId}/users`,
            layer: 'location'
        }
    ];

    const currentLayer = allLinks.find(l => l.path === location.pathname)?.layer;
    const links = allLinks.filter(l => l.layer === currentLayer);

    const settingsDropdown = (
        <li
            ref={dropdownRef}
            className={css.dropdownContainer}
            onClick={() => setShowDropdown(!showDropdown)}
            id="settings-menu-item"
        >
            <IconGear color='#B1C0D0' />
            
            <div
              className={`${css.dropdown} ${showDropdown ? css.open : ''}`}
              onClick={e => e.stopPropagation()}
            >
                {userData?.first_name &&
                  <h5>
                    Hi, <span>{userData?.first_name}!</span>
                    <span className={css.email}>{userData?.email}</span>
                  </h5>
                }
                
                <button onClick={logout} className='btn btn-danger'>Logout</button>
            </div>
        </li>
    );

    return (
        <TopHeader
            links={links}
            company={userData?.company?.display_name || userData?.company.name}
            email={(userData || {}).email}
            customEls={settingsDropdown}
            navOpen={navOpen}
            setNavOpen={setNavOpen}
            label="Herd Sense Admin Portal"
        />
    );
}

export default Topbar;

const header = 'data:image/svg+xml;base64,';

const base64 = btoa(`<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="21" height="21" rx="2.5" fill="#0D1D27"/>
<rect x="0.5" y="0.5" width="21" height="21" rx="2.5" stroke="#F6F6F6"/>
<path d="M7.83083 11.4657C7.74646 11.8453 7.70355 12.2404 7.70707 12.646C7.71058 13.0515 7.76033 13.4458 7.85127 13.824L7.85215 13.9251C5.98318 13.9413 4.45947 12.4229 4.44328 10.5546C4.43662 9.78672 4.69083 9.0335 5.16547 8.42837L5.36028 8.18002C5.48668 8.01878 5.71044 7.9716 5.89108 8.06805C6.07173 8.16449 6.15727 8.37691 6.09427 8.57189L5.80782 9.45845C5.74379 9.65661 5.72237 9.86631 5.74476 10.0732C5.82683 10.8291 6.46597 11.3976 7.2229 11.391L7.83014 11.3858L7.83083 11.4657Z" fill="#F6F6F6"/>
<path d="M14.1279 11.4657C14.2123 11.8453 14.2552 12.2404 14.2517 12.646C14.2482 13.0515 14.1984 13.4458 14.1075 13.824L14.1066 13.9251C15.9756 13.9413 17.4993 12.4229 17.5155 10.5546C17.5221 9.78672 17.2679 9.0335 16.7933 8.42837L16.5985 8.18002C16.4721 8.01878 16.2483 7.9716 16.0677 8.06805C15.887 8.16449 15.8015 8.37691 15.8645 8.57189L16.1509 9.45845C16.215 9.65661 16.2364 9.86631 16.214 10.0732C16.1319 10.8291 15.4928 11.3976 14.7358 11.391L14.1286 11.3858L14.1279 11.4657Z" fill="#F6F6F6"/>
<path d="M11.8132 17.8616C11.4177 18.4588 10.5411 18.4588 10.1457 17.8616L6.8754 12.9235C6.43517 12.2587 6.91184 11.3713 7.70915 11.3713L14.2497 11.3713C15.047 11.3713 15.5237 12.2587 15.0835 12.9235L11.8132 17.8616Z" fill="#F6F6F6"/>
<ellipse cx="10.9793" cy="11.5454" rx="2.81843" ry="0.70459" fill="#F6F6F6"/>
<path d="M10.2367 6.92412L9.50515 6.19447L9.50642 6.19256C9.36314 6.04928 9.1688 5.96879 8.96617 5.96879C8.76354 5.96879 8.56921 6.04928 8.42593 6.19256C8.28264 6.33585 8.20215 6.53018 8.20215 6.73281C8.20215 6.93544 8.28264 7.12977 8.42593 7.27306L10.4598 9.30692C10.5307 9.37803 10.6148 9.43444 10.7076 9.47294C10.8003 9.51143 10.8997 9.53125 11 9.53125C11.1004 9.53125 11.1998 9.51143 11.2925 9.47294C11.3852 9.43444 11.4694 9.37803 11.5403 9.30692L13.5742 7.27306C13.7174 7.12977 13.7979 6.93544 13.7979 6.73281C13.7979 6.53018 13.7174 6.33585 13.5742 6.19256C13.4309 6.04928 13.2365 5.96879 13.0339 5.96879C12.8313 5.96879 12.6369 6.04928 12.4937 6.19256L11.7621 6.92412V4.19111C11.7621 3.98883 11.6817 3.79484 11.5387 3.6518C11.3957 3.50877 11.2017 3.42841 10.9994 3.42841C10.7971 3.42841 10.6031 3.50877 10.4601 3.6518C10.3171 3.79484 10.2367 3.98883 10.2367 4.19111V6.92412Z" fill="#F6F6F6"/>
</svg>`);

const icon = `${header}${base64}`;

const img = new Image();
img.src = icon;

export default img;

import React from 'react';

const APIDocumentation = () => {
    return (
        <div>
            <h2>/api/v1/device-frames</h2>
            <h3>POST</h3>
            <h4>Description</h4>
            <p>
                This endpoint allows the client to submit frames and metadata
                url that has been uploaded along with other info like frame rate
                and recorded time. The response for the endpoint is the latest
                time to which a browser user has requested for frames. Orin
                client can use this endpoint to both poll for frame requests and
                also post frame info updates.
            </p>
            <h4>Endpoint</h4>
            <code>/api/v1/device-frames</code>
            <h4>Request Method</h4>
            <code>POST</code>
            <h4>Request Body</h4>
            <p>The request body should contain the following parameters:</p>
            <ul>
                <li>
                    <strong>url</strong> (string): The URL of the frames bundle,
                    it should include both frames and metadata for each frame,
                    null if no available.
                </li>
                <li>
                    <strong>recordedAt</strong> (string): The recorded timestamp
                    of the frames bundle, null if no available.
                </li>
                <li>
                    <strong>deviceId</strong> (string): The ID of the device, a
                    valid deviceId always required.
                </li>
                <li>
                    <strong>frameRate</strong> (string): The frame rate at of
                    the recorded frames bundle, null if no available frame.
                </li>
                <li>
                    <strong>isCompressed</strong> (string): This lets the UI
                    client know whether or not it needs to decompress and
                    extract the data before showing the frames, defaults to
                    false
                </li>
            </ul>
            <h4>Example</h4>
            <pre>
                {`
        {
          "url": "herdsense_ai_frames_and_metadata_2023-05-22T16:21:27.566256Z.json"
          "recordedAt": "2022-05-20T10:30:00Z",
          "deviceId": "abc123",
          "frameRate": "10"
        }
        `}
            </pre>
            <h4>Response</h4>
            <p>
                The API will respond with a JSON object containing the following
                property:
            </p>
            <pre>
                {`
        {
          "requestTime": "<latest request time>"
        }
        `}
            </pre>
            <p>
                If a browser client request for frames, the requestTime for the
                response will get updated.
            </p>
            <h4>Example Response</h4>
            <pre>
                {`
        {
          "requestTime": "2022-05-20T10:30:05Z"
        }
        `}
            </pre>
        </div>
    );
};

export default APIDocumentation;

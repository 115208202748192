import React from 'react'

const IconInnovation = props => {

  const {
    title = '',
    color = '#3099FD',
    textColor = '#000000',
  } = props

  return (
    <>
      <svg width="75" height="60" viewBox="0 0 75 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M28.1061 45.0673C28.1035 45.0526 28.101 45.0382 28.0992 45.0242C26.9414 41.1121 23.9168 36.4736 21.9797 34.255C18.8016 30.6091 16.875 25.8445 16.875 20.6183C16.875 9.76907 25.4812 0.0368899 37.4297 9.8425e-05C48.8555 -0.0348761 58.125 9.25564 58.125 20.6183C58.125 25.9529 56.2031 30.7104 53.0215 34.255C51.0844 36.4736 48.0592 41.1121 46.902 45.0242C46.9009 45.0336 46.8994 45.0429 46.8978 45.052L46.8946 45.0703V45.0713C46.8919 45.0862 46.8891 45.101 46.8873 45.1163H28.1138C28.1132 45.111 28.1125 45.1057 28.1117 45.1004L28.1099 45.0886C28.1087 45.0814 28.1074 45.0743 28.1061 45.0673ZM25.4359 31.2878L25.4364 31.2883C27.1682 33.2724 29.6312 36.8639 31.295 40.5814H43.706C45.3705 36.8619 47.8356 33.2688 49.5672 31.2855L49.5877 31.2621L49.6086 31.2389C52.0379 28.5323 53.5547 24.8508 53.5547 20.6183C53.5547 11.7271 46.3013 4.50784 37.4439 4.53493C28.2039 4.56343 21.4453 12.0755 21.4453 20.6183C21.4453 24.7174 22.9513 28.4373 25.4359 31.2878Z" fill={color} />
        <path d="M28.7559 55.2976C28.3495 54.6799 28.1367 53.9572 28.1367 53.2164L28.125 48.6046H46.875L46.86 53.2164C46.86 53.8435 46.5776 54.7767 46.2333 55.2988L44.2317 58.32C43.6165 59.2469 42.2194 60 41.1134 60H33.8864C32.7766 60 31.3798 59.2465 30.7645 58.32L28.7559 55.2976Z" fill={color} />
        <path d="M6.09844 6.33487C5.80662 7.2035 5.70351 8.12442 5.79494 9.03489C6.13008 12.3604 8.94493 14.8837 12.3047 14.8837H15V26.0465C6.70429 26.0465 0 19.314 0 11.1011C0 7.72556 1.15723 4.42438 3.28709 1.78255L4.1613 0.698341C4.72853 -0.00557927 5.72342 -0.204412 6.52147 0.226354C7.31953 0.657178 7.691 1.59417 7.40393 2.44883L6.09844 6.33487Z" fill={color} />
        <path d="M69.2051 9.03489C69.2965 8.12442 69.1934 7.2035 68.9016 6.33487L67.5961 2.44883C67.309 1.59417 67.6805 0.657178 68.4785 0.226354C69.2766 -0.204412 70.2715 -0.00557927 70.8387 0.698341L71.7129 1.78255C73.8428 4.42438 75 7.72556 75 11.1011C75 19.314 68.2957 26.0465 60 26.0465V14.8837H62.6953C66.0551 14.8837 68.8699 12.3604 69.2051 9.03489Z" fill={color} />
      </svg>

      {title && <h4 style={{ color: textColor }}><span>{title}</span></h4>}
    </>
  );
}

export default IconInnovation;
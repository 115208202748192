import React from 'react';

import css from './styles.module.scss';

const HomeHero = ({ h1 = '', sub = '', img = '' }) => (
  <div id={css.hero} className={`align-items-start d-flex flex-column justify-content-center position-relative`} style={{ backgroundImage: `url(${img})` }}>
    <div className={`col-lg-8 position-relative ${css.content}`}>
      <h1 className={`mb-5 pb-5 position-relative`}>{h1}</h1>
      <p className={`mb-0`}>{sub}</p>
    </div>
  </div>
)

export default HomeHero;
const header = 'data:image/svg+xml;base64,';

const base64 = btoa(`<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="21" height="21" rx="2.5" fill="#0D1D27"/>
<rect x="0.5" y="0.5" width="21" height="21" rx="2.5" stroke="#F6F6F6"/>
<path d="M15.8229 16.9106V14.4907C15.8229 12.8339 14.4797 11.4907 12.8229 11.4907H8.39575C6.7389 11.4907 5.39575 12.8339 5.39575 14.4907V16.9106" stroke="#F6F6F6" stroke-width="3" stroke-linecap="round"/>
<path d="M13.1461 5.18207C13.093 5.42105 13.066 5.66975 13.0682 5.92502C13.0704 6.18029 13.1017 6.42849 13.1589 6.66651L13.1595 6.73018C11.9831 6.74038 11.024 5.78459 11.0138 4.6086C11.0096 4.12526 11.1696 3.65114 11.4684 3.27024L11.591 3.11392C11.6705 3.01242 11.8114 2.98273 11.9251 3.04344C12.0388 3.10414 12.0927 3.23785 12.053 3.36058L11.8727 3.91863C11.8324 4.04336 11.8189 4.17535 11.833 4.30561C11.8847 4.78139 12.287 5.13923 12.7634 5.1351L13.1456 5.13179L13.1461 5.18207Z" fill="#F6F6F6"/>
<path d="M17.1098 5.18158C17.1629 5.42056 17.1899 5.66927 17.1877 5.92453C17.1855 6.1798 17.1542 6.428 17.0969 6.66602L17.0964 6.72969C18.2728 6.73989 19.2319 5.7841 19.2421 4.60811C19.2463 4.12477 19.0863 3.65065 18.7875 3.26975L18.6649 3.11343C18.5853 3.01193 18.4445 2.98224 18.3308 3.04295C18.2171 3.10365 18.1632 3.23736 18.2029 3.36009L18.3832 3.91814C18.4235 4.04287 18.437 4.17487 18.4229 4.30512C18.3712 4.7809 17.9689 5.13874 17.4924 5.13461L17.1102 5.1313L17.1098 5.18158Z" fill="#F6F6F6"/>
<path d="M15.6527 9.20803C15.4038 9.5839 14.852 9.5839 14.6031 9.20803L12.5446 6.09969C12.2675 5.68126 12.5675 5.12269 13.0694 5.12269L17.1864 5.12269C17.6882 5.12269 17.9883 5.68126 17.7112 6.09969L15.6527 9.20803Z" fill="#F6F6F6"/>
<ellipse cx="15.1281" cy="5.23208" rx="1.77407" ry="0.443505" fill="#F6F6F6"/>
</svg>`);

const icon = `${header}${base64}`;

const img = new Image();
img.src = icon;

export default img;

import React from 'react';

const AlertModal = (props) => {
  const {
    title = 'Cancel App',
    message,
    btn1 = 'Cancel',
    btn2 = 'Yes',
    onAccept,
    onCancel,
    info = false,
    show = false
  } = props;

  if (!show) {
    return null;
  }

  const btnColor = info ? 'btn-primary' : 'btn-primary';

  const btn1El = btn1
    ? (
      <button type="button" className={`btn btn-outline-danger me-3`} data-dismiss="modal" onClick={() => onCancel(false)}>{btn1}</button>
    )
    : null;

  const btn2El = btn2
    ? (
      <button type="button" className={`btn ${btnColor}`} style={{ marginLeft: 'auto' }} data-dismiss="modal" onClick={() => onAccept()}>{btn2}</button>
    )
    : null;

  return (
    <div className="modal fade show" id="exampleModalLive" tabIndex="-1" aria-labelledby="newDevModalLabel" style={{ display: 'block' }} aria-modal="true" role="dialog">
      
      <div className={`position-fixed backdrop`} onClick={() => onCancel(false)} />

      <form className={`d-inline m-0`}>
        <div className={`modal-dialog modal-dialog-centered modal-dialog-scrollable modal`}>

          <div className={`modal-content`}>
            <div className={`modal-header position-relative bgNavy textWhite`}>
              <h5 className="modal-title" id="newDevModalLabel">{title}</h5>
              <button type="button" className={`border-0 position-absolute close`} data-dismiss="modal" aria-label="Close" onClick={() => onCancel(false)}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.1341 2.58996L12 1.72791L10.2721 0L9.41004 0.862047L6 4.27209L2.58996 0.865862L1.72791 0L0 1.72791L0.862047 2.58996L4.27209 6L0.865861 9.41004L0 10.2721L1.72791 12L2.58996 11.138L6 7.72791L9.41004 11.138L10.2721 12L12 10.2721L11.138 9.41004L7.72791 6L11.138 2.58996H11.1341Z" fill="white"/>
                </svg>
              </button>
            </div>

            <div className={`modal-body`}>
              <h3 className={`text-center`}>{message}</h3>
            </div>

            <div className={`align-items-center d-flex flex-row justify-content-between modal-footer border-0 bgGray246`}>
              {btn1El}
              {btn2El}
            </div>
          </div>

        </div>
      </form>
    </div>
  )
}

export default AlertModal;

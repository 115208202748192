import React from 'react';

const PageSubTitle = ( props ) => {
  const {
    title = '',
  } = props;

  return (
    <h2 className={`subTitle`}>{title}</h2>
  )
}


export default PageSubTitle;
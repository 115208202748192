import React, { useMemo, useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { useNavigate } from 'react-router-dom';

import useQuery from '../../hooks/useQuery';

import Loader from '../../components/Loader';

import Marker from './Marker';

import Client from '../../apis/Client';

import css from './styles.module.scss';

const { REACT_APP_GOOGLE_MAP_KEY } = process.env;

const FIFTEEN_MINUTES = 1000 * 60 * 15;

const options = {
  mapTypeId: 'satellite',
  zoomControl: true,
  mapTypeControl: true,
  scaleControl: true,
  streetViewControl: false,
  rotateControl: true,
  fullscreenControl: false
};

function PoleMap(props) {
  const { locationId, view, setView } = props;

  const navigate = useNavigate();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['get_location_poles', locationId],
    queryFn: () => Client.get(`/api/v1/producers/locations/${locationId}/devices`),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  });

  function selectDevice(deviceId) {
    const url = `/producers/herd-sense?locationId=${locationId}&deviceId=${deviceId}`;
    navigate(url);
  }

  const center = useMemo(() => {
    if (!data?.length) {
      return null;
    }

    const origin = data.reduce((coords, device) => {
      coords.lat += device.latitude;
      coords.lng += device.longitude;
      return coords;
    }, {lat: 0, lng: 0});

    origin.lat /= data.length;
    origin.lng /= data.length;

    return origin;
  }, [data]);

  let noDataMsg = null;
  if (!data?.length) {
    noDataMsg = <span className={css.noPoles}>Sorry, there are no poles found at current location.</span>;
  }

  if (isFetching) {
    return null;
  }

  const markers = data?.map((device) => {
    const online = new Date().getTime() - new Date(device.last_heartbeat).getTime() < FIFTEEN_MINUTES;
    return (
      <Marker
        key={device.id}
        online={online}
        lat={device.latitude}
        lng={device.longitude}
        label={device.name}
        onClick={() => selectDevice(device.id)}
      />
    )
  });

  return (
    <>
      <div className={css.mapContainer}>
        <div className={`${css.filterRowItem} ${css.right} ${css.btnGroup}`}>
          <button
            onClick={() => setView('table')}
            className={`btn ${view === 'table' ? `btn-primary ${css.btnPrimary}` : `btn-outline-primary ${css.btnOutlinePrimary}`}`}
          >
            <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.1536 5.10769H5.16458C4.69712 5.10769 4.31818 5.58983 4.31818 6.18462C4.31818 6.7794 4.69712 7.26154 5.16458 7.26154H18.1536C18.6211 7.26154 19 6.7794 19 6.18462C19 5.58983 18.6211 5.10769 18.1536 5.10769Z" fill="#3E9CDB"/>
              <path d="M18.1536 0H5.16458C4.69712 0 4.31818 0.482139 4.31818 1.07692C4.31818 1.67171 4.69712 2.15385 5.16458 2.15385H18.1536C18.6211 2.15385 19 1.67171 19 1.07692C19 0.482139 18.6211 0 18.1536 0Z" fill="#3E9CDB"/>
              <path d="M18.1536 9.84615H5.16458C4.69712 9.84615 4.31818 10.3283 4.31818 10.9231C4.31818 11.5179 4.69712 12 5.16458 12H18.1536C18.6211 12 19 11.5179 19 10.9231C19 10.3283 18.6211 9.84615 18.1536 9.84615Z" fill="#3E9CDB"/>
              <path d="M1.07955 2.15385C1.67576 2.15385 2.15909 1.67169 2.15909 1.07692C2.15909 0.482155 1.67576 0 1.07955 0C0.483329 0 0 0.482155 0 1.07692C0 1.67169 0.483329 2.15385 1.07955 2.15385Z" fill="#3E9CDB"/>
              <path d="M1.07955 7.26154C1.67576 7.26154 2.15909 6.77938 2.15909 6.18462C2.15909 5.58985 1.67576 5.10769 1.07955 5.10769C0.483329 5.10769 0 5.58985 0 6.18462C0 6.77938 0.483329 7.26154 1.07955 7.26154Z" fill="#3E9CDB"/>
              <path d="M1.07955 12C1.67576 12 2.15909 11.5178 2.15909 10.9231C2.15909 10.3283 1.67576 9.84615 1.07955 9.84615C0.483329 9.84615 0 10.3283 0 10.9231C0 11.5178 0.483329 12 1.07955 12Z" fill="#3E9CDB"/>
            </svg>
          </button>
          <button
            onClick={() => setView('map')}
            className={`btn ${view === 'map' ? `btn-primary ${css.btnPrimary}` : `btn-outline-primary ${css.btnOutlinePrimary}`}`}
          >
            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.1667 4.28199C14.1667 6.2303 11.6285 9.70228 10.5139 11.1332C10.2465 11.4757 9.75 11.4757 9.48611 11.1332C8.37153 9.70228 5.83333 6.2303 5.83333 4.28199C5.83333 1.91619 7.69792 0 10 0C12.3021 0 14.1667 1.91619 14.1667 4.28199ZM14.4444 7.15092C14.566 6.90471 14.6771 6.6585 14.7778 6.41585C14.7951 6.37303 14.8125 6.32664 14.8299 6.28382L18.8576 4.62812C19.4062 4.40331 20 4.81724 20 5.42385V15.0869C20 15.4366 19.7917 15.7506 19.4757 15.8826L14.4444 17.9487V7.15092ZM4.77778 4.93499C4.86111 5.43813 5.02778 5.94483 5.22222 6.41585C5.32292 6.6585 5.43403 6.90471 5.55556 7.15092V16.1217L1.14236 17.938C0.59375 18.1628 0 17.7489 0 17.1422V7.47921C0 7.12951 0.208333 6.8155 0.524306 6.68347L4.78125 4.93499H4.77778ZM11.3819 11.8468C11.8646 11.226 12.6215 10.2161 13.3333 9.09923V17.9951L6.66667 16.0361V9.09923C7.37847 10.2161 8.13542 11.226 8.61806 11.8468C9.32986 12.7603 10.6701 12.7603 11.3819 11.8468ZM10 5.42385C10.3684 5.42385 10.7216 5.27348 10.9821 5.0058C11.2426 4.73812 11.3889 4.37508 11.3889 3.99652C11.3889 3.61797 11.2426 3.25493 10.9821 2.98725C10.7216 2.71957 10.3684 2.56919 10 2.56919C9.63164 2.56919 9.27837 2.71957 9.01791 2.98725C8.75744 3.25493 8.61111 3.61797 8.61111 3.99652C8.61111 4.37508 8.75744 4.73812 9.01791 5.0058C9.27837 5.27348 9.63164 5.42385 10 5.42385Z" fill="#ffffff"/>
            </svg>
          </button>
        </div>
        <Loader loading={isLoading}>
          {noDataMsg}
          <GoogleMapReact
            bootstrapURLKeys={{ key: REACT_APP_GOOGLE_MAP_KEY }}
            defaultCenter={center}
            defaultZoom={18}
            options={options}
            onChildClick={selectDevice}
          >
            {markers}
          </GoogleMapReact>
        </Loader>
      </div>
    </>
  );
}

export default PoleMap;

import Client from './Client';

export default class Producers extends Client {
    static getCompanyAccount() {
        return Client.get('/api/v1/producers/account');
    }

    static updateCompanyAccount(data) {
        return Client.put('/api/v1/producers/account', data);
    }

    static addBankAccount(data) {
        return Client.post('/api/v1/producers/account/payment', data);
    }

    static getAppCategories() {
        return Client.get('/api/v1/producers/categories');
    }

    static getApps(category, poleId) {
        const pole = poleId ? `&poleId=${poleId}` : '';
        return Client.get(`/api/v1/producers/apps?category=${category}${pole}`);
    }

    static getMostPopular(category) {
        return Client.get(`/api/v1/producers/apps?category=${category}&popular=true`);
    }

    static getAppById(appId) {
        return Client.get(`/api/v1/producers/apps/${appId}`);
    }

    static getAppSummary(appId) {
        return Client.get(`/api/v1/producers/apps/${appId}/ratings/summary`);
    }

    static async getAppReviews(appId, limit) {
        let url = `/api/v1/producers/apps/${appId}/ratings`;
        if (limit) {
            url += `?limit=${limit}`;
        }

        return this.get(url);
    }

    static getFeedlotHeadCount() {
        return this.get('/api/v1/producers/summary/feedlot-alerts/headcount');
    }

    static getInstalledSummary(category) {
        return this.get(`/api/v1/producers/summary/${category}/installed`);
    }

    static addReview(appId, body) {
        return this.post(`/api/v1/producers/apps/${appId}/ratings`, body);
    }

    static getLocations() {
        return this.get('/api/v1/producers/locations');
    }

    static getLocationPoles(locationId) {
        return this.get(`/api/v1/producers/locations/${locationId}/poles`);
    }

    static savePenTags(areaId, tags) {
        return this.put(`/api/v1/producers/monitoring-areas/${areaId}/tags`, {
            tags,
        });
    }

    static getAppSubscriptions() {
        return this.get(`/api/v1/producers/apps/subscriptions`);
    }

    static getPoleApps(poleId) {
        return this.get(`/api/v1/producers/poles/${poleId}/apps`);
    }

    static poleAppInstall(poleId, appId) {
        return this.post(`/api/v1/producers/poles/${poleId}/apps/${appId}/install`);
    }

    static poleAppUninstall(poleId, appId) {
        return this.post(`/api/v1/producers/poles/${poleId}/apps/${appId}/uninstall`);
    }

    static installAppToAreas(appId, areas) {
        const body = {appId};
        if (areas && areas.length) {
            body.monitoring_areas = areas;
        }

        return this.post(`/api/v1/producers/monitoring-areas/install-app`, body);
    }

    static uninstallApp(appId) {
        return this.post(`/api/v1/producers/monitoring-areas/uninstall-app`, {appId});
    }

    static getMonitoringAreas() {
        return this.get(`/api/v1/producers/monitoring-areas`);
    }

    static getPoleView(locationId, poleId) {
        let url = `/api/v1/producers/locations/${locationId}/poles/view`;
        if (poleId) {
            url += `?poleId=${poleId}`;
        }
        return this.get(url);
    }

    static createRoundNote(locationId, areaId, description) {
        let url = `/api/v1/producers/locations/${locationId}/monitoring-areas/${areaId}/round-notes`;
        return this.post(url, {description});
    }

    static createWatchlistNote(locationId, areaId, data) {
        let url = `/api/v1/producers/locations/${locationId}/monitoring-areas/${areaId}/watch-notes`;
        return this.post(url, data);
    }

    static updateWatchlistNote(locationId, areaId, noteId, data) {
        let url = `/api/v1/producers/locations/${locationId}/monitoring-areas/${areaId}/watch-notes/${noteId}`;
        return this.put(url, data);
    }

    static deleteWatchListNote(locationId, areaId, cattleId, noteId) {
        let url = `/api/v1/producers/locations/${locationId}/monitoring-areas/${areaId}/watch-notes/${noteId}`;
        return this.delete(url);
    }

    static createPullListNote(locationId, areaId, data) {
        let url = `/api/v1/producers/locations/${locationId}/monitoring-areas/${areaId}/pull-notes`;
        return this.post(url, data);
    }

    static updatePullListNote(locationId, areaId, noteId, data) {
        let url = `/api/v1/producers/locations/${locationId}/monitoring-areas/${areaId}/pull-notes/${noteId}`;

        return this.put(url, data);
    }

    static deletePullListNote(locationId, areaId, cattleId, noteId) {
        let url = `/api/v1/producers/locations/${locationId}/monitoring-areas/${areaId}/pull-notes/${noteId}`;
        return this.delete(url);
    }

    static getLocationCattle(locationId) {
        let url = `/api/v1/producers/locations/${locationId}/cattle`;
        return this.get(url);
    }

    static getCattleWatchNotes(locationId, start, end) {
        const params = [];
        let url = `/api/v1/producers/locations/${locationId}/cattle/notes`;
        if (start) {
            params.push(`start=${start}`);
        }
        if (end) {
            params.push(`end=${end}`);
        }

        if (params.length) {
            url += `?${params.join('&')}`;
        }

        return this.get(url);
    }

    static getCattlePullList(locationId, start, end) {
        const params = [];
        let url = `/api/v1/producers/locations/${locationId}/cattle/pull-notes`;
        if (start) {
            params.push(`start=${start}`);
        }
        if (end) {
            params.push(`end=${end}`);
        }

        if (params.length) {
            url += `?${params.join('&')}`;
        }

        return this.get(url);
    }

    static getLocationSummary(locationId) {
        let url = `/api/v1/producers/locations/${locationId}/summary`;
        return this.get(url);
    }

    static markCowAsPriority(locationId, cattleId, value) {
        let url = `/api/v1/producers/locations/${locationId}/cattle/${cattleId}/priority`;
        return this.put(url, {value});
    }

    static pullCow(locationId, cattleId, value) {
        let url = `/api/v1/producers/locations/${locationId}/cattle/${cattleId}/pull`;
        return this.put(url, {value});
    }
}

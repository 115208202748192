import React from 'react';
import { Formik, Form } from 'formik';

import useMutation from '../../hooks/useMutation';

import CustomInput from '../../components/forms/CustomInput';
import CustomSlider from '../../components/forms/CustomSlider';
import FormModal from '../../components/FormModal';

import Client from '../../apis/Client';

import {required, email, phone, state} from '../../validation/form';

import css from '../styles.module.scss';

const validate = (values) => {
    const requiredFields = ['email', 'state', 'phone', 'city', 'firstName', 'lastName', 'postalCode', 'address1'];

    let errors = {};
    requiredFields.forEach((field) => {
        required(errors, values, field);
    });

    email(errors, values, 'email');
    state(errors, values, 'state');
    phone(errors, values, 'phone');

    return errors;
};

const LocationModal = ({
    showModal,
    setShowModal = () => {},
    location,
    companyId,
    onSuccess = () => {},
    isDeactivate = false,
}) => {
    const {
        mutateAsync: onSubmit,
        isLoading,
        isError,
        error,
    } = useMutation({
        mutationKey: 'create-edit-location',
        mutationFn: (data) => {
            if (location) {
                return Client.put(`/api/v1/admin/customers/${companyId}/locations/${location.id}`, data);
            } else {
                return Client.post(`/api/v1/admin/customers/${companyId}/locations`, data);
            }
        },
        onSuccess: (values) => {
            setShowModal(false);
            onSuccess(values);
        },
        useErrorBoundary: (error) => error.status === 401,
    });

    const initialValues = {
        name: location?.name || '',
        address2: location?.address.address2 || '',
        address1: location?.address.address1 || '',
        city: location?.address.city || '',
        state: location?.address.state || '',
        postalCode: location?.address.postal_code || '',
        firstName: location?.poc_first_name || '',
        lastName: location?.poc_last_name || '',
        phone: location?.poc_phone || '',
        email: location?.poc_email || '',
        isPrimary: location?.is_primary || false,
    };

    const headerLabel = location ? `Edit ${location.name}` : 'Add Location';

    return (
        <FormModal
          setShowModal={setShowModal}
          showModal={showModal}
          title={headerLabel}
          submitBtnLabel={location ? "Update" : "Create"}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
          error={error}
        >
            {(formik) => (
                <>
                    <CustomInput
                        label="Location Name"
                        name="name"
                        formik={formik}
                        required
                    />

                    <CustomInput
                        label="Street Address"
                        name="address1"
                        formik={formik}
                        required
                    />

                    <CustomInput
                        label="Building Address"
                        name="address2"
                        formik={formik}
                    />

                    <CustomInput
                        label="City"
                        name="city"
                        formik={formik}
                        required
                    />

                    <CustomInput
                        label="State"
                        name="state"
                        formik={formik}
                        required
                    />

                    <CustomInput
                        label="Postal Code"
                        name="postalCode"
                        formik={formik}
                        required
                    />

                    <CustomInput
                        label="Contact First Name"
                        name="firstName"
                        formik={formik}
                        required
                    />

                    <CustomInput
                        label="Contact Last Name"
                        name="lastName"
                        formik={formik}
                        required
                    />

                    <CustomInput
                        label="Contact Phone"
                        name="phone"
                        formik={formik}
                        required
                    />

                    <CustomInput
                        label="Email"
                        name="email"
                        formik={formik}
                        required
                    />

                    <CustomSlider
                        label="Is Primary"
                        name="isPrimary"
                        formik={formik}
                    />
                </>
            )}
        </FormModal>
    );
};

export default LocationModal;

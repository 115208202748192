import React from 'react';

import useMutation from '../../../hooks/useMutation';

import Client from '../../../apis/Client';
import { Formik, Form } from 'formik';

import { required } from '../../../validation/form';

import css from './_styles.module.scss';

const validate = (values) => {
  const errors = {};

  required(errors, values, 'note');

  return errors;
}

function ChatBox(props) {
  const { locationId, cattleId, portal } = props;

  const createUrl = portal === 'producer'
    ? `/api/v1/producers/locations/${locationId}/chat-notes`
    : `/api/v1/vets/locations/${locationId}/chat-notes`;

  const { mutateAsync: createMessage } = useMutation({
    mutationKey: 'create_cattle_chat_msg',
    mutationFn: async (data) => Client.post(createUrl, data),
    onSuccess: () => {
      // post message
    }
  });

  async function onSubmit(values, formik) {
    formik.resetForm();

    try {
      await createMessage(values);
    } catch (e) {
      console.error(e);
    }
  }

  const initialValues = {
    note: '',
    cattleId
  };

  return (
    <Formik
      validate={validate}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(formik) => (
        <Form className={css.chatActions}>
          <input
            id="note"
            name="note"
            type="text"
            value={formik.values.note}
            onChange={formik.handleChange}
            placeholder="New Message"
            className={css.chatInput} />
          <button
            className={`btn btn-primary ${css.send}`}
            type="submit"
          >Send</button>
        </Form>
      )}
    </Formik>
  );
}

export default ChatBox;

import React, { useState, useContext, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import useMutation from '../../hooks/useMutation';

import ChatItems from './ChatItems/ChatItems';
import ChatThreads from './ChatThreads/ChatThreads';

import useSubscription from '../../hooks/useSubscription';

import SocketContext from '../../context/SocketContext';
import LocationContext from '../../context/LocationContext';

import useQuery from '../../hooks/useQuery';

import Details from './Details';

import Client from '../../apis/Client';

import cow from '../../images/cow.jpg';

import css from './_styles.module.scss';

function VetChat(props) {
  const { portal } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const socketClient = useContext(SocketContext);
  const { locationId } = useContext(LocationContext);

  const chatThreadRef = useRef();
  const itemsRef = useRef();

  const cid = searchParams.get('cattleId');

  const [cattleId, setCattleId] = useState(!cid || cid === 'null' ? null : cid);
  const [showRefreshBtn, setShowRefreshBtn] = useState(false);

  const detailsUrl = portal === 'producer'
    ? `/api/v1/producers/locations/${locationId}/cattle/${cattleId}?mug=true&health_notes=true&note_details=true`
    : `/api/v1/vets/locations/${locationId}/cattle/${cattleId}?mug=true&health_notes=true&note_details=true`;

  const { data, isLoading } = useQuery({
    queryKey: ['get_cattle_view_details', cattleId, portal],
    queryFn: async () => Client.get(detailsUrl),
    enabled: !!cattleId
  });

  function setSelectedCattle(id) {
    setCattleId(id);
    setSearchParams((prev) => {
        prev.set('cattleId', id);
        return prev;
    });
  }

  const readUrl = portal === 'producer'
    ? `/api/v1/producers/locations/${locationId}/chat-notes/read`
    : `/api/v1/vets/locations/${locationId}/chat-notes/read`;

  const { mutate: readChat } = useMutation({
    mutationKey: 'post_cattle_convo_read',
    mutationFn: () => Client.post(readUrl, { cattleId }),
    onSuccess: (data) => {
      itemsRef?.current?.markAsRead(data.cattle_id, true);
    }
  });

  useSubscription(socketClient, 'cattle-vet-chat', locationId, (data) => {
    if (data.cattle_id === cattleId) {
      chatThreadRef.current.appendData(data);
      readChat();
    }

    itemsRef?.current?.markAsRead(data.cattle_id, false);

    setShowRefreshBtn(true);
  });

  const udderlyEmpty = (
    <div className={css.emptyScreen} style={{ backgroundImage: `url(${cow})` }}>
      <div className={css.inner}>
        {/*<h1>It's udderly empty here</h1>*/}
        <p>Take a break, looks like there are no cattle needing health checks at the moment.</p>
      </div>
    </div>
  );

  const content = !cattleId
    ? <>{udderlyEmpty}</>
    : (
      <>
        <ChatThreads
          portal={portal}
          scrollRef={chatThreadRef}
          cattleId={cattleId}
          locationId={locationId}
          head={data}
          readChat={readChat}
        />
        <Details
          portal={portal}
          cattleId={cattleId}
          locationId={locationId}
          showHeader={false}
          showActions={false}
          chat={true}
          data={data}
          isLoading={isLoading}
        />
      </>
    );

  return (
    <div className={css.vetChat}>
      <ChatItems
        ref={itemsRef}
        portal={portal}
        cattleId={cattleId}
        setCattleId={setSelectedCattle}
        locationId={locationId}
      />
      {content}
    </div>
  );
}

export default VetChat;

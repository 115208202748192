import React from 'react';

const APIDocumentation = () => {
    return (
        <div>
            <h2>/api/v1/device-env-resolver</h2>
            <h3>GET</h3>
            <h4>Description</h4>
            <p>
                This endpoint retrieves the environment URL resolver for a
                specific device.
            </p>
            <h4>Endpoint</h4>
            <code>/api/v1/device-env-resolver/:deviceId</code>
            <h4>Request Method</h4>
            <code>GET</code>
            <h4>Parameters</h4>
            <p>The request should include the following parameter:</p>
            <ul>
                <li>
                    <strong>deviceId</strong> (string): The ID of the device to
                    retrieve the environment URL resolver for.
                </li>
            </ul>
            <h4>Example</h4>
            <p>Request:</p>
            <pre>/api/v1/device-env-resolver/device123</pre>
            <h4>Response</h4>
            <p>
                The API will respond with a JSON object containing the
                environment URL resolver for the specified device:
            </p>
            <pre>
                {`
        {
          "url": "https://example.com/env-resolver"
        }
        `}
            </pre>
            <h4>Example Response</h4>
            <p>Response:</p>
            <pre>
                {`
        {
          "url": "https://example.com/env-resolver"
        }
        `}
            </pre>
        </div>
    );
};

export default APIDocumentation;

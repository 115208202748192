import React, {useState, useEffect, useRef} from 'react'

import InputBase from '../InputBase';

import css from './styles.module.scss'

function CustomInput(props) {
    const {
        label,
        name,
        formik,
        required,
        className,
        message,
        textarea,
        inputStyle,
        icon,
        disabled,
        password,
        number,
        placeholder,
        autoComplete,
        onChange
    } = props;

    const containerRef = useRef();
    const innerContainerRef = useRef();
    const inputRef = useRef();

    const value = formik.values[name];

    let inputType = 'text';
    if (password) {
        inputType = 'password';
    } else if (number) {
        inputType = 'number';
    }

    function handleChange(e) {
        if (onChange) onChange(e);
        if (formik) formik.handleChange(e);
    }

    const inputEl = textarea ? (
        <textarea
            ref={inputRef}
            id={name}
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={value}
        />
    ) : (
        <input
            ref={inputRef}
            type={inputType}
            id={name}
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            onBlur={formik.handleBlur}
            onChange={handleChange}
            value={value}
            autoComplete={autoComplete}
        />
    );

    return (
        <InputBase {...props}>
            {inputEl}
        </InputBase>
    );
}

export default CustomInput

import {useContext} from 'react';
import Client from '../apis/Client';

import useQuery from '../hooks/useQuery';

import Loader from './Loader';
import ErrorBox from './ErrorBox';

import LocationContext from '../context/LocationContext';
import AuthContext from '../context/AuthContext';

export default function LocationSelect(props) {
  const { hideLabel, url, noData, style } = props;

  // let location = useLocation();
  const {locationId, updateLocationId} = useContext(LocationContext);
  const { userData } = useContext(AuthContext);

  const onLocationChange = (event) => {
    updateLocationId(event.target.value);
  };

  const { data: locations, isLoading, isError, error } = useQuery({
    queryKey: ['locations', url, locationId],
    queryFn: () => {
      return Client.get(url);
    },
  });

  const labelEl = hideLabel ? null : <label>Location:</label>;

  const locationSelect = locations?.length
    ? (
      <select value={locationId || ''} onChange={onLocationChange} id="locDD">
        {(locations || []).map((loc) => {
          const locationStr = `${loc.is_primary ? 'Primary: ' : ''}${loc.name || `${loc.address.city}, ${loc.address.state}`}`;

          return (
            <option key={loc.id} value={loc.id}>{locationStr}</option>
          );
        })}
      </select>
    )
    : (
      <span style={{ fontWeight: '700' }}>{noData}</span>
    );

  return (
    <div className="selectLocation" style={{ ...style }}>
      {labelEl}
      <Loader loading={isLoading}>
        {locationSelect}
      </Loader>
      <ErrorBox show={isError} message={error?.message} />
    </div>
  );
}

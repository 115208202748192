import React from 'react'

import css from './styles.module.scss'

const HomeCard = (props) => {
  const {
    children,
    classes = '',
    minHeight = '0',
    valign = 'center',
    id = ''
  } = props

  const divId = `${css[id]}`

  return (
    <div
      id={divId}
      className={`align-items-${valign} d-flex flex-column flex-sm-row flex-wrap ${css.card} ${classes}`}
      style={{
        minHeight: `${minHeight}px`
      }}
    >
      {children}
    </div>
  )
}

export default HomeCard;
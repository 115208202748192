import React from 'react';
import moment from 'moment';

import css from './styles.module.scss';

function NoteCard(props) {
  const { head, gotoVetChat } = props;

  const note = head.health_note;

  const user = note.created_user;

  const contact = `${user.first_name} ${user.last_name}`;

  return (
    <div className={`${css.cattleCard} ${head.urgent === 1 ? css.urgent : ''}`}>
      <div className={css.titleBlock}>
        <div>{moment.utc(note.created_at).local().format('MM/DD/YY, h:mma')}</div>
        <div>Pen {head.monitoring_area.name}</div>
        <div>{contact}</div>
      </div>
      <div className={css.image} style={{ backgroundImage: `url(${head.mug_url})` }}>
        {/*<button
          onClick={() => console.log('play clicked')}
          className={css.play}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none">
            <circle opacity="0.4" cx="27.5" cy="27.5" r="27.5" fill="black"/>
            <path d="M43.3438 27.5C43.3438 23.298 41.6745 19.2681 38.7032 16.2968C35.7319 13.3255 31.702 11.6562 27.5 11.6562C23.298 11.6562 19.2681 13.3255 16.2968 16.2968C13.3255 19.2681 11.6562 23.298 11.6562 27.5C11.6562 31.702 13.3255 35.7319 16.2968 38.7032C19.2681 41.6745 23.298 43.3438 27.5 43.3438C31.702 43.3438 35.7319 41.6745 38.7032 38.7032C41.6745 35.7319 43.3438 31.702 43.3438 27.5ZM8 27.5C8 22.3283 10.0545 17.3684 13.7114 13.7114C17.3684 10.0545 22.3283 8 27.5 8C32.6717 8 37.6316 10.0545 41.2886 13.7114C44.9455 17.3684 47 22.3283 47 27.5C47 32.6717 44.9455 37.6316 41.2886 41.2886C37.6316 44.9455 32.6717 47 27.5 47C22.3283 47 17.3684 44.9455 13.7114 41.2886C10.0545 37.6316 8 32.6717 8 27.5ZM37.25 27.5L21.4062 37.25V17.75L37.25 27.5Z" fill="white"/>
          </svg>
        </button>*/}
      </div>

      <div className={css.infoBlock}>
        <span>Animal ID</span>
        <span><strong>{head.name}</strong></span>
        {/* Ignore urgent care for flag for now */}

        <span className={css.urgent}>
          {/* {head.urgent === 1
            ? (
              <>
                Urgent Care
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M21.2565 18C21.9388 18 22.1875 17.1786 21.8512 16.6236L12.0893 0.508176C11.679 -0.169392 10.321 -0.169392 9.9107 0.508176L0.148796 16.6236C-0.187473 17.1786 0.0611494 18 0.743812 18H21.2565ZM12.1197 5.38215V12.04H9.88067V5.38215H12.1197ZM12.1197 13.3894V15.4362H9.88067V13.3894H12.1197Z" fill="#EE3253"/>
                </svg>
              </>
              )
            : <>&nbsp;</>
          } */}
        </span>
      </div>
      
      <div className={css.notes}>
        <div className={css.label}>Notes</div>
        <p>{note.description}</p>
      </div>
      <div className={css.actions}>
        <button onClick={() => gotoVetChat(head.id)}>Vet Chat</button>
      </div>
    </div>
  );
}

export default NoteCard;

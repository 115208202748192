import React from 'react';
import { Route } from 'react-router-dom';

import UnProtectedLayout from '../layouts/UnProtectedLayout';

import Login from '../Login';

import ResetPassword from '../Login/ResetPassword';
import AcceptInvite from '../Login/AcceptInvite';

const LoginRouter = (
    <Route element={<UnProtectedLayout />}>
        <Route path="/login" element={<Login />} />

        <Route
            path="/reset-password/:key"
            element={<ResetPassword></ResetPassword>}></Route>

        <Route
            path="/accept-invite/:id"
            element={<AcceptInvite></AcceptInvite>}></Route>
    </Route>
);

export default LoginRouter;

import React from 'react'

const IconAnalytics = props => {

  const {
    color = '#3099FD',
  } = props

  return (
    <svg className="mb-5 mx-auto" width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="70" cy="70" r="67" fill="white" stroke={color} strokeWidth="6"/>
      <path d="M74.9441 63.5496H82.6264C83.6152 63.5496 84.4519 64.3893 84.4519 65.458C84.4519 66.5267 83.6152 67.3664 82.5503 67.3664H73.7271C72.9664 67.3664 72.2819 66.9847 71.9776 66.2977L71.6734 65.6107L67.566 74.6947C67.1857 75.3054 66.5772 75.7634 65.8166 75.7634H65.7405C64.9799 75.7634 64.2953 75.3054 64.0671 74.542L59.5034 61.9466L57.2215 66.3741C56.9172 66.9848 56.2327 67.3664 55.5481 67.3664H48.9306C47.8658 67.3664 47.0291 66.5267 47.0291 65.458C47.0291 64.3893 47.8658 63.5496 48.9306 63.5496H54.3311L58.0582 56.2977C58.4385 55.6107 59.123 55.229 59.8837 55.3054C60.6443 55.3054 61.3289 55.8397 61.557 56.5267L65.9687 68.8168L69.9239 60.1909C70.3042 59.5802 70.9128 59.1222 71.6734 59.1222C72.434 59.1222 73.1186 59.5038 73.4228 60.1909L74.9441 63.5496Z" fill={color} />
      <path fillRule="evenodd" clipRule="evenodd" d="M36 64.9237C36 48.4351 49.387 35 65.8166 35C82.2461 35 95.6331 48.4351 95.6331 64.9237C95.6331 70.8779 93.8837 76.6794 90.5369 81.6412L89.3199 83.3969L102.631 96.9084C103.468 97.7481 104 99.0458 104 100.267C104 101.489 103.544 102.71 102.631 103.626C101.794 104.466 100.577 105 99.2841 105C98.0671 105 96.8501 104.542 95.9374 103.626L82.2461 89.8855L81.1051 90.5725C76.4653 93.3969 71.1409 94.8473 65.8166 94.8473C49.387 94.8473 36 81.4122 36 64.9237ZM44.0626 64.9237C44.0626 76.9084 53.7987 86.7557 65.8166 86.7557C77.8344 86.7557 87.5705 76.9084 87.5705 64.9237C87.5705 52.8626 77.8344 43.0916 65.8166 43.0916C53.7987 43.0916 44.0626 52.8626 44.0626 64.9237Z" fill={color} />
    </svg>
  )
}

export default IconAnalytics;
import { useEffect } from 'react';

function useSubscription(socketClient, subscription, key, onData) {
  useEffect(() => {
    if (subscription && key) {
      socketClient.subscribe(subscription, key);
    }

      return () => {
          socketClient.unsubscribe(subscription, key);
      };
  }, [socketClient, subscription, key]);

  useEffect(() => {
    if (subscription && key) {
      socketClient.update(subscription, key, onData);
    }
  }, [socketClient, subscription, key, onData]);
}

export default useSubscription;

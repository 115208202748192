import React, { useContext } from 'react';
import { Formik, Form } from 'formik';
import { useMutation } from '@tanstack/react-query';
import css from './styles.module.scss';
import PaneInput from './PaneInput';
import LogoHorns from '../icons/LogoHorns';
import LogoCattle from '../icons/LogoCattle';
import LogoPig from '../icons/LogoPig';

import ErrorBox from '../components/ErrorBox';

import {required, email, password} from '../validation/form';

import Account from '../apis/Account';

import AuthContext from '../context/AuthContext';

const validate = (values) => {
    const errors = {};
 
    required(errors, values, 'email');
    required(errors, values, 'password');

    password(errors, values, 'password');

    email(errors, values, 'email');

    return errors;
};

const initialValues = {
    email: '',
    password: ''
};

const PaneLogin = (props) => {
    const {
        setFlow,
        setShowForgot
    } = props;

    const { login } = useContext(AuthContext);

    const {
        mutateAsync: onSubmit,
        error,
        isError,
        isLoading,
    } = useMutation({
        mutationFn: async (data) => Account.login(data),
        onSuccess: (data) => {
            login(data);
        }
    });

    console.log(error?.message)

    return (
        <div className={css.paneInner}>
            <div className={css.logo}>
                <div className={css.logoIcons}>
                    <span>
                        <LogoHorns fill='#29313B' />
                    </span>
                    <span>
                        <LogoCattle fill='#29313B' />
                    </span>
                    <span>
                        <LogoPig fill='#29313B' />
                    </span>
                </div>
            </div>

            <h1 className={`col-12 px-0`}>Login to Herd Sense</h1>

            <p className={`col-12 px-0`}>
                Welcome. Please enter your login information below to enter.
                {/*or{' '}
                <a
                    href="#signup"
                    onClick={(e) => {
                        e.preventDefault();
                        setFlow('create');
                    }}
                >
                    click here
                </a>{' '}
                to register.*/}
            </p>

            <Formik
                validate={validate}
                initialValues={initialValues}
                onSubmit={onSubmit}
            >
                {(formik) => (
                    <Form
                        id={css.loginForm}
                        className={`px-0 ${css.form}`}
                        onSubmit={formik.handleSubmit}
                    >
                        <PaneInput
                            className="col-12 d-flex flex-column p-0 text-start"
                            name="email"
                            label="Email"
                            placeholder="your email here"
                            required
                            formik={formik}
                        />

                        <PaneInput
                            className="col-12 d-flex flex-column p-0 text-start"
                            name="password"
                            label="Password"
                            placeholder="password"
                            password
                            required
                            type="password"
                            formik={formik}
                        />

                        <ErrorBox
                            show={isError}
                            message={error?.message}
                        />

                        <div className={`align-items-center d-flex flex-column flex-sm-row justify-content-between p-0 mt-4 ${css.actions}`}>
                            <div className={`col-12 col-sm-6 text-center text-sm-start`}>
                                <button
                                    type="button"
                                    className={`btn btn-link`}
                                    onClick={() => setShowForgot(true)}
                                    style={{ fontSize: '1.2rem' }}
                                >
                                    Forgot Password?
                                </button>
                            </div>
                            <div className={`col-12 col-sm-6 d-flex flex-column justify-content-center justify-content-sm-end m-0 p-0 ${css.item}`}>
                                <button
                                    onClick={formik.handleSubmit}
                                    type="submit"
                                    className={`btn btn-primary ms-auto me-auto me-sm-0 px-5 ${
                                        isLoading ? 'btn-disabled' : 'btn-primary'
                                    }`}
                                    disabled={isLoading || Object.keys(formik.errors).length}
                                >
                                    Login
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default PaneLogin;

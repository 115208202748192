import React, { useState } from 'react';
import moment from 'moment';

import CustomSlider from '../../components/forms/CustomSlider';
import Loader from '../../components/Loader';
import ErrorBox from '../../components/ErrorBox';

import RoundNoteModal from './RoundNoteModal';
import DevModeModal from './DevModeModal';

import css from './styles.module.scss';
import IconCarat from '../../icons/Carat';
import IconPencil from '../../icons/Pencil';
import IconGear from '../../icons/Gear';

function Details(props) {
  const {
    isDebugView,
    setIsDebugView,
    showIds,
    setShowIds,
    shouldDrawMovement,
    setShouldDrawMovement,
    shouldDrawHeadPos,
    setShouldDrawHeadPos,
    locationId,
    prevDevice,
    nextDevice,
    data,
    refetch,
    noNextCamera,
    isLoading,
    isError,
    error
  } = props;

  const [showRoundNoteModal, setShowRoundNoteModal] = useState(false);
  const [showDevModeModal, setShowDevModeModal] = useState(false);

  const [selectedNote, setSelectedNote] = useState(null);

  function createNewRoundNote() {
    setSelectedNote(null);
    setShowRoundNoteModal(true);
  }

  function handleDevModeState() {
    setShowDevModeModal(false);
    setIsDebugView(true);
  }

  const toolEls = isDebugView
    ? <>
      <li className={css.toolContainer}>
        <CustomSlider
          label="Movement Tracking"
          value={shouldDrawMovement}
          onChange={(val) => setShouldDrawMovement(!shouldDrawMovement)}
        />
      </li>
      <li className={css.toolContainer}>
        <CustomSlider
          label="Head Posture"
          value={shouldDrawHeadPos}
          onChange={(val) => setShouldDrawHeadPos(!shouldDrawHeadPos)}
        />
      </li>
    </>
    : <li>None available</li>;

  const round_note = data?.monitoring_area.round_note;

  let roundNoteEl = null;
  if (isError) {
    roundNoteEl = <ErrorBox show={isError} error={error.message} />
  } else {
    let first_name = null;
    let last_name = null;

    if (round_note) {
      const user = round_note.created_by;
      first_name = user.first_name;
      last_name = user.last_name;
    }

    roundNoteEl = round_note
      ? (
        <div className={css.note}>
          {/* <div> */}
          <p className={css.name}>{first_name} {last_name}</p>
          <p>{moment.utc(round_note.created_at).local().format('M/D/YY, hh:mm A')}</p>
          {/* </div> */}
          <p className={css.n}>{round_note.description}</p>
        </div>
      )
      : (
        <div className={css.note}>
          <p><strong>No note</strong></p>
        </div>
      );
  }

  return (
    <div className={css.sidebarContainer}>
      <div className={css.content}>
        <div className={css.header}>
          <h2>{data?.name}</h2>
          <button className='btn btn-outline-primary' onClick={() => setShowDevModeModal(true)}>Dev mode</button>
        </div>

        <div className={css.sec}>
          <ul>
            <li className={css.toolContainer}>
              <label>Last Round</label>
              <span>{round_note ? moment.utc(round_note?.created_at).local().format('MM/DD/YYYY hh:mm A') : 'None'}</span>
            </li>
          </ul>
        </div>

        <div className={css.sec}>
          <ul>
            <li className={css.toolContainer}>
              <CustomSlider
                label="Show IDs"
                value={showIds}
                onChange={(val) => setShowIds(!showIds)}
              />
            </li>
          </ul>
        </div>

        <div id={css.ai} className={css.sec}>
          <h3>
            <IconGear color='#FFFFFF' />
            <span>AI Suggestions</span>
          </h3>
          <ul>
            {toolEls}
          </ul>
        </div>

        <div className={css.sec}>
          <h3>
            <IconPencil color='#ffffff' />
            <span>Pen Rider Notes</span>
          </h3>
          <Loader loading={isLoading}>
            {roundNoteEl}
          </Loader>
          <button
            className="btn btn-outline-primary"
            onClick={createNewRoundNote}
          >
            Add Note
          </button>
        </div>
      </div>
      <div className={css.actions}>
        <button
          className="btn btn-primary"
          onClick={prevDevice}
          disabled={noNextCamera}
        >
          <IconCarat color='#ffffff' />
          Prev Pen (Camera)
        </button>
        <button
          className="btn btn-primary"
          onClick={nextDevice}
          disabled={noNextCamera}
        >
          Next Pen (Camera)
          <IconCarat color='#ffffff' dir='right' />
        </button>
      </div>

      {showRoundNoteModal && (
        <RoundNoteModal
          showModal={showRoundNoteModal}
          setShowModal={setShowRoundNoteModal}
          selected={selectedNote}
          areaId={data?.monitoring_area_id}
          locationId={locationId}
          refetch={refetch}
        />
      )}

      {showDevModeModal && (
        <DevModeModal
          showModal={showDevModeModal}
          setShowModal={setShowDevModeModal}
          onAccept={handleDevModeState}
        />
      )}
    </div>
  );
}

export default Details;

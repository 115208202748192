import React from 'react';

import VetChat from '../../components/VetChat';

function ProducerVetChat() {
  return (
    <VetChat portal="producer" />
  );
}

export default ProducerVetChat;

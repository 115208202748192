import React from 'react';
import { Route } from 'react-router-dom';

import ProtectedLayout from '../layouts/ProtectedLayout';
import VetLayout from '../layouts/VetLayout';
import LocationLayout from '../layouts/LocationLayout';
import LocationRequiredLayout from '../layouts/LocationRequiredLayout';

import VetCheck from '../Vet/VetCheck';
import VetChat from '../Vet/VetChat';

import { VET_PATH } from '../constants';

const ProducerRouter = (
  <Route path="/vets">
    <Route element={<ProtectedLayout portalDefaultPath={VET_PATH} />}>
      <Route element={<LocationLayout />}>
        <Route element={<VetLayout />}>
          <Route path="" element={<VetCheck />} />
          <Route path="" element={<LocationRequiredLayout url="/api/v1/vets/locations" />}>
            <Route path="chat" element={<VetChat />} />
          </Route>
        </Route>
      </Route>
    </Route>
  </Route>
);

export default ProducerRouter;

import React from 'react'

const IconCart = props => {

  const {
    title = '',
    color = '#3099FD',
  } = props

  return (
    <>
      <svg className={`mb-5`} width="91" height="82" viewBox="0 0 91 82" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 3.84375C0 1.71367 1.70491 0 3.8241 0H11.0739C14.5794 0 17.6864 2.05 19.1364 5.125H84.624C88.8146 5.125 91.8739 9.12891 90.7745 13.1969L84.2416 37.5887C82.8873 42.6176 78.3462 46.125 73.1677 46.125H27.1989L28.0593 50.6895C28.4098 52.4992 29.9873 53.8125 31.8197 53.8125H77.7566C79.8758 53.8125 81.5807 55.5262 81.5807 57.6562C81.5807 59.7863 79.8758 61.5 77.7566 61.5H31.8197C26.3066 61.5 21.5743 57.5602 20.5545 52.1309L12.3327 8.72852C12.2212 8.11992 11.6954 7.6875 11.0739 7.6875H3.8241C1.70491 7.6875 0 5.97383 0 3.84375ZM20.3952 74.3125C20.3952 73.303 20.593 72.3033 20.9774 71.3706C21.3617 70.4379 21.9251 69.5905 22.6353 68.8766C23.3455 68.1628 24.1886 67.5965 25.1165 67.2102C26.0444 66.8238 27.039 66.625 28.0434 66.625C29.0477 66.625 30.0423 66.8238 30.9702 67.2102C31.8981 67.5965 32.7413 68.1628 33.4515 68.8766C34.1617 69.5905 34.725 70.4379 35.1094 71.3706C35.4937 72.3033 35.6916 73.303 35.6916 74.3125C35.6916 75.322 35.4937 76.3217 35.1094 77.2544C34.725 78.1871 34.1617 79.0345 33.4515 79.7484C32.7413 80.4622 31.8981 81.0285 30.9702 81.4148C30.0423 81.8012 29.0477 82 28.0434 82C27.039 82 26.0444 81.8012 25.1165 81.4148C24.1886 81.0285 23.3455 80.4622 22.6353 79.7484C21.9251 79.0345 21.3617 78.1871 20.9774 77.2544C20.593 76.3217 20.3952 75.322 20.3952 74.3125ZM73.9325 66.625C75.9609 66.625 77.9063 67.4349 79.3406 68.8766C80.7749 70.3183 81.5807 72.2737 81.5807 74.3125C81.5807 76.3513 80.7749 78.3067 79.3406 79.7484C77.9063 81.1901 75.9609 82 73.9325 82C71.9041 82 69.9587 81.1901 68.5244 79.7484C67.0901 78.3067 66.2843 76.3513 66.2843 74.3125C66.2843 72.2737 67.0901 70.3183 68.5244 68.8766C69.9587 67.4349 71.9041 66.625 73.9325 66.625Z" fill={color} />
      </svg>

      {title && <h4><span>{title}</span></h4>}
    </>
  )
}

export default IconCart
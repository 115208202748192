import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useMutation from '../../hooks/useMutation';
import SortableTableHeader from '../../components/SortableTableHeader/SortableTableHeader';

import ReviewClient from '../../apis/Review';

import useQuery from '../../hooks/useQuery';

import css from '../styles.module.scss';

function Review() {
    const navigate = useNavigate();

    const [notes, setNotes] = useState('');
    const [selectedApp, setSelectedApp] = useState(null);
    const [sortAssets, setSortAssets] = useState('title');
    const [sortAsc, setSortAsc] = useState(true);

    const { data: apps, isLoading } = useQuery({
        queryKey: ['get_review_apps'],
        queryFn: () => ReviewClient.getReviewApps(),
        useErrorBoundary: (error) => error.status === 401,
    });

    function selectApp(app) {
        navigate(`/review/apps/${app.id}`);
    }

    if (isLoading) {
        return <div></div>;
    }

    const appEls = apps
        .sort((item1, item2) => {
            const sOpL = sortAsc ? -1 : 1;
            const sOpG = sortAsc ? 1 : -1;

            return item1[sortAssets] < item2[sortAssets] ? sOpL : sOpG;
        })
        .map((app, i) => {
            const statusStyle = app.builds.length
                ? { color: '#F16060' }
                : { color: '#383838' };

            const statusText = app.builds.length ? (
                <strong>{`${app.builds.length} Pending`}</strong>
            ) : (
                'None'
            );

            return (
                <tr
                    key={i}
                    className={`clickable-row show`}
                    onClick={() => selectApp(app)}>
                    <td className={`text-start ${css.col1}`}>{app.title}</td>
                    <td className={`text-start ${css.col2}`}>
                        {app.company.name}
                    </td>
                    <td className={`text-start ${css.col3}`}>
                        <span style={statusStyle}>{statusText}</span>
                    </td>
                </tr>
            );
        });

    const headerArgs = {
        className: `text-start ${css.col1}`,
        sortPilots: sortAssets,
        setSortCol: setSortAssets,
        sortAsc,
        setSortAsc,
    };

    return (
        <div className={css.page}>
            <h2>Review Portal - All Apps</h2>
            <div className={css.pageContent}>
                <div className={`bgWhite tableContain`}>
                    <table className={`table table-striped mb-0`}>
                        <thead>
                            <tr>
                                <SortableTableHeader
                                    key="title"
                                    sortName="title"
                                    displayName="App Name"
                                    {...headerArgs}
                                />
                                <SortableTableHeader
                                    key="company"
                                    sortName="company"
                                    displayName="Company"
                                    {...headerArgs}
                                />
                                <SortableTableHeader
                                    key="pendingBuilds"
                                    sortName="pendingBuilds"
                                    displayName="Builds Pending Review"
                                    {...headerArgs}
                                />
                            </tr>
                        </thead>
                        <tbody>{appEls}</tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Review;

import { useContext } from 'react';
import { useMutation as reactQueryUseMutation } from '@tanstack/react-query';

import AuthContext from '../context/AuthContext';

export default function useMutation(props) {
  const { runProtected } = useContext(AuthContext);

  const query = reactQueryUseMutation({
    ...props,
    mutationFn: async (...args) => {
      const data = await runProtected(props.mutationFn, ...args);
      return data;
    },
  });

  return query;
}

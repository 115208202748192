import React, { useState, useEffect, useRef } from 'react';

import InputBase from '../InputBase';

import css from './styles.module.scss';

function CustomSelect(props) {
    const {
        label,
        name,
        formik,
        required,
        className,
        message,
        icon,
        disabled,
        options = [],
        placeholder,
    } = props;

    const dropdownRef = useRef();

    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        const closeDropdown = (e) => {
            if (!dropdownRef?.current) {
                return;
            }

            if (showDropdown && !dropdownRef.current.contains(e.target)) {
                setShowDropdown(false);
                formik.handleBlur({ target: { name } });
            }
        }

        document.addEventListener('click', closeDropdown);

        return () => {
            document.removeEventListener('click', closeDropdown);
        }
    }, [showDropdown, formik, name]);

    function selectOption(val) {
        let newVals = [];

        if (formik) {
            const index = formik.values[name].findIndex(v => v === val);
            if (index > -1) {
                newVals = [
                    ...formik.values[name].slice(0, index),
                    ...formik.values[name].slice(index + 1)
                ];
            } else {
                newVals = [...formik.values[name], val];
            }

            formik.handleChange({ target: { value: newVals, name } });
            formik.handleBlur({ target: { name } });
        }
    }

    const optionEls = options.map((opt) => {
        let isChecked = false;
        if (formik) {
            isChecked = formik.values[name].includes(opt.value);
        }

        const checkEl = isChecked
            ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M20.6097 5.20743C21.0475 5.54416 21.1294 6.17201 20.7926 6.60976L10.7926 19.6098C10.6172 19.8378 10.352 19.9793 10.0648 19.9979C9.77765 20.0166 9.49637 19.9106 9.29289 19.7072L4.29289 14.7072C3.90237 14.3166 3.90237 13.6835 4.29289 13.2929C4.68342 12.9024 5.31658 12.9024 5.70711 13.2929L9.90178 17.4876L19.2074 5.39034C19.5441 4.95258 20.172 4.87069 20.6097 5.20743Z" fill="#000000"/>
                </svg>
            )
            : null;

        return (
            <div
                key={opt.value}
                className={`${css.dropdownOption} ${isChecked ? css.selected : ''}`}
                onClick={() => selectOption(opt.value)}
            >
                <span className={css.dropdownLabel}>{opt.label}</span>
                {checkEl}
            </div>
        );
    });

    const dropdownEl = showDropdown
        ? (
            <div className={css.dropdown} ref={dropdownRef}>
                {optionEls}
            </div>
        )
        : null;

    let optionEl = (
        <option
            className={css.selectLabel}
            value="label"
            disabled
        >
            {placeholder}
        </option>
    );

    if (formik) {
        const nameMap = options.reduce((map, opt) => {
            map[opt.value] = opt.label;
            return map;
        }, {});

        const displayNames = formik.values[name].map(id => nameMap[id]);
        
        if (displayNames.length) {
            const selectLabel = displayNames.join(', ');
            optionEl = (
                <option
                    className={css.selectLabel}
                    value="label"
                >
                    {selectLabel}
                </option>
            );
        }
    }

    const inputEl = (
        <select
            id={name}
            disabled={disabled}
            onClick={() => setShowDropdown(!showDropdown)}
            value="label"
            onChange={() => {}}
        >
            {optionEl}
        </select>
    )

    return (
        <InputBase {...props} dropdown={dropdownEl}>
            {inputEl}
        </InputBase>
    );
}

export default CustomSelect

import React from 'react'

export default function IconList(props) {
  const { color = '#fff' } = props

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="12" viewBox="0 0 19 12" fill="none">
      <path d="M18.1536 5.10769H5.16458C4.69712 5.10769 4.31818 5.58983 4.31818 6.18462C4.31818 6.7794 4.69712 7.26154 5.16458 7.26154H18.1536C18.6211 7.26154 19 6.7794 19 6.18462C19 5.58983 18.6211 5.10769 18.1536 5.10769Z" fill={color}/>
      <path d="M18.1536 0H5.16458C4.69712 0 4.31818 0.482139 4.31818 1.07692C4.31818 1.67171 4.69712 2.15385 5.16458 2.15385H18.1536C18.6211 2.15385 19 1.67171 19 1.07692C19 0.482139 18.6211 0 18.1536 0Z" fill={color}/>
      <path d="M18.1536 9.84615H5.16458C4.69712 9.84615 4.31818 10.3283 4.31818 10.9231C4.31818 11.5179 4.69712 12 5.16458 12H18.1536C18.6211 12 19 11.5179 19 10.9231C19 10.3283 18.6211 9.84615 18.1536 9.84615Z" fill={color}/>
      <path d="M1.07955 2.15385C1.67576 2.15385 2.15909 1.67169 2.15909 1.07692C2.15909 0.482155 1.67576 0 1.07955 0C0.483329 0 0 0.482155 0 1.07692C0 1.67169 0.483329 2.15385 1.07955 2.15385Z" fill={color}/>
      <path d="M1.07955 7.26154C1.67576 7.26154 2.15909 6.77938 2.15909 6.18462C2.15909 5.58985 1.67576 5.10769 1.07955 5.10769C0.483329 5.10769 0 5.58985 0 6.18462C0 6.77938 0.483329 7.26154 1.07955 7.26154Z" fill={color}/>
      <path d="M1.07955 12C1.67576 12 2.15909 11.5178 2.15909 10.9231C2.15909 10.3283 1.67576 9.84615 1.07955 9.84615C0.483329 9.84615 0 10.3283 0 10.9231C0 11.5178 0.483329 12 1.07955 12Z" fill={color}/>
    </svg>
  );
}

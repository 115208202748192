import React from 'react'

const IconHealth = props => {

  const {
    color = '#3099FD',
  } = props

  return (
    <svg className={`mb-5`} width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="70" cy="70" r="67" fill="white" stroke={color} strokeWidth="6"/>
      <path d="M66.2129 98.5063L41.5078 75.3888C40.9336 74.8544 40.3867 74.2788 39.8809 73.6896H51.7754C54.8652 73.6896 57.6543 71.8259 58.8438 68.9619L60.2793 65.5087L67.0195 80.5138C67.5391 81.6786 68.6738 82.4323 69.9453 82.446C71.2168 82.4597 72.3789 81.7608 72.9531 80.6235L78.75 68.9893L78.9824 69.4552C80.2812 72.0589 82.9336 73.7033 85.832 73.7033H100.119C99.6133 74.2925 99.0664 74.8681 98.4922 75.4025L73.7871 98.5063C72.7617 99.4656 71.4082 100 70 100C68.5918 100 67.2383 99.4656 66.2129 98.5063ZM103.865 67.112H85.8184C85.4082 67.112 85.0254 66.879 84.834 66.509L81.6621 60.1644C81.1016 59.0544 79.9668 58.3418 78.7227 58.3418C77.4785 58.3418 76.3438 59.0407 75.7832 60.1644L70.123 71.5107L63.1504 55.9026C62.6172 54.7104 61.4141 53.943 60.1152 53.9704C58.8164 53.9979 57.6406 54.7789 57.1348 55.9985L52.7871 66.4542C52.623 66.8653 52.2129 67.1257 51.7754 67.1257H37.1875C36.832 67.1257 36.5039 67.1805 36.1895 67.2764C35.4102 65.0839 35 62.7543 35 60.3836V59.5888C35 50.0102 41.9043 41.843 51.3242 40.2671C57.5586 39.2256 63.9023 41.2674 68.3594 45.7347L70 47.3791L71.6406 45.7347C76.0977 41.2674 82.4414 39.2256 88.6758 40.2671C98.0957 41.843 105 50.0102 105 59.5888V60.3836C105 62.6995 104.617 64.9742 103.865 67.112Z" fill={color} />
    </svg>
  )
}

export default IconHealth;
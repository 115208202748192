import React from 'react'

const LogoCattle = ({ fill = '#ffffff'}) => (
  <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.3303 17.7815C13.33 17.7795 13.3296 17.7775 13.3293 17.7756C13.3287 17.772 13.3281 17.7685 13.3277 17.765C12.8896 16.2715 11.7451 14.5005 11.0121 13.6534C9.8095 12.2615 9.0805 10.4424 9.0805 8.4471C9.0805 4.30496 12.3371 0.589283 16.8583 0.575233C21.1818 0.561884 24.6894 4.10893 24.6894 8.4471C24.6894 10.4838 23.9621 12.3002 22.7582 13.6534C22.0252 14.5005 20.8805 16.2715 20.4426 17.765C20.4423 17.7676 20.4419 17.7702 20.4415 17.7727C20.4414 17.7736 20.4413 17.7745 20.4411 17.7754L20.4409 17.7768C20.4407 17.7777 20.4405 17.7787 20.4404 17.7796L20.4398 17.7826L20.4398 17.7827C20.4396 17.784 20.4394 17.7853 20.4391 17.7867C20.4388 17.7884 20.4385 17.7901 20.4383 17.7918C20.4378 17.7946 20.4374 17.7974 20.4371 17.8002H13.3333C13.3325 17.7939 13.3314 17.7876 13.3303 17.7815ZM19.4816 16.4502H17.8986C18.0577 16.133 18.0373 15.7392 17.81 15.4335L17.713 15.303C17.52 15.0434 17.225 14.8789 16.9026 14.8512L16.527 14.819C15.5095 14.7316 14.7281 13.8807 14.7281 12.86V11.1532L14.6399 10.3808C14.5445 9.54454 14.0215 8.81774 13.2586 8.46126L12.7071 8.20356C11.8918 7.8226 10.9432 8.09303 10.4401 8.79385C10.4342 8.67906 10.4313 8.56345 10.4313 8.4471C10.4313 4.98932 13.1439 1.93678 16.8625 1.92523C17.7859 1.92238 18.6657 2.11701 19.4625 2.46972L19.3268 2.5399C18.8147 2.80487 18.4197 3.2509 18.2186 3.79108C17.9905 4.40393 18.0319 5.08452 18.3324 5.66529L18.4639 5.91925C18.5086 6.00562 18.5594 6.08868 18.616 6.16777L18.8733 6.52718C19.173 6.946 19.2045 7.49993 18.9542 7.95001C18.6971 8.41241 18.7379 8.98303 19.0583 9.40414L19.128 9.49578C19.4983 9.98252 20.1259 10.1978 20.7173 10.0409L21.457 9.84471L22.612 9.35922C22.708 9.31889 22.811 9.29812 22.9151 9.29812C23.0532 9.29812 23.1845 9.26195 23.2988 9.19757C23.1488 10.5943 22.5841 11.8174 21.7488 12.7564L21.7426 12.7633L21.7366 12.7703C21.0641 13.5474 20.0891 14.9965 19.4816 16.4502Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.572 21.6051C13.418 21.3694 13.3374 21.0935 13.3374 20.8107L13.3329 19.0502H20.437L20.4313 20.8107C20.4313 21.05 20.3243 21.4063 20.1938 21.6056L19.4355 22.7589C19.2024 23.1127 18.673 23.4002 18.254 23.4002H15.5158C15.0953 23.4002 14.5661 23.1125 14.333 22.7589L13.572 21.6051ZM14.5052 21.4654C14.3946 21.3163 14.3367 21.1419 14.3367 20.9631L14.3335 19.8502H19.4364L19.4323 20.9631C19.4323 21.1145 19.3554 21.3397 19.2617 21.4657L18.717 22.1948C18.5496 22.4185 18.1693 22.6002 17.8683 22.6002H15.9015C15.5995 22.6002 15.2193 22.4183 15.0518 22.1948L14.5052 21.4654Z" fill={fill} />
    <path d="M10.2627 3.05457C8.96554 4.46106 8.19063 6.33222 8.15616 8.30934L7.95847 8.1438C7.69668 7.92459 7.33591 7.86833 7.01017 7.97093C6.52904 8.12248 5.81034 8.3047 5.26942 8.2502C0.679304 7.7877 0.566739 2.9002 0.929446 2.2252C1.29215 1.5502 3.71853 1.73724 5.26942 2.2252C6.39236 2.57852 7.26613 3.42286 7.66284 3.86192C7.80446 4.01866 8.03272 4.07509 8.22485 3.98717L10.2627 3.05457Z" fill={fill} />
    <path d="M25.8454 8.30934C25.8109 6.33222 25.036 4.46106 23.7388 3.05457L25.7767 3.98717C25.9688 4.07509 26.1971 4.01866 26.3387 3.86192C26.7354 3.42286 27.6092 2.57852 28.7321 2.2252C30.283 1.73724 32.7094 1.5502 33.0721 2.2252C33.4348 2.9002 33.3223 7.7877 28.7321 8.2502C28.1912 8.3047 27.4725 8.12248 26.9914 7.97093C26.6656 7.86833 26.3049 7.92459 26.0431 8.1438L25.8454 8.30934Z" fill={fill} />
  </svg>
)

export default LogoCattle
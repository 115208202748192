import React from 'react'

import css from './styles.module.scss'

const Loader = () => {
  return (
    <div id={css.loader}>
      <div className={css.loaderContain}>
        <svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 100 100' enableBackground='new 0 0 0 0'>
          <circle fill='#29313B' stroke='none' cx='30' cy='50' r='6'>
            <animateTransform 
              attributeName='transform' 
              dur='1s' 
              type='translate' 
              values='0 15 ; 0 -15; 0 15' 
              repeatCount='indefinite' 
              begin='0.1'/>
          </circle>
          <circle fill='#29313B' stroke='none' cx='50' cy='50' r='6'>
            <animateTransform 
              attributeName='transform' 
              dur='1s' 
              type='translate' 
              values='0 15 ; 0 -15; 0 15' 
              repeatCount='indefinite' 
              begin='0.2'/>
          </circle>
          <circle fill='#29313B' stroke='none' cx='70' cy='50' r='6'>
            <animateTransform 
              attributeName='transform' 
              dur='1s' 
              type='translate' 
              values='0 15 ; 0 -15; 0 15' 
              repeatCount='indefinite' 
              begin='0.3'/>
          </circle>
        </svg>
        loading
      </div>
    </div>
  )
}
export default Loader
import React from 'react';

import css from '../styles.module.scss';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';

const MetricItem = ({ title = '', p }) => {
  return (
    <div className={css.item}>
      <h3>{title}</h3>
      <p>{p}</p>
    </div>
  );
};

function CustomerMetrics(props) {
  const { customer, isLocation } = props;

  return (
    <div id={css.metrics} className='tableContain'>
      <PageSubTitle
        title={`${
          isLocation ? 'Location' : 'Customer'
        } Metrics`}
      />

      <div id={css.metricsNums}>
        <MetricItem
          title="Total Head"
          p={customer?.expectedHeadCount}
        />
        <MetricItem
          title="Apps Installed"
          p={(customer?.appsInstalled || []).length}
        />
        <MetricItem title="Avg. Monthly Expense" p={10} />
        <MetricItem
          title={'Total Users'}
          p={(customer?.users || customer?.accounts || []).length}
        />
        <MetricItem
          title={'Total Poles'}
          p={customer?.numOfPoles || (customer?.poles || []).length}
        />
      </div>
    </div>
  );
};

export default CustomerMetrics;

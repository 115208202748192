import React from 'react'

export default function IconEllipsis(props) {
  const { color = '#fff' } = props

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="20" viewBox="0 0 26 20" fill="none">
      <rect x="0.5" y="0.5" width="25" height="19" rx="3.5" stroke={color}/>
      <circle cx="7" cy="10" r="2" fill={color}/>
      <circle cx="13" cy="10" r="2" fill={color}/>
      <circle cx="19" cy="10" r="2" fill={color}/>
    </svg>
  );
}

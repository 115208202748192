import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useState,
  useEffect,
  useCallback
} from 'react';

import css from './styles.module.css';

const SCROLL_BOTTOM_PADDING = 40;
const SCROLL_TOP_PADDING = 100;

const ChatScroll = forwardRef((props, ref) => {
  const { onLoad, loadKey, className, onScrollTop, renderMessage } = props;

  const [scrollY, setScrollY] = useState(0);
  const [scrollPos, setScrollPos] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);

  const [data, setData] = useState([]);
  const [scrollBottom, setScrollBottom] = useState(false);

  const chatContainer = useRef();

  // reset everything every time the load key changes
  useEffect(() => {
    setScrollY(0);
    setScrollPos(false);
    setLoading(true);
    setData([]);
    setScrollBottom(false);
    setInitialized(true);

    if (loadKey) {
      onLoad(loadKey);
    }
  }, [loadKey, onLoad])

  useImperativeHandle(ref, () => {
    return {
      loadInitial: (arr) => {
        const slice = arr
          .slice()
          .sort((a, b) => a.created_at < b.created_at ? -1 : 1);

        setData(slice);
        setScrollBottom(true);
      },
      loadData: (arr) => {
        const combined = data.reduce((map, message) => {
          map[message.id] = message;
          return map;
        }, {});

        arr.forEach((message) => {
          combined[message.id] = message;
        });

        const slice = Object.values(combined)
          .slice()
          .sort((a, b) => a.created_at < b.created_at ? -1 : 1);

        setData(slice);
        setScrollPos(true);
      },
      appendData: (message) => {
        const combined = data.reduce((map, message) => {
          map[message.id] = message;
          return map;
        }, {});

        combined[message.id] = message;

        const slice = Object.values(combined)
          .slice()
          .sort((a, b) => a.created_at < b.created_at ? -1 : 1);

        setData(slice);
        setScrollBottom(true);
      }
    };
  }, [data]);

  const scrollToBottom = useCallback(() => {
    const { scrollHeight, clientHeight } = chatContainer.current;

    if (loading && (scrollY - clientHeight) > SCROLL_BOTTOM_PADDING) {
      return;
    }

    chatContainer.current.scrollTo({
      top: scrollHeight,
      behavior: 'instant',
    });

    setLoading(false);
  }, [scrollY, loading]);

  const scrollToLastPos = useCallback(() => {
    const { scrollHeight } = chatContainer.current;

    chatContainer.current.scrollTo({
      top: scrollHeight - scrollY,
      behavior: 'instant',
    });
  }, [scrollY]);

  useEffect(() => {
    if (scrollBottom) {
      scrollToBottom();
      setScrollBottom(false);
    }
  }, [scrollBottom, scrollToBottom]);

  useEffect(() => {
    if (scrollPos) {
      scrollToLastPos();
      setScrollPos(false);
    }
  }, [scrollPos, scrollToLastPos]);

  function handleScroll(e) {
    const { scrollTop, scrollHeight } = chatContainer.current;

    const y = scrollHeight - scrollTop;
    setScrollY(y);

    if (!loading && scrollTop < SCROLL_TOP_PADDING) {
      if (onScrollTop) onScrollTop(loadKey, data[0].created_at);
    }
  }

  const containerClasses = [className];
  if (loading) {
    containerClasses.push(css.hide);
  }

  const messages = data?.length
    ? data.map(renderMessage)
    : <span>There are no messages in this chat yet</span>;

  return (
    <div
      ref={chatContainer}
      onScroll={handleScroll}
      className={containerClasses.join(' ')}
    >
      {messages}
    </div>
  );
});

export default ChatScroll;

import React, { useContext } from 'react';
import {
    Outlet,
    Navigate,
    useLocation
} from 'react-router-dom';

import NotFound from '../NotFound';
import {getDefaultPath} from '../util';

import {
    LOGIN_PATH,
    NOT_FOUND_PATH,
} from '../constants';

import AuthContext from '../context/AuthContext';

function ProtectedLayout(props) {
    const { portalDefaultPath } = props;

    const { userData } = useContext(AuthContext);

    const location = useLocation();

    if (!userData?.id) {
        const state = {
            ...location.state,
            routeTo: location.pathname,
        };

        return <Navigate to={LOGIN_PATH} state={state} replace></Navigate>;
    }

    // const defaultPath = getDefaultPath(userData.roles, portalDefaultPath);
    // if (defaultPath === NOT_FOUND_PATH) {
    //     return <NotFound />;
    // }

    return <Outlet></Outlet>;
};

export default ProtectedLayout;

import React, { useState } from 'react';
import {
    useNavigate,
    useParams,
} from 'react-router-dom';

import Client from '../../apis/Client';

import useQuery from '../../hooks/useQuery';
import useMutation from '../../hooks/useMutation';

import Loader from '../../components/Loader/Loader';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';

import Metrics from './Metrics';
import Locations from './Locations';

import CustomerModal from '../components/CustomerModal';

import css from '../styles.module.scss';

//const devicesPolesPens = ''
export const CUSTOMER_TABS = [
    {
        name: 'overview',
        display: 'Overview',
    },
    {
        name: 'users',
        display: 'Users',
    },
    {
        name: 'locations',
        display: 'Locations',
    },
];

export default function Customer() {
    const { customerId } = useParams();

    const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);

    const { isLoading, isError, error, data: customer, refetch } = useQuery({
        queryFn: () => Client.get(`/api/v1/admin/customers/${customerId}`),
        queryKey: ['customer', customerId],
    });

    const { mutate: activateCustomer } = useMutation({
        mutationKey: 'activate-customer',
        mutationFn: ({ id, activate }) => {
            let url = `/api/v1/admin/customers/${id}/activate?activate=${activate}`;
            return Client.put(url);
        },
        onSuccess: (values) => {
            refetch();
        },
        useErrorBoundary: (error) => error.status === 401,
    });

    const navigate = useNavigate();

    if (isError) {
        return <h4 style={{ color: 'red' }}>{error.msg}</h4>;
    }

    if (isLoading) {
        return <Loader></Loader>;
    }

    const contact = {
        first_name: customer?.poc_first_name || '',
        last_name: customer?.poc_last_name || '',
        phone: customer?.poc_phone || '',
        email: customer?.poc_email || '',
    };
    const primaryLoc = customer?.primLoc;

    return (
        <>
            <div id={css.glActions}>
                <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => navigate('/admin/customers')}>
                    Back
                </button>
            </div>

            <div id={css.overview} className="tableContain">
                <div id={css.titleLocation}>
                    <PageSubTitle title={customer?.name} />
                </div>

                <div className={css.contentBlock}>
                    <div className={css.item}>
                        <h3>Point of Contact</h3>
                        <p>
                            {`${contact.first_name} ${contact.last_name}`}
                            <br />
                            {contact.email}
                            <br />
                            {contact.phone}
                        </p>
                    </div>
                    <div className={css.item}>
                        <h3>Primary Location</h3>
                        <p>
                            {`${
                                primaryLoc?.address.city
                                    ? primaryLoc.address.city + ', '
                                    : ''
                            }${
                                primaryLoc?.address.state
                                    ? primaryLoc.address.state + ', '
                                    : ''
                            }`}
                            <br />
                            {`${
                                primaryLoc?.address.unit
                                    ? primaryLoc.address.unit + ', '
                                    : ''
                            }${
                                primaryLoc?.address.street
                                    ? primaryLoc.address.street + ', '
                                    : ''
                            }`}
                        </p>
                    </div>
                    <div className={css.item}>
                        <h3>Current Balance</h3>
                        <p>$1,234</p>
                    </div>
                    <div className={`${css.item} ${css.actions}`}>
                        <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() => {
                                setShowEditCustomerModal(true);
                            }}>
                            Edit Customer Information
                        </button>
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                                activateCustomer({
                                    id: customer?.id,
                                    activate: !customer?.is_active,
                                });
                            }}>
                            {customer?.is_active
                                ? 'Deactive Customer'
                                : 'Activate Customer'}
                        </button>
                    </div>
                </div>
            </div>

            <CustomerModal
                onSuccess={(rsp) => {
                    refetch();
                }}
                customer={customer}
                showModal={showEditCustomerModal}
                setShowModal={setShowEditCustomerModal}
            />

            <Metrics customer={customer} />

            <Locations customer={customer} />
        </>
    );
}

import React from 'react'

const IconConnectivity = props => {

  const {
    title = '',
    color = '#3099FD',
    textColor = '#000000',
  } = props

  return (
    <>
      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.5 3.75C22.5 1.67578 24.1758 0 26.25 0C44.8945 0 60 15.1055 60 33.75C60 35.8242 58.3242 37.5 56.25 37.5C54.1758 37.5 52.5 35.8242 52.5 33.75C52.5 19.2539 40.7461 7.5 26.25 7.5C24.1758 7.5 22.5 5.82422 22.5 3.75ZM7.10156 25.8516L19.3008 38.0508L22.6289 34.7227C22.5469 34.418 22.5 34.0898 22.5 33.75C22.5 31.6758 24.1758 30 26.25 30C28.3242 30 30 31.6758 30 33.75C30 35.8242 28.3242 37.5 26.25 37.5C25.9102 37.5 25.5938 37.4531 25.2773 37.3711L21.9492 40.6992L34.1484 52.8984C35.8477 54.5977 35.5312 57.4453 33.293 58.3242C30.5273 59.4023 27.5273 60 24.375 60C10.9102 60 0 49.0898 0 35.625C0 32.4727 0.597656 29.4727 1.6875 26.707C2.56641 24.4805 5.41406 24.1523 7.11328 25.8516H7.10156ZM26.25 11.25C38.6719 11.25 48.75 21.3281 48.75 33.75C48.75 35.8242 47.0742 37.5 45 37.5C42.9258 37.5 41.25 35.8242 41.25 33.75C41.25 25.4648 34.5352 18.75 26.25 18.75C24.1758 18.75 22.5 17.0742 22.5 15C22.5 12.9258 24.1758 11.25 26.25 11.25Z" fill={color} />
      </svg>

      {title && <h4 style={{ color: textColor }} className={`title`}>{title}</h4>}
    </>
  );
}

export default IconConnectivity;
import React, { useContext } from 'react';
import {
    Outlet,
    Navigate,
    useLocation
} from 'react-router-dom';

import {getDefaultPath} from '../util';

import { NOT_FOUND_PATH } from '../constants';

import AuthContext from '../context/AuthContext';

function UnProtectedLayout(props) {
    const { userData } = useContext(AuthContext);

    const location = useLocation();

    if (userData?.id) {
        const defaultPath = getDefaultPath(userData.roles);
        if (defaultPath === NOT_FOUND_PATH) {
            return <Navigate to={defaultPath} replace></Navigate>;
        }

        if (location?.state?.routeTo) {
            const routeTo = userData.defaultLocation
                ? `${location.state.routeTo}?locationId=${userData.defaultLocation}`
                : location.state.routeTo;
            return <Navigate to={routeTo} replace></Navigate>;
        }

        let path = userData.defaultLocation
            ? `${defaultPath}?locationId=${userData.defaultLocation}`
            : defaultPath;

        return <Navigate to={path} replace></Navigate>;
    }

    return <Outlet></Outlet>;
};

export default UnProtectedLayout;

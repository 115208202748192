import {createContext} from 'react';

const AuthContext = createContext({
	refreshToken: null,
	login: () => {},
	logout: () => {},
	selectCompany: () => {}
});

export default AuthContext;

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

function NoteModal(props) {
  const {
    note,
    showNote = false,
    setShowNote,
  } = props;

  if (!showNote) {
    return null;
  }

  const { first_name, last_name } = note.created_user;

  return (
    <Modal
      aria-labelledby='contained-modal-title-vcenter'
      dialogClassName='modal-sm'
      centered
      onHide={() => setShowNote(false)}
      show={showNote}
    >
      <Modal.Header closeButton>
        <Modal.Title>{note.cattle_note_type} Note</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{note.description}</p>
        <p>{moment.utc(note.created_at).local().format('MM/DD/YYYY hh:mm A')}</p>
        <p>By {first_name} {last_name}</p>
      </Modal.Body>

      <Modal.Footer className={`align-items-center d-flex justify-content-end border-0 bgGray246`}>
        <button data-testid='close-info-button' type='button' className={`btn btn-primary`} onClick={()=>{setShowNote(false)}}>Close</button>
      </Modal.Footer>
    </Modal>
  )
}

export default NoteModal;
import React from 'react'

import css from './styles.module.scss'
import ButtonGroupFilter from '../../components/ButtonGroupFilter/ButtonGroupFilter';

export default function LocationHeader({
  data,
  filters,
  activeFilter,
  onClick,
}) {
  return (
    <div className={css.header}>
      <h1>{data.name}</h1>
      <ButtonGroupFilter
        filters={filters}
        activeFilter={activeFilter}
        onClick={onClick} />
    </div>
  );
}

import React from 'react';
import { Formik, Form } from 'formik';
import {Modal} from 'react-bootstrap';

import CustomInput from '../../components/forms/CustomInput';
import FormModal from '../../components/FormModal';

import css from './styles.module.scss';

const validate = (values) => {
  const errors = {};

  return errors;
};

function DevModeModal(props) {
  const {
    showModal,
    setShowModal,
    onAccept
  } = props;

  const initialValues = {
    secretpassphrase: ''
  };

  function onSubmit(values) {
    if (values.secretpassphrase === '127.0.0.1') {
      onAccept();
    } else {
      console.error('access denied');
      setShowModal(false);
    }
  }

  return (
    <FormModal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Enable Dev Mode"
      submitBtnLabel="Enter"
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {(formik) => (
        <CustomInput
          label="There's no place like"
          name="secretpassphrase"
          formik={formik}
        />
      )}
    </FormModal>
  );
}

export default DevModeModal;

import React, { useState, useRef, useContext } from 'react';
import useMutation from '../../hooks/useMutation';
import {
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import moment from 'moment';

import useQuery from '../../hooks/useQuery';

import Client from '../../apis/Client';

import AuthContext from '../../context/AuthContext';

import Loader from '../../components/Loader/Loader';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import CustomTable from '../../components/CustomTable';
import AlertModal from '../../components/AlertModal';

import UserModal from './UserModal';

export default function GlobalUsers() {
    const [searchParams] = useSearchParams();

    const locationId = searchParams.get('locationId');

    const { userData } = useContext(AuthContext);

    const navigate = useNavigate();

    const [showUserModal, setShowUserModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showResendInviteModal, setShowResendInviteModal] = useState(false);

    const activeUser = useRef(null);

    const { isLoading, isError, error, data, refetch } = useQuery({
        queryKey: ['get_producer_users_location', locationId],
        queryFn: () => Client.get(`/api/v1/producers/locations/${locationId}/users`)
    });

    const { mutate: resendInviteRequest, isLoading: resendInviteLoading } =
    useMutation({
        mutationKey: ['resend-user-invite'],
        mutationFn: (user) => {
            return Client.post(`/api/v1/auth/invite/resend`, { accountId: user.company_account_id });
        },
        onSuccess: () => {
            setShowResendInviteModal(true);
        },
    });

    const { mutate: deleteAccount, isLoading: deleteAccountLoading } =
    useMutation({
        mutationKey: ['resend-user-invite'],
        mutationFn: () => {
            return Client.delete(`/api/v1/producers/locations/${locationId}/users/${activeUser.current.company_account_id}`);
        },
        onSuccess: () => {
            setShowDeleteModal(false);
            refetch();
        },
    });

    if (isError) {
        return <h4 style={{ color: 'red' }}>{error.msg}</h4>;
    }

    if (isLoading) {
        return <Loader></Loader>;
    }

    const columnsInfo = [
        {
            name: 'name',
            label: 'User Name',
            valFn: (datum) => {
                if (!datum.first_name && !datum.last_name) {
                    return '--';
                }

                return `${datum.first_name} ${datum.last_name}`;
            },
        },
        {
            name: 'phone',
            label: 'Phone',
            valFn: (datum) => {
                return datum.phone || '--';
            },
        },
        {
            name: 'email',
            label: 'Email',
            valFn: (datum) => {
                return datum.email;
            },
        },
        {
            name: 'uiRolesStr',
            label: 'Roles',
            valFn: (datum) => {
                return datum.roles[0]?.display_name;
            },
        },
        {
            name: 'created_at',
            label: 'Date Joined',
            valFn: (datum) => {
                const created_at = moment.utc(datum.invite_accepted || datum.created_at).local().format('MM/DD/YYYY');
                return datum.status === 'pending' ? 'Pending' : created_at;
            },
        },
        {
            name: 'status',
            label: 'Status',
            valFn: (datum) => datum.status
                .charAt(0)
                .toUpperCase()
                .concat(datum.status.slice(1).toLowerCase())
        },
        {
            name: 'action-1',
            label: '',
            componentFn: (user) => user.status === 'pending'
                ? (
                    <button
                        // disabled={resendInviteLoading}
                        type="button"
                        className='btn btn-outline-primary mx-3'
                        onClick={(e) => {
                            e.stopPropagation();
                            activeUser.current = { ...user };
                            resendInviteRequest(user);
                        }}
                    >
                        Resend
                    </button>
                )
                : null
        },
        {
            name: 'action-2',
            label: '',
            componentFn: (user) => (
                <button
                    type="button"
                    className='btn btn-danger'
                    onClick={(e) => {
                        e.stopPropagation();
                        activeUser.current = { ...user };
                        setShowDeleteModal(true);
                    }}
                >
                    Remove
                </button>
            )
        }
    ];

    return (
        <>
            <h1>Users</h1>

            <div className={`bgWhite tableContain`} style={{ height: 'calc(100vh - 240px)', width: '100%' }}>
                <CustomTable
                    rows={data}
                    columns={columnsInfo}
                    onRowClick={(e, user) => {
                        activeUser.current = { ...user };
                        setShowUserModal(true);
                    }}
                />
            </div>

            <div className='actions' style={{ textAlign: 'right' }}>
                <button
                    type="button"
                    className='btn btn-primary'
                    style={{ width: 'auto' }}
                    onClick={() => {
                        activeUser.current = null;
                        setShowUserModal(true);
                    }}>
                    Add New User
                </button>
            </div>

            {showUserModal && (
                <UserModal
                    setShowModal={setShowUserModal}
                    showModal={showUserModal}
                    user={activeUser.current}
                    refetch={refetch}
                    locationId={locationId}
                />
            )}

            <AlertModal
                show={showDeleteModal}
                onAccept={deleteAccount}
                onCancel={() => setShowDeleteModal(false)}
                title={`Remove User: ${activeUser?.current?.first_name} ${activeUser?.current?.last_name}`}
                btn1="No"
                btn2="Yes"
                message={`Would you like to remove user ${activeUser?.current?.first_name} ${activeUser?.current?.last_name}?`}
            />

            <AlertModal
                show={showResendInviteModal}
                onAccept={() => setShowResendInviteModal(false)}
                onCancel={() => setShowResendInviteModal(false)}
                title="Invite Sent"
                btn1={null}
                btn2="Okay"
                info
                message="A new invite has been sent to the user's email."
            />
        </>
    );
}

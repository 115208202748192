import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Client from '../../apis/Client';

import Loader from '../../components/Loader/Loader';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import CustomTable from '../../components/CustomTable';
import InfoModal from '../../components/InfoModal';
import CustomerModal from '../components/CustomerModal';

import useQuery from '../../hooks/useQuery';

import css from '../styles.module.scss';

const columnsInfo = [
    {
        name: 'name',
        label: 'Name',
    },
    {
        name: 'location',
        label: 'Location',
        valFn: (row) => {
            if (!row.address) {
                return 'N/A';
            }

            return `${row.address.city || ''}, ${
                row.address.state || ''
            }`;
        },
    },
    {
        name: 'pointOfContact',
        label: 'Point of Contact',
        valFn: (row) => {
            return `${row.poc_first_name} ${row.poc_last_name}`;
        },
    },
    {
        name: 'poc_email',
        label: 'Contact Email',
    },
    {
        name: 'poc_phone',
        label: 'Contact Phone',
    },
    // {
    //     name: 'expectedHeadCount',
    //     label: 'Total Heads',
    // },
    // {
    //     name: 'apps',
    //     label: 'Apps Installed',
    //     valFn: (row) => {
    //         return (row.appsInstalled || []).length;
    //     },
    // },
];

const Customers = () => {
    const { data, isLoading, isError, error, refetch } = useQuery({
        queryKey: ['customers'],
        queryFn: () => Client.get('/api/v1/admin/customers'),
        useErrorBoundary: (error) => error.status === 401,
    });

    const [showNewCustomerModal, setShowNewCustomerModal] = useState(false);
    const [emailSentAlert, setEmailSentAlert] = useState(false);
    const emailAlertMsg = useRef('');
    const navigate = useNavigate();

    return (
        <>
            <div id="mainContent" className={`admin`}>
                <div id={css.customers} className='tableContain'>
                    <PageSubTitle title="Customers" />
                    {isLoading && <Loader />}
                    {isError && <h4 style={{ color: 'red' }}>{error.msg}</h4>}
                    <div style={{ width: '100%', height: 'calc(100vh - 260px)' }}>
                        <CustomTable
                            columns={columnsInfo}
                            rows={data || []}
                            rowKey="id"
                            onRowClick={(e, row) => navigate(`/admin/customers/${row.id}`)}
                        />
                    </div>
                    <div id={css.actionsBlock} className='actions'>
                        <button
                            type="button"
                            className='btn btn-primary'
                            onClick={() => {
                                setShowNewCustomerModal(true);
                            }}>
                            Add New Customer
                        </button>
                    </div>
                </div>

                <InfoModal
                    showAlert={emailSentAlert}
                    setShowAlert={setEmailSentAlert}
                    message={emailAlertMsg.current}></InfoModal>

                <CustomerModal
                    onSuccess={(rsp) => {
                        if (rsp.msg && rsp.msg.includes('email')) {
                            emailAlertMsg.current = rsp.msg;
                            setEmailSentAlert(true);
                        }
                        refetch();
                    }}
                    showModal={showNewCustomerModal}
                    setShowModal={setShowNewCustomerModal}
                />
            </div>
        </>
    );
};

export default Customers;
